import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Player } from '@lottiefiles/react-lottie-player';

import { Box, Button, Grid2, Typography } from "@mui/material";

import { PathConstants } from '../../utils/nav';

import { CompanyTool } from "../../services/api/schemas/CompanyTool";
import { createSlack } from "../../services/api/CompanyTool";
import { PageHeader } from "../_PageHeader";

const SlackOAuthRedirect = () => {
    const location = useLocation();
    const [done, setDone] = useState<boolean>(false);
    const [message, setMessage] = useState("Processing Slack integration...");

    const [companyTool, setCompanyTool] = useState<CompanyTool | null>();
    const navigate = useNavigate();

    const ctaClick = (route: string) => {
        navigate(route);
    };

    const getQueryParams = () => {
      return new URLSearchParams(location.search);
    };

  useEffect(() => {
    const queryParams = getQueryParams();
    const code = queryParams.get("code");
    const state = queryParams.get("state");

    if (!code || !state) {
      setMessage("Missing required OAuth parameters. Please try again.");
      return;
    }

    const sendOAuthRequest = async () => {
      try {
        //await createSlack(code, state);
        await createSlack(code, state).then((value) => {
            setCompanyTool(value);
            setMessage("Integration with Slack was successful!");
          });
      } catch (error) {
        setMessage(
          `Failed to integrate with Slack: ${error}. Please try again.`
        );
      } finally {
        setDone(true);
      }
    };

    sendOAuthRequest();
  }, [location.search]);

  const animationHighFive = "/static/lotties/high-five.json";


  return (
    <Box sx={{ width: "100%", maxWidth: { sm: "100%", md: "1700px" } }}>
      <Grid2 container sx={{ justifyContent: "space-evenly", alignItems: "center", }} spacing={3} padding={3} >
        <PageHeader
          title="Slack Integration"
          subtitle={message}
          subtitle2=""
        />
          {companyTool ? 
          (
            <>
              <Grid2 size={{ xs: 12, sm: 6 }}>
                  <Player
                      src={animationHighFive}
                      className="player"
                      loop
                      autoplay
                      style={{ height: '60%', width: '60%' }}
                  />            
              </Grid2>
              <Grid2 size={{ xs: 12, sm: 6 }}>
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  onClick={() => ctaClick(PathConstants.HOME)}
                >
                  Return to Branco
                </Button>
              </Grid2>
            </>
          )
          :
          (done && 
            <Grid2 size={{ xs: 12 }}>
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  onClick={() => ctaClick(PathConstants.SETTINGS)}
                >
                  Return to Settings
                </Button>
            </Grid2>            
          )}
      </Grid2>
      </Box>
  );
};

export default SlackOAuthRedirect;
