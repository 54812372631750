import React, {useContext, useEffect, useState} from "react";
import { useNavigate } from 'react-router-dom';
import dayjs from "dayjs";

import { Player } from '@lottiefiles/react-lottie-player';

import {Alert, Box, Button, FormControlLabel, Grid2, styled, Tooltip, Typography,} from "@mui/material";

import Switch from "@mui/material/Switch";
import ZoomOutOutlinedIcon from "@mui/icons-material/ZoomOutOutlined";
import ZoomInOutlinedIcon from "@mui/icons-material/ZoomInOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

import {
    getTeamGoals, 
    createTeamGoals, 
    deleteTeamGoal, 
    updateTeamGoal, 
    updateTeamGoalAggregate, 
    updateTeamGoalsKpi,
    getTeamGoalsCount,
    GOAL_STATUS,
} from "../../services/api/TeamGoals";

import { getVision } from "../../services/api/Visions";
import { isUserOrgLeader } from "../../services/api/Users";

import {UserContext} from "../../utils/context";
import { PathConstants } from "../../utils/nav";
import {PageHeader} from "../_PageHeader";

//import {sortTeamGoals, sortTeamGoalKpis,} from "../../utils/TeamGoalsUtils";

import { myTeam } from "../../services/api/Users";
import { User } from "../../services/api/schemas/User";

import {TeamGoalCard} from "./TeamGoalCard";
import { TeamGoalCardEdit } from "./TeamGoalCardEdit";


const StyledEmptyState = styled('div')(({ theme }) => ({
    alignSelf: 'center',
    width: '100%',
    height: 500,
    marginTop: theme.spacing(1),
    borderRadius: (theme).shape.borderRadius,
    //outline: '6px solid',
    outlineColor: 'hsla(220, 25%, 80%, 0.2)',
    border: '1px solid',
    borderColor: (theme).palette.grey[200],
    boxShadow: '0 0 12px 8px hsla(220, 25%, 80%, 0.2)',
    backgroundImage: `url(${process.env.REACT_APP_TEMPLATE_IMAGE_URL || 'https://branco.ai'}/static/empty-state/team-goals_pointer_trans.png)`,
    backgroundSize: 'cover',
    [theme.breakpoints.up('xs')]: {
        marginTop: theme.spacing(1),
        height: 300,
      },
      [theme.breakpoints.up('sm')]: {
        marginTop: theme.spacing(1),
        height: 400,
      },
      [theme.breakpoints.up('md')]: {
        marginTop: theme.spacing(1),
        height: 550,
      },
        ...theme.applyStyles('dark', {
      boxShadow: '0 0 24px 12px hsla(210, 100%, 25%, 0.2)',
      backgroundImage: `url(${process.env.REACT_APP_TEMPLATE_IMAGE_URL || 'https://branco.ai'}/static/empty-state/team-goals_pointer_trans.png)`,
      outlineColor: 'hsla(220, 20%, 42%, 0.1)',
      borderColor: (theme).palette.grey[700],
    }),
  }));


  const animationArrow = "/static/lotties/arrow_right.json";
  const animationHighFive = "/static/lotties/high-five.json";


interface ListItemContainerProps {
    itemInfo: TeamGoalInfo,
    onSave: (updatedItem: TeamGoalInfo) => void,
    onDelete: (item: TeamGoalInfo) => void,
    onTeamGoalCompleted: (item: TeamGoalInfo, isCompleted: boolean) => void,
    onKpiCompleted: (kpi: TeamGoalKpiInfo, isCompleted: boolean) => void,
    showActiveOnly: boolean,
    viewLevelDetails: boolean,
    userOrgLeader: boolean,
    teamMates: User[],
}


export const TeamGoalsPage = () => {
    const [user] = useContext(UserContext);
    const [goals, setGoals] = useState<TeamGoalInfo[]>([]);
    const [createTeamGoal, setCreateTeamGoal] = useState(false);
    const [showActiveOnly, setShowActiveOnly] = useState(true);
    const [viewLevelDetails, setViewLevelDetails] = useState(false);
    const [strategyId, setStrategyId] = useState<number>(0);
    const [userOrgLeader, setUserOrgLeader] = useState<boolean>(false);
    const [teamMates, setMyTeam] = useState<User[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isFirstGoal, setIsFirstGoal] = useState<boolean>(false);

    const navigate = useNavigate();

    const handleClick = (path: string) => {
        navigate(path);
      };
    
    const fetchMyTeam = async (org_id: number) => {
        return await myTeam(org_id).then((value) => {
            setMyTeam(value);
        });
    };

    const ListItemContainer = ({
        itemInfo,
        onSave,
        onDelete,
        onTeamGoalCompleted,
        onKpiCompleted: onKpiCompleted,
        showActiveOnly,
        viewLevelDetails,
        userOrgLeader,
        teamMates,
    }: ListItemContainerProps) => {
        const [isEditing, setIsEditing] = useState(false);
        const handleEdit = () => setIsEditing(true);
        const handleCancelEdit = () => setIsEditing(false);

        function handleSave(updatedItem: TeamGoalInfo) {
        setIsEditing(false);
        onSave(updatedItem);
        }

        return (userOrgLeader && isEditing) ? (
            <TeamGoalCardEdit goalEdit={itemInfo} userId={user.user_id} onCancel={handleCancelEdit}
                    onSave={handleSave}/>
            ) : (
            <TeamGoalCard
            goalInfo={itemInfo}
            onDelete={onDelete}
            onEdit={handleEdit}
            onTeamGoalCompleted={onTeamGoalCompleted}
            onKpiCompleted={onKpiCompleted}
            showActiveOnly={showActiveOnly}
            viewLevelDetails={viewLevelDetails}
            userOrgLeader={userOrgLeader}
            teamMates={teamMates}
            />
        );
    };

    useEffect(() => {
        try {
        if (user && user.org_id) {
            fetchGoals(user.org_id, showActiveOnly);
            fetchTeamGoalsCount(user.org_id);
            fetchMyTeam(user.org_id);
            fetchVisionAndStrategy(user.org_id);
            fetchUserOrgLeadership(user.org_id);
        }
        } catch (err) {
            console.error("Failed to retrieve goals. ", err);
        }
    }, [user]);

    const fetchTeamGoalsCount = async (org_id: number) => {
        var count: number = 0;
        count = await getTeamGoalsCount(org_id);
        setIsFirstGoal(count === 0);
      };

    const fetchGoals = async (org_id: number, viewActiveOnly: boolean) => {
        const filterStatus = viewActiveOnly ? GOAL_STATUS.ACTIVE : "";

        return await getTeamGoals(org_id, filterStatus).then((value) => {
        setGoals(value);
        if (value.length === 0)
            fetchTeamGoalsCount(org_id);

        setIsLoading(false);
        });
    };

    const fetchVisionAndStrategy = async (org_id: number) => {
        return await getVision(org_id).then((value) => {
            if (value && value.strategies && value.strategies.length > 0) {
                setStrategyId(value.strategies[0].strategy_id);
            }
        });
    };

    const fetchUserOrgLeadership = async (org_id: number) => {
        return await isUserOrgLeader(org_id).then((value) => {
            setUserOrgLeader(value);
        });
    };

    function handleKpiCompleted(kpi: TeamGoalKpiInfo, isCompleted: boolean) {
        if (userOrgLeader) {
            kpi.kpi_achieved_date = isCompleted ? dayjs().format("YYYY-MM-DD") : null;
            kpi.kpi_achieved_user_id = user.user_id;
            updateTeamGoalsKpi(kpi).then(() => {
                fetchGoals(user.org_id, showActiveOnly);
            }).catch((e) => {
                console.error("Failed to update team goal kpi: " + e);
            });
        }
    }

    function handleTeamGoalCompleted(goal: TeamGoalInfo, isCompleted: boolean) {
        if (userOrgLeader) {
            goal.goal_achieved_date = isCompleted ? dayjs().format("YYYY-MM-DD") : null;
            goal.goal_achieved_user_id = isCompleted ? user.user_id : null;
            goal.goal_status = isCompleted ? GOAL_STATUS.ACHIEVED : GOAL_STATUS.ACTIVE;
            updateTeamGoal({...goal}).then(() => {
                fetchGoals(user.org_id, showActiveOnly);
            }).catch((e) => {
                console.error("Failed to update team goal: " + e);
                // TODO: show a UI error
            });
        }
    }

    function handleDeleteTeamGoal(goal: TeamGoalInfo) {
        if (userOrgLeader) {
                // TODO: add prompt "Are you sure you want to delete?"
            deleteTeamGoal(goal.goal_id).then(() => {
                fetchGoals(user.org_id, showActiveOnly);
            }).catch((e) => {
                console.error("Failed to delete team goal: " + e, " goal: " + goal);
                // TODO: handle failure
            });
        }
    }

    function handleSave(updatedItem: TeamGoalInfo) {
        if (userOrgLeader) {
            updateTeamGoalAggregate(updatedItem)
                .then(() => {
                    fetchGoals(user.org_id, showActiveOnly);
                })
                .catch((e) => {
                    // TODO: handle failure
                });
            }
    }

    const renderTeamGoals = () =>
        goals.map((value) => {
            const visible = !(showActiveOnly && value.goal_achieved_date);
            return visible && (
                <ListItemContainer
                    itemInfo={value}
                    // TODO: replace Date.now with a unique id in the mapping array
                    key={`goal-${value.goal_id}-${Date.now()}`}
                    onSave={handleSave}
                    onDelete={handleDeleteTeamGoal}
                    onTeamGoalCompleted={handleTeamGoalCompleted}
                    onKpiCompleted={handleKpiCompleted}
                    showActiveOnly={showActiveOnly}
                    viewLevelDetails={viewLevelDetails}
                    userOrgLeader={userOrgLeader}
                    teamMates={teamMates}
                />
            );
        });


    function handleChangeDetailsView() {
        setViewLevelDetails(!viewLevelDetails);
    }

    function handleNewTeamGoal() {
        setCreateTeamGoal(true);
    }

    const handleActiveOnlyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setShowActiveOnly(event.target.checked);
        fetchGoals(user.org_id, event.target.checked);
    };

    const renderTopToolBar = () => (
        <Grid2 container sx={{my: 2}} justifyContent="flex-end" alignItems="center">
            {(userOrgLeader && (!goals || goals.length == 0) && !isLoading && !createTeamGoal) ?
            (
                <Grid2 justifyItems={"flex-end"}>
                    <Player
                        src={animationArrow}
                        className="player"
                        loop
                        autoplay
                        style={{ height: '60px', width: '60px' }}                            
                    />
                </Grid2>
            ) : (
                <FormControlLabel
                    label="active only"
                    control={<Switch id="teamgoals-viewStatusChange-switch" defaultChecked onChange={handleActiveOnlyChange}/>}
                />
            )}
            {goals && goals.length > 0 && (
                <>
                    {viewLevelDetails ? (
                        <Tooltip title="Less details" arrow>
                            <Button color="info" onClick={handleChangeDetailsView}>
                                <ZoomOutOutlinedIcon/>
                            </Button>
                        </Tooltip>
                    ) : (
                        <Tooltip title="More details" arrow>
                            <Button color="info" onClick={handleChangeDetailsView}>
                                <ZoomInOutlinedIcon/>
                            </Button>
                        </Tooltip>
                    )}
                </>
            )}
            {userOrgLeader && 
                <Button variant="contained" onClick={handleNewTeamGoal}>+ New Goal</Button>
            }
        </Grid2>
    );

    const emptyItem: TeamGoalInfo = {
        goal_id: 0,
        strategy_id: strategyId,
        goal_description: "",
        goal_kpis: [],
        goal_status: GOAL_STATUS.ACTIVE,
        goal_target_date: null,
        goal_created_date: dayjs().format("YYYY-MM-DD"),
        goal_created_user_id: user?.user_id || 0,
        goal_achieved_date: null,
        goal_achieved_user_id: null,
    };

    function handleCreateTeamGoal(newTeamGoal: TeamGoalInfo) {
        createTeamGoals(strategyId, newTeamGoal).then((response) => {
            const newTeamGoal = response as TeamGoalInfo;
            console.log("Created new team goal: " + JSON.stringify(newTeamGoal));
            goals.push(newTeamGoal);
            setCreateTeamGoal(false);
        }).catch((e) => {
            console.error("Failed to create new team goal: " + e);
            // TODO: handle error
        });
    }

    return (
        <Box sx={{width: "100%", maxWidth: {sm: "100%", md: "1700px"}}}>
          <Grid2 container sx={{ justifyContent: "center", alignItems: "center", }} >
            <Grid2 size={12}>
                <PageHeader
                    title="Team objectives" 
                    subtitle="This is what your team is working on."
                    subtitle2="Focus on outcomes: start with the most important objectives and 1-3 indicators of success (KR or KPI)."
                />
            </Grid2>
            {userOrgLeader && !isLoading && !createTeamGoal && isFirstGoal && goals && goals.length > 0 && goals[0].goal_kpis && goals[0].goal_kpis.length > 0 && 
            <>
                <Grid2 size={{ xs: 12, sm: 6 }}>
                    <Player
                        src={animationHighFive}
                        className="player"
                        loop
                        autoplay
                        style={{ height: '60%', width: '60%' }}
                    />            
                </Grid2>
                <Grid2 size={{ xs: 12, sm: 6 }}>
                    <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        onClick={() => handleClick(PathConstants.FEEDBACK)}
                    >
                        Awesome! Let's test some Feedback
                    </Button>                    
                </Grid2>
            </>
            }
            <Grid2 size={12}>
            {
                    (createTeamGoal && userOrgLeader) ? (
                        <>
                            <TeamGoalCardEdit goalEdit={emptyItem} userId={user.user_id} onCancel={() => setCreateTeamGoal(false)}
                                                    onSave={handleCreateTeamGoal}/>
                        </>
                    ) : (
                        <>
                            {renderTopToolBar()}
                            {userOrgLeader && !isLoading && !createTeamGoal && isFirstGoal && goals && goals.length > 0 && (!goals[0].goal_kpis || (goals[0].goal_kpis && goals[0].goal_kpis.length === 0)) && 
                                <Alert 
                                    key="notification-missing-kpi"
                                    variant="outlined" 
                                    severity={'warning'}
                                >
                                    Good start: your first goal! Please add a Key Result (KR or KPI): edit <EditOutlinedIcon fontSize="medium"/> the goal and then press "Add KR".
                                </Alert>
                            }
                            {goals && renderTeamGoals()}
                        </>)
                }
            </Grid2>
            {userOrgLeader && (!goals || goals.length == 0) && !isLoading && !createTeamGoal && 
                <StyledEmptyState />
            }
          </Grid2>
          </Box>
    );
};