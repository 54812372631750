import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme, Tooltip, Grid2, Typography, Button } from '@mui/material';

import { Player } from '@lottiefiles/react-lottie-player';

import { PathConstants } from '../../utils/nav';

import { PageHeader } from '../_PageHeader';

interface OnboardingOrgLeaderProps {
  slackComplete: boolean;
  goalsComplete: boolean;
  receivedFeebackComplete: boolean;
  actionComplete: boolean;
}


const OnboardingUser = ({
  slackComplete,
  goalsComplete,
  receivedFeebackComplete,
  actionComplete,
}: OnboardingOrgLeaderProps) => {
  
  const theme = useTheme();
  const navigate = useNavigate();

  // Lottie file paths (replace with actual files)
  const feedbackLottie = '/static/lotties/feedback-rating.json';
  const actionLottie = '/static/lotties/action-item.json';

  const handleClick = (path: string) => {
    navigate(path);
  };

  const getLottieProps = (src: string, complete: boolean, loop: boolean = true) => ({
    src,
    loop: loop,
    autoplay: true,
    style: {
      width: 100,
      height: 100,
      cursor: 'pointer',
      filter: !complete
        ? theme.palette.mode === 'dark'
          ? 'invert(1)'
          : 'none'
        : theme.palette.mode === 'dark'
          ? 'grayscale(1) invert(1) opacity(0.5)'
          : 'grayscale(1) opacity(0.5)',
    },
  });


  return (
    <Grid2
      width="100%"
      container
      spacing={1}
//      padding={1}
      columns={12}
      sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}
    >
      <Grid2 size={12}>
        <PageHeader title="Hi! Almost there..." 
          subtitle="Feel free to explore the app, but first take some action so branco.ai can do some work for you:"
          subtitle2=""
        />
      </Grid2>
      <Grid2 size={{ xs: 12, sm: 3}}>
        <Tooltip title="Request a feedback from teammates">
          <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}} onClick={() => handleClick(PathConstants.FEEDBACK)}>
            <Player {...getLottieProps(feedbackLottie, receivedFeebackComplete, slackComplete && goalsComplete && !receivedFeebackComplete && !actionComplete)} />
            <Button
              variant={receivedFeebackComplete? "outlined" : "contained"} 
              size="small"
              color="primary"
              disabled={receivedFeebackComplete}
            >
              1. Request Feedback
            </Button>
          </div>
        </Tooltip>
      </Grid2>
      <Grid2 size={{ xs: 12, sm: 3}}>
        <Tooltip title="Build your Action Plan">
          <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}} onClick={() => handleClick(PathConstants.ACTION_PLAN)}>
            <Player {...getLottieProps(actionLottie, actionComplete, slackComplete && goalsComplete && receivedFeebackComplete && !actionComplete)} />
            <Button
              variant={actionComplete? "outlined" : "contained"} 
              size="small"
              color="primary"
              disabled={actionComplete}
            >
              2. Create a Plan
            </Button>
          </div>
        </Tooltip>        
      </Grid2>
    </Grid2>
  );
};

export default OnboardingUser;
