import * as React from 'react';
import { useNavigate } from "react-router-dom";

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { PathConstants } from '../../../utils/nav';

const RECOMMENDED_TIER = "Core"

const tiers = [
  {
    title: 'Free (Offer ends May 1st)',
    price: '0',
    description: [
      'Team OKRs',
      'Monthly growth plan AI review',
      'Peer Feedback on team impact and growth plan',
      'Manual and weekly feedback triggers',
      '1 team included',
      'Self service onboarding',
      'Best support, always included',
    ],
    buttonText: 'Sign up for free',
    buttonVariant: 'outlined',
    buttonColor: 'primary',
    buttonRedirect: PathConstants.SIGN_UP,
  },
  {
    title: RECOMMENDED_TIER,
    subheader: 'Recommended',
    price: '10/user',
    description: [
      'Company-wide Strategy and OKRs cascade',
      'Weekly growth plan AI review',
      '360 Feedback on skills, behavior, team impact and growth plan',
      'Automated feedback triggers',
      'Unlimited teams included',
      'Assisted onboarding and setup',
      'Dual-reporting/Cross-functional org design',
      'Best support, always included',
    ],
    buttonText: 'Request a Demo',
    buttonVariant: 'outlined',
    buttonColor: 'primary',
    buttonRedirect: 'https://calendar.app.google/uM679JaxsgF8UkHX6',
    buttonRedirectNewWindow: true,
  },
  {
    title: 'Advanced',
    price: '15/user',
    description: [
      'Company-wide Strategy and OKRs cascade',
      'Daily growth plan AI review',
      '360 Feedback on skills, behavior, team impact and growth plan',
      'On event feedback triggers (calendar, Slack)',
      'Unlimited teams included',
      'Assisted onboarding and setup',
      'Dual-reporting/Cross-functional org design',
      'Skill and Competency matrix templates',
      'Automated calibration scheduling',
      'One-click performance reviews',
      'Best support, always included',
    ],
    buttonText: 'Contact us',
    buttonVariant: 'outlined',
    buttonColor: 'primary',
    buttonRedirect: 'https://calendar.app.google/R9ser6zL6jPHS1uv6',
    buttonRedirectNewWindow: true,
  }
];

export default function Pricing() {
  const navigate = useNavigate();

  const redirectClick = (route: string) => {
    navigate(route);
  };

  return (
    <Container
      id="pricing"
      sx={{
        pt: { xs: 12, sm: 12 },
        pb: { xs: 8, sm: 8 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        <Typography
          component="h2"
          variant="h4"
          gutterBottom
          sx={{ color: 'text.primary' }}
        >
          Pricing
        </Typography>
        <Typography variant="body1" sx={{ color: 'text.secondary' }}>
          Right for your needs, cancel anytime.
        </Typography>
      </Box>
      <Grid
        container
        spacing={3}
        sx={{ alignItems: 'stretch', justifyContent: 'center', width: '100%' }}
      >
        {tiers.map((tier) => (
          <Grid
            size={{ xs: 12, sm: tier.title === 'Enterprise' ? 12 : 6, md: 4 }}
            key={tier.title}
          >
            <Card
              sx={[
                {
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 4,
                },
                tier.title === RECOMMENDED_TIER &&
                  ((theme) => ({
                    border: 'none',
                    background:
                      'radial-gradient(circle at 50% 0%, hsl(220, 20%, 35%), hsl(220, 30%, 6%))',
                    boxShadow: `0 8px 12px hsla(220, 20%, 42%, 0.2)`,
                    ...theme.applyStyles('dark', {
                      background:
                        'radial-gradient(circle at 50% 0%, hsl(220, 20%, 20%), hsl(220, 30%, 16%))',
                      boxShadow: `0 8px 12px hsla(0, 0%, 0%, 0.8)`,
                    }),
                  })),
              ]}
            >
              <CardContent>
                <Box
                  sx={[
                    {
                      mb: 1,
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      gap: 2,
                    },
                    tier.title === RECOMMENDED_TIER
                      ? { color: 'grey.100' }
                      : { color: '' },
                  ]}
                >
                  <Typography component="h3" variant="h6">
                    {tier.title}
                  </Typography>
                  {tier.title === RECOMMENDED_TIER && (
                    <Chip icon={<AutoAwesomeIcon />} label={tier.subheader} />
                  )}
                </Box>
                <Box
                  sx={[
                    {
                      display: 'flex',
                      alignItems: 'baseline',
                    },
                    tier.title === RECOMMENDED_TIER
                      ? { color: 'grey.50' }
                      : { color: null },
                  ]}
                >
                  <Typography component="h3" variant="h2">
                    ${tier.price}
                  </Typography>
                  <Typography component="h3" variant="h6">
                    &nbsp;per month
                  </Typography>
                </Box>
                <Divider sx={{ my: 2, opacity: 0.8, borderColor: 'divider' }} />
                {tier.description.map((line) => (
                  <Box
                    key={line}
                    sx={{ py: 1, display: 'flex', gap: 1.5, alignItems: 'center' }}
                  >
                    <CheckCircleRoundedIcon
                      sx={[
                        {
                          width: 20,
                        },
                        tier.title === RECOMMENDED_TIER
                          ? { color: 'primary.light' }
                          : { color: 'primary.main' },
                      ]}
                    />
                    <Typography
                      variant="subtitle2"
                      component={'span'}
                      sx={[
                        tier.title === RECOMMENDED_TIER
                          ? { color: 'grey.50' }
                          : { color: null },
                      ]}
                    >
                      {line}
                    </Typography>
                  </Box>
                ))}
              </CardContent>
              <CardActions>
                {tier.buttonRedirectNewWindow ? 
                    (
                      <Button
                        fullWidth
                        variant={tier.buttonVariant as 'outlined' | 'contained'}
                        color={tier.buttonColor as 'primary' | 'secondary'}
                        aria-label={tier.buttonText}
                        href={tier.buttonRedirect} target="_blank" 
                      >
                        {tier.buttonText}
                      </Button>
                    ): (
                      <Button
                        fullWidth
                        variant={tier.buttonVariant as 'outlined' | 'contained'}
                        color={tier.buttonColor as 'primary' | 'secondary'}
                        aria-label={tier.buttonText}
                        onClick={() => redirectClick(tier.buttonRedirect)}
                      >
                        {tier.buttonText}
                      </Button>
                  )}
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
