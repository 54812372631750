import * as React from 'react';
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import MuiToolbar from '@mui/material/Toolbar';
import { tabsClasses } from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';

import { Button, Icon } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { PathConstants } from "../../utils/nav";

import SideMenuMobile from './SideMenuMobile';
import MenuButton from './MenuButton';

import { useTheme } from '@mui/system';
import ColorModeIconDropdown from '../shared-theme/ColorModeIconDropdown';
import { BrancoIcon, BrancoLogoMobile, BrancoLogoMobileWhite } from '../_common/CustomIcons';
import ImpersonateReports from "./ImpersonateReports";


const Toolbar = styled(MuiToolbar)({
  width: '100%',
  padding: '12px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  justifyContent: 'center',
  gap: '12px',
  flexShrink: 0,
  [`& ${tabsClasses.flexContainer}`]: {
    gap: '8px',
    p: '8px',
    pb: 0,
  },
});

export default function AppNavbar() {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();

  const navigate = useNavigate();
  
  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        display: { xs: 'auto', md: 'none' },
        boxShadow: 0,
        bgcolor: 'background.paper',
        backgroundImage: 'none',
        borderBottom: '1px solid',
        borderColor: 'divider',
        top: 'var(--template-frame-height, 0px)',
      }}
    >
      <Toolbar variant="regular">
        <Stack
          direction="row"
          sx={{
            alignItems: 'center',
            flexGrow: 1,
            width: '100%',
            gap: 1,
          }}
        >
          <Stack
            direction="row"
            spacing={1}
            sx={{ justifyContent: 'center', mr: 'auto' }}
          >
            {/*<CustomIcon />*/}
            <Button aria-label='home' onClick={() => navigate(PathConstants.HOME)}>
              <Icon sx={{ width:10 }}></Icon>
              <Typography variant="h4" component="h1" sx={{ display: { xs: 'block', sm: 'none', width:50 }, color: 'text.primary' }}>
                {theme.palette.mode === 'light' ? <BrancoLogoMobile /> : <BrancoLogoMobileWhite />}
              </Typography>
              <Typography variant="h4" component="h1" sx={{ display: { xs: 'none', sm: 'block' }, color: 'text.primary' }}>
                {theme.palette.mode === 'light' ? <BrancoLogoMobile /> : <BrancoLogoMobileWhite />}
              </Typography>
            </Button>
          </Stack>
          <ImpersonateReports />
          <ColorModeIconDropdown />
          <MenuButton aria-label="menu" onClick={toggleDrawer(true)}>
            <MenuRoundedIcon />
          </MenuButton>
          <SideMenuMobile open={open} toggleDrawer={toggleDrawer} />
        </Stack>
      </Toolbar>
    </AppBar>
  );
}

export function CustomIcon() {
  return (
    <Box
      sx={{
        width: '1.5rem',
        height: '1.5rem',
        bgcolor: 'black',
        borderRadius: '999px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        backgroundImage:
          'linear-gradient(135deg, hsl(210, 98%, 60%) 0%, hsl(210, 100%, 35%) 100%)',
        color: 'hsla(210, 100%, 95%, 0.9)',
        border: '1px solid',
        borderColor: 'hsl(210, 100%, 55%)',
        boxShadow: 'inset 0 2px 5px rgba(255, 255, 255, 0.3)',
      }}
    >
      <DashboardRoundedIcon color="inherit" sx={{ fontSize: '1rem' }} />
    </Box>
  );
}