import * as React from 'react';
import { useContext } from "react";

import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { SparkLineChart } from '@mui/x-charts/SparkLineChart';
import { areaElementClasses } from '@mui/x-charts/LineChart';

import { UserContext } from "../../utils/context.js";
import { getTrends } from '../../services/api/Dashboard';


export type StatCardXProps = {
  title: string;
  trend_type: string;
};

function AreaGradient({ color, id }: { color: string; id: string }) {
  return (
    <defs>
      <linearGradient id={id} x1="50%" y1="0%" x2="50%" y2="100%">
        <stop offset="0%" stopColor={color} stopOpacity={0.3} />
        <stop offset="100%" stopColor={color} stopOpacity={0} />
      </linearGradient>
    </defs>
  );
}

export default function StatCardX({
  title,
  trend_type,
}: StatCardXProps) {
  const theme = useTheme();
  const [user] = useContext(UserContext);

  const [trend, setTrend] = React.useState<'up' | 'down' | 'neutral'>('neutral');
  const [statcardtrend, setStatCardTrends] = React.useState<StatCardTrendInfo>();

  const fetchTrends = async (id: number) => {
    await getTrends(id, trend_type).then((results) => {
      if (results) {
        setStatCardTrends(results)
        switch (results.time_series_trend){
          case "up": {
            setTrend('up');
            break;
          }
          case "down": {
            setTrend('down');
            break;
          }
          default: {setTrend('neutral');}
        }
      }
    }).catch((err) => {
      console.error("Failed to fetch trends: ", id, trend_type, err);
    });
  };

  React.useEffect(() => {
    try {
      if (user){
        if (trend_type.startsWith("USER")) {
          fetchTrends(user.user_id);
        } else if (trend_type.startsWith("TEAM")) {
          fetchTrends(user.org_id);
        } else if (trend_type.startsWith("COMPANY")) {
          fetchTrends(user.company_id);
        } 
      }
    } catch (err) {
      console.error("Failed to retrieve trends. ", err);
    }
  }, [user]);


  const trendColors = {
    up:
      theme.palette.mode === 'light'
        ? theme.palette.success.main
        : theme.palette.success.dark,
    down:
      theme.palette.mode === 'light'
        ? theme.palette.error.main
        : theme.palette.error.dark,
    neutral:
      theme.palette.mode === 'light'
        ? theme.palette.grey[400]
        : theme.palette.grey[700],
  };

  const labelColors = {
    up: 'success' as const,
    down: 'error' as const,
    neutral: 'default' as const,
  };

  const color = labelColors[trend];
  const chartColor = trendColors[trend];
  
  return (
    <Card variant="outlined" sx={{ height: '100%', flexGrow: 1 }}>
      {statcardtrend && 
        <CardContent>
          <Typography component="h2" variant="subtitle2" gutterBottom>
            {title}
          </Typography>
          <Stack
            direction="column"
            sx={{ justifyContent: 'space-between', flexGrow: '1', gap: 1 }}
          >
            <Stack sx={{ justifyContent: 'space-between' }}>
              <Stack
                direction="row"
                sx={{ justifyContent: 'space-between', alignItems: 'center' }}
              >
                <Typography variant="h4" component="p">
                  {statcardtrend.time_series_range_value}{statcardtrend.time_series_range_value_suffix}
                </Typography>
                <Chip size="small" color={color} label={"last " + statcardtrend.time_series_trend_timeframe_number + " " + statcardtrend.time_series_trend_timeframe_unit + " trend: " + statcardtrend.time_series_trend_percentage + "%"} />
              </Stack>
              <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                {"last " + statcardtrend.time_series_range_timeframe_number + " " + statcardtrend.time_series_range_timeframe_unit}
              </Typography>
            </Stack>
            <Box sx={{ width: '100%', height: 50 }}>
              <SparkLineChart
                colors={[chartColor]}
                data={statcardtrend.data}
                area
                showHighlight
                showTooltip
                xAxis={{
                  scaleType: 'band',
                  data: statcardtrend.x_data,
                }}
                sx={{
                  [`& .${areaElementClasses.root}`]: {
                    fill: `url(#area-gradient-${statcardtrend.time_series_range_value})`,
                  },
                }}
              >
                <AreaGradient color={chartColor} id={`area-gradient-${statcardtrend.time_series_range_value}`} />
              </SparkLineChart>
            </Box>
          </Stack>
        </CardContent>
      }
    </Card>
  );
}