import {FormControl, IconButton, Paper, Stack, TextField, Tooltip} from "@mui/material";
import React, { useState } from "react";
import Grid from '@mui/material/Grid2';

import DeleteIcon from "@mui/icons-material/Delete";

interface StepEditProps {
    stepInfo: ActionItemStepInfo;
    onDeleteStep: (step: ActionItemStepInfo) => void;
    onChange: (updatedStep: ActionItemStepInfo) => void;
}

export const StepEdit = ({
                             stepInfo,
                             onDeleteStep,
                             onChange,
                         }: StepEditProps) => {
    const [stepDescriptionError, setStepDescriptionError] = useState(false);
    const [stepTargetDateError, setStepTargetDateError] = useState(false);

    function handleOnChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        const {name, value} = event.target;
        const updatedStep = {
            ...stepInfo,
            [name]: value,
        };
        onChange(updatedStep);
    }

    function handleOnStepDescriptionChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        const {name, value, required} = event.target;
        if (required && value.length == 0){
            setStepDescriptionError(true)
        } else {
            setStepDescriptionError(false)
        }
        const updatedStep = {
            ...stepInfo,
            [name]: value,
        };
        onChange(updatedStep);
    }
    function handleOnStepTargetDateChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        const {name, value, required} = event.target;
        if (required && value.length == 0){
            setStepTargetDateError(true)
        } else {
            setStepTargetDateError(false)
        }
        const updatedStep = {
            ...stepInfo,
            [name]: value,
        };
        onChange(updatedStep);
    }

    const handleDeleteStep = () => {
        onDeleteStep(stepInfo);
    }

    return (
        <Stack key={stepInfo.user_action_plan_step_id} direction={"row"} sx={{width: '100%'}}>
            <Paper sx={{padding: 2, flex: 1}}>
                <Grid container spacing={2}>
                    <Grid size={{ xs: 12, sm: 8 }}>
                        <FormControl fullWidth>
                            <TextField
                                size={"small"}
                                id="description"
                                label="Step Description"
                                required
                                defaultValue={stepInfo.user_action_plan_step_description}
                                name={"user_action_plan_step_description"}
                                onChange={handleOnStepDescriptionChange}
                                error={stepDescriptionError}
                                helperText={stepDescriptionError ? "Please enter Step Description" : ""}
                                    />
                        </FormControl>
                    </Grid>
                    <Grid size={{ xs: 12, sm: 4 }}>
                        <TextField
                            size={"small"}
                            fullWidth
                            id="target_date"
                            required
                            label="Target Date"
                            type="date"
                            defaultValue={stepInfo.user_action_plan_step_target_date}
                            name={"user_action_plan_step_target_date"}
                            slotProps={{inputLabel: {shrink: true}}}
                            onChange={handleOnStepTargetDateChange}
                            error={stepTargetDateError}
                            helperText={stepTargetDateError ? "Please enter Target Date" : ""}
                        />
                    </Grid>
                    <Grid size={{ xs: 12, sm: 8 }}>
                        <FormControl fullWidth>
                            <TextField
                                size={"small"}
                                id="measurement"
                                label="Measurement (success criteria)"
                                defaultValue={stepInfo.user_action_plan_step_measurement}
                                name={"user_action_plan_step_measurement"}
                                onChange={handleOnChange}
                            />
                        </FormControl>
                    </Grid>
                    <Grid size={{ xs: 12, sm: 4 }}>
                        {stepInfo.user_action_plan_step_date_completed && 
                            <TextField
                                size={"small"}
                                id="completed_date"
                                label="Date Completed"
                                type="date"
                                defaultValue={stepInfo.user_action_plan_step_date_completed}
                                name={"user_action_plan_step_date_completed"}
                                slotProps={{inputLabel: {shrink: true}}}
                                onChange={handleOnChange}
                            />
                        }
                    </Grid>
                </Grid>
            </Paper>
            <Tooltip title="Delete this step" arrow>
                <IconButton aria-label="delete" size="medium" color={"default"}
                            sx={{
                                ml: 'auto', flex: 'none', border: "none",     // Remove any applied border
                                outline: "none",    // Remove focus outline
                                "&:focus": {
                                    outline: "none",  // Ensure focus styles are removed
                                }
                            }} onClick={handleDeleteStep}>
                    <DeleteIcon fontSize="small" color={"error"}/>
                </IconButton>
            </Tooltip>
        </Stack>
    );
}
