import { get, post, put, delete_api } from "./helpers";

type UpdateActionPlanStep = {
  user_action_plan_step_id: number | null;
  user_action_plan_step_description: string;
  user_action_plan_step_measurement: string;
  user_action_plan_step_target_date: string;
  user_action_plan_step_date_completed: string | null;
};

type UpdateActionPlan = {
  user_action_plan_id: number;
  user_action_plan_priority: number;
  user_action_plan_goal: string;
  user_action_plan_business_value: string;
  user_action_plan_date_completed: string | null;
  user_action_plan_steps: Array<UpdateActionPlanStep>;
};


export const getActionPlan = async (
  UserId: number,
): Promise<ActionItemInfo[]> => {
  try {
    const params = {user_id: UserId.toString()};
    return await get<ActionItemInfo[]>("/user_action_plans?" + new URLSearchParams(params).toString());
  } catch (err) {
    console.error(err);
    return [];
  }  
};

export const getActionPlanCount = async (
  UserId: number,
): Promise<number> => {
  try {
    const params = {user_id: UserId.toString()};
    return await get<number>("/user_action_plans/count?" + new URLSearchParams(params).toString());
  } catch (err) {
    console.error(err);
    return 0;
  }  
};

export const createActionPlan = async (
  userId: number,
  actionItem: ActionItemInfo
): Promise<ActionItemInfo | null> => {
  try {
    const actionPlanBody = {
      ...actionItem,
      user_action_plan_steps: actionItem.user_action_plan_steps?.map(step => ({
          ...step,
          // Drop the negative step IDs for new steps (the backend will assign new IDs)
          user_action_plan_step_id: (step.user_action_plan_step_id == null || step.user_action_plan_step_id < 0) ?
              null :
              step.user_action_plan_step_id,
      })),
      user_id: userId
  };
  return await post<ActionItemInfo>("/user_action_plans", 
    actionPlanBody,
    );
  } catch (err) {
      console.error(err);
      return null;
    } 
};

export const deleteActionPlan = async (
  planId: number
): Promise<ActionItemInfo | null> => {
  try {
    const params = {plan_id: planId.toString()};
    return await delete_api<ActionItemInfo>("/user_action_plans/plan_id?" + new URLSearchParams(params).toString());
  } catch (err) {
    console.error(err);
    return null;
  }  
};

export const updateActionPlan = async (
  actionPlan: ActionItemInfo
): Promise<ActionItemInfo | null> => {
  try {
    const params = {plan_id: actionPlan.user_action_plan_id.toString()};
    const actionPlanBody: UpdateActionPlan = {
      user_action_plan_id: actionPlan.user_action_plan_id,
      user_action_plan_priority: Number(actionPlan.user_action_plan_priority),
      user_action_plan_goal: actionPlan.user_action_plan_goal,
      user_action_plan_business_value: actionPlan.user_action_plan_business_value,
      user_action_plan_date_completed: actionPlan.user_action_plan_date_completed || null,
      user_action_plan_steps: actionPlan.user_action_plan_steps || [],
    };

    return await put<ActionItemInfo>("/user_action_plans/plan_id?" + new URLSearchParams(params).toString(), 
      actionPlanBody,
    );
  } catch (err) {
      console.error(err);
      return null;
    } 
};

export const updateActionPlanAggregate = async (
  actionPlan: ActionItemInfo
): Promise<ActionItemInfo | null> => {
  try {
    const actionPlanBody: UpdateActionPlan = {
      user_action_plan_id: actionPlan.user_action_plan_id,
      user_action_plan_priority: Number(actionPlan.user_action_plan_priority),
      user_action_plan_goal: actionPlan.user_action_plan_goal,
      user_action_plan_business_value: actionPlan.user_action_plan_business_value,
      user_action_plan_date_completed: actionPlan.user_action_plan_date_completed || null,
      user_action_plan_steps: (actionPlan.user_action_plan_steps || []).map((step) => {
          return {
              // Drop the negative step IDs for new steps (the backend will assign new IDs)
              //user_action_plan_step_id: step.user_action_plan_step_id,
              user_action_plan_step_id: (step.user_action_plan_step_id == null || step.user_action_plan_step_id < 0) ?
              null :
              step.user_action_plan_step_id,
              user_action_plan_step_description: step.user_action_plan_step_description,
              user_action_plan_step_measurement: step.user_action_plan_step_measurement,
              user_action_plan_step_target_date: step.user_action_plan_step_target_date,
              user_action_plan_step_date_completed: step.user_action_plan_step_date_completed || null,
          };
      }),
  };

    return await put<ActionItemInfo>("/user_action_plans/" + actionPlan.user_action_plan_id.toString(), 
      actionPlanBody,
    );
  } catch (err) {
      console.error(err);
      return null;
    } 
};


export const updateActionPlanStep = async (
  step: ActionItemStepInfo
): Promise<ActionItemInfo | null> => {
  try {
    const params = {plan_id: step.user_action_plan_id.toString(),
      step_id: step.user_action_plan_step_id!.toString()};

    return await put<ActionItemInfo>("/user_action_plans/plan_id/steps/step_id?" + new URLSearchParams(params).toString(), 
      step,
    );
  } catch (err) {
      console.error(err);
      return null;
    } 
};
