import React, { useState } from "react";
import { useTheme, Button, Typography, Box, TextField, IconButton, Tooltip } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import { PathConstants } from '../../utils/nav';

import { PageHeader } from '../_PageHeader';


interface InviteUserProps {
  inviteLink: string;
}

const InviteUser = ({
  inviteLink,
}: InviteUserProps) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(inviteLink);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <Box textAlign="center" p={3}>
      <Typography variant="body2" color="text.secondary" gutterBottom>
        Share this link with your team members to invite them to branco.ai <br />
        (remind them to use their work emails)
      </Typography>
      <Box display="flex" alignItems="center" justifyContent="center" mt={2}>
        <TextField
          value={inviteLink}
          size="small"
          variant="outlined"
          fullWidth
          InputProps={{
            readOnly: true,
          }}
          sx={{ maxWidth: 300 }}
        />
        <Tooltip title={copied ? "Copied!" : "Copy to clipboard"}>
          <IconButton onClick={handleCopy} sx={{ ml: 1 }}>
            <ContentCopyIcon color={copied ? "success" : "inherit"} />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default InviteUser;
