import * as React from 'react';
import Box from '@mui/material/Box';
import Grid2 from '@mui/material/Grid2';

import { PageHeader } from '../_PageHeader';

import ExpectationsSkills from './ExpectationsSkills';
import ExpectationsCompetencies from './ExpectationsCompetencies';
//import UserCareerPath from './UserCareerPath';
import ExpectationsTeamImpact from './ExpectationsTeamImpact';

import { UserContext, CompetenciesContext, SkillsContext } from "../../utils/context.js";
import { getFeedbacksReceivedCount } from "../../services/api/Feedback";
import { styled } from '@mui/material';


const StyledEmptyState = styled('div')(({ theme }) => ({
  alignSelf: 'center',
  width: '100%',
  height: 500,
  marginTop: theme.spacing(1),
  borderRadius: (theme).shape.borderRadius,
  //outline: '6px solid',
  outlineColor: 'hsla(220, 25%, 80%, 0.2)',
  border: '1px solid',
  borderColor: (theme).palette.grey[200],
  boxShadow: '0 0 12px 8px hsla(220, 25%, 80%, 0.2)',
  backgroundImage: `url(${process.env.REACT_APP_TEMPLATE_IMAGE_URL || 'https://branco.ai'}/static/empty-state/my-career_trans.png)`,
  backgroundSize: 'cover',
  [theme.breakpoints.up('xs')]: {
      marginTop: theme.spacing(1),
      height: 300,
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(1),
      height: 550,
    },
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(1),
      height: 650,
    },
      ...theme.applyStyles('dark', {
    boxShadow: '0 0 24px 12px hsla(210, 100%, 25%, 0.2)',
    backgroundImage: `url(${process.env.REACT_APP_TEMPLATE_IMAGE_URL || 'https://branco.ai'}/static/empty-state/my-career_trans.png)`,
    outlineColor: 'hsla(220, 20%, 42%, 0.1)',
    borderColor: (theme).palette.grey[700],
  }),
}));

export default function UserExpectationsProgressPage() {
  const [user] = React.useContext(UserContext);
  const [competencies] = React.useContext(CompetenciesContext);
  const [skills] = React.useContext(SkillsContext);
  
  const [hasFeedback, setHasFeedback] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
        try {
          if (user) {
            fetchFeedbackCount();
          }
        } catch (err) {
        console.error("Failed to retrieve my career data. ", err);
        }
    }, [user, competencies, skills]);

    const fetchFeedbackCount = async (
    ) => {
      var count: number = 0;
      count = await getFeedbacksReceivedCount(user.user_id);
      setHasFeedback(count > 0);
      setIsLoading(false);
    };
    

  return (
    <Box sx={{ width: '100%', maxWidth: { sm: '100%', md: '1700px' } }}>
      <PageHeader
        title="My career"
        subtitle="Review how you're doing compared to expectations."
        subtitle2={hasFeedback ? ('') : ('You need at least one feedback from others to see career data.')}
        />
      {/*
      <Grid2
        sx={{ mb: (theme) => theme.spacing(2) }}
        >
        <UserCareerPath />
      </Grid2>
      */}
        <Grid2
          container
          spacing={2}
          columns={12}
          sx={{ mb: (theme) => theme.spacing(2) }}
        >
        {(hasFeedback || competencies || skills) ? (
            <>
              <Grid2 size={{ xs: 12 }}>
                <ExpectationsTeamImpact />        
              </Grid2>
              <Grid2 size={{ xs: 12, sm: 6 }}>
                <ExpectationsSkills />
              </Grid2>
              <Grid2 size={{ xs: 12, sm: 6 }}>
                <ExpectationsCompetencies />
              </Grid2>
            </>
        ) : (
          (!isLoading && <StyledEmptyState />)
        )}
        </Grid2>
      </Box>
  );
}