import React, { useState, useEffect, useContext } from "react";
import { Box, Grid2, FormControlLabel, Switch, Chip, Tooltip, Stack } from "@mui/material";

import { UserContext } from "../../utils/context.js";

import { PageHeader } from "../_PageHeader";

import { FeedbackTrigger } from "./FeedbackTrigger";
import { FeedbacksTable } from "./FeedbacksTable";

export const FeedbackPage = () => {
  const [user] = useContext(UserContext);
  
  return (
    <Box sx={{ width: "100%", maxWidth: { sm: "100%", md: "1700px" } }}>
      <Grid2>
        <PageHeader
          title="Feedback"
          subtitle="Trigger new feedback, or review the feedback received and provided."
          subtitle2=""
        />
      </Grid2>
      <Grid2>
          <FeedbackTrigger />
      </Grid2>
      <Grid2>
          <FeedbacksTable />
      </Grid2>
    </Box>
  );
};
