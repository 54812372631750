import {useContext, useEffect, useState} from "react";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
//import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';


import { UserContext } from "../../utils/context.js";

import { getCurrentUser, getUser, myDirectReports } from "../../services/api/Users";
import { User } from "../../services/api/schemas/User";


export default function ImpersonateReports() {
  const [userContext, setUserContext] = useContext(UserContext);
  const [directReports, setDirectReports] = useState<User[]>();
  const [selectedUser, setSelectedUser] = useState<string>(""); 
  
  const fetchMyReports = async () => {
      return await myDirectReports().then((value) => {
        setDirectReports(value);
      });
  };

  useEffect(() => {
    try {
      if (userContext) {
        fetchMyReports();
      }
    } catch (err) {
          console.error("Failed to retrieve direct reports. ", err);
    }
  }, [userContext]);


  const swapContextUser = async (new_user: number) => {
    try {
      const user = await getUser(new_user);
      setUserContext(user);
      setSelectedUser(String(user.user_id));
      console.log("Manager impersonation - User impersonated:", user.user_name);
    } catch (err) {
      console.error("Failed to impersonate user.", err);
    }
  };

  const resetToMe = async () => {
    try {
      const currentUser = await getCurrentUser();
      setUserContext(currentUser);
      setSelectedUser(""); 
      console.log("Manager impersonation - User reset to self.");
    } catch (err) {
      console.error("Failed to reset impersonation.", err);
    }
  };

  const handleChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    if (value === "") {
      resetToMe();
    } else {
      swapContextUser(parseInt(value));
    }
  };

  return (
    <>
      {userContext && directReports && directReports.length > 0 && 
          <FormControl sx={{ m: 1, minWidth: 150 }} size="small" error>
            <InputLabel id="impersonate-select-helper-label">Impersonate</InputLabel>
            <Select
              labelId="impersonate-select-helper-label"
              id="impersonate-select-helper"
              value={selectedUser}
              aria-label="impersonate reports"
              label="Impersonate"
              onChange={handleChange}
            >
              <MenuItem key="impersonate-select-item-default" value="">Yourself</MenuItem>
              {directReports.map((directReport) => (
                <MenuItem key={"impersonate-select-item-" + directReport.user_id} value={directReport.user_id}>{`⚠️  - ${directReport.user_name}`}</MenuItem>
                ))}
            </Select>
          </FormControl>
      }
    </>
  );
}
