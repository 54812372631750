import { Theme, alpha, Components } from "@mui/material/styles";
import { gray, orange } from "../themePrimitives";
import { grey } from "@mui/material/colors";

export const feedbackCustomizations: Components<Theme> = {

  MuiAlert: {
    styleOverrides: {
      root: ({ theme }) => ({
        borderRadius: 10,
        color: theme.palette.text.primary,
        ...theme.applyStyles("dark", {
        }),
      }),
    },
  },

  MuiDialog: {
    styleOverrides: {
      root: ({ theme }) => ({
        "& .MuiDialog-paper": {
          borderRadius: "10px",
          border: "1px solid",
          borderColor: theme.palette.divider,
        },
      }),
    },
  },

  MuiLinearProgress: {
    styleOverrides: {
      root: ({ theme }) => ({
        height: 8,
        borderRadius: 8,
        backgroundColor: gray[200],
        ...theme.applyStyles("dark", {
          backgroundColor: gray[800],
        }),
      }),
    },
  },
};
