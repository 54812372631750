import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from 'react-router-dom';

import { Player } from '@lottiefiles/react-lottie-player';

import { Alert, Autocomplete, Box, Button, Checkbox, Grid2, Stack, TextField, Typography } from "@mui/material";

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';

import { PathConstants } from "../../utils/nav.js";
import { UserContext, SkillsContext, CompetenciesContext } from "../../utils/context.js";

import { triggerSlackFeedback, getFeedbacksCount } from "../../services/api/Feedback";
import { myTeam, myDialogsCount } from "../../services/api/Users";
import { User } from "../../services/api/schemas/User";

import { getCompanyToolCount } from "../../services/api/CompanyTool";
import { getTeamGoals } from "../../services/api/TeamGoals";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const animationHighFive = "/static/lotties/high-five.json";


export const FeedbackTrigger = () => {
  const [user] = useContext(UserContext);
  const [skills] = useContext(SkillsContext);
  const [competencies] = useContext(CompetenciesContext);
  const [teamMates, setMyTeam] = useState<User[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectTeamMates, setSelectTeamMates] = useState<User[]>([]);
  const [message, setMessage] = useState("");
  const [isFirstFeedback, setIsFirstFeedback] = useState<boolean>(false);
  const [hasKpis, setHasKpis] = useState<boolean>(false);
  const [slackComplete, setSlackComplete] = React.useState<boolean>(false);

  const SLACK_COMPANY_TOOL_ID = 1;

  const navigate = useNavigate();

  const handleClick = (path: string) => {
    navigate(path);
  };

  const fetchCompanyTool = async (
  ) => {
    return await getCompanyToolCount(user.company_id, SLACK_COMPANY_TOOL_ID).then((value) => {
      setSlackComplete(value > 0);
    });
  };

  const fetchMyTeam = async (org_id: number) => {
      return await myTeam(org_id).then((value) => {
        setMyTeam(value);
      });
  };

  const fetchFeedbackCount = async (
  ) => {
    var count, dialog_count: number = 0;
    count = await getFeedbacksCount(user.user_id);
    if (count > 0) 
      setIsFirstFeedback(false);
    else {
      dialog_count = await myDialogsCount();
      setIsFirstFeedback(dialog_count === 0);
    }
  };

  const fetchHasKpi = async (org_id: number) => {
      return await getTeamGoals(org_id, "").then((value) => {
        var foundKpi: boolean = false;
        const goals = value as TeamGoalInfo[];
        for (let i = 0; i < goals.length; i++) {
          if (goals[i].goal_kpis)
            for (let j = 0; j < goals[i].goal_kpis!.length; j++) {
              foundKpi = true;
              setHasKpis(foundKpi);
              break;
            }
          if (foundKpi)
            break;
        }
      });
  };

  
  useEffect(() => {
    try {
      if (user){
        fetchFeedbackCount();

        if (user.org_id){
          fetchMyTeam(user.org_id);
          fetchHasKpi(user.org_id);
        }

        if (user.company_id)
          fetchCompanyTool();
      }
    } catch (err) {
      console.error("Failed to retrieve myTeam. ", err);
    }
  }, [user, competencies, skills]);


  const sendFeedback = () => {
    if (selectTeamMates.length > 0) {
      var names = selectTeamMates[0].user_name!;
      setIsLoading(true);
      try {
        for (let i = 0; i < selectTeamMates.length; i++) {
          triggerSlackFeedback(user.user_id, selectTeamMates[i].user_id);
          if (i > 0)
            names += ", " + selectTeamMates[i].user_name!;
        }

        if (selectTeamMates.length === 1 && user.user_id === selectTeamMates[0].user_id)
          setMessage("Triggered self-reflection. Head to Slack to chat with Branco.");  
        else
          setMessage("Triggered feedback from you to " + names + ". Head to Slack to chat with Branco.");  

      } catch (err) {
        console.error("Failed to trigger feedback (sendFeedback).", err);
        //setMessage("Error in triggering feedback");  
      } finally {
        setIsLoading(false);
      }
    }
  };
  const getFeedback = () => {
    if (selectTeamMates.length > 0) {
      var names = selectTeamMates[0].user_name!;
      setIsLoading(true);
      try {
        for (let i = 0; i < selectTeamMates.length; i++) {
          triggerSlackFeedback(selectTeamMates[i].user_id, user.user_id);
          if (i > 0)
            names += ", " + selectTeamMates[i].user_name!;
        }
        
        if (selectTeamMates.length === 1 && user.user_id === selectTeamMates[0].user_id)
          setMessage("Triggered self-reflection. Head to Slack to chat with Branco.");  
        else
          setMessage("Sent feedback request to " + names + ". Branco is chatting with them on Slack.");

      } catch (err) {
        console.error("Failed to trigger feedback (getFeedback).", err);
        //setMessage("Error in triggering feedback request");  
      }    
      finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <Box sx={{ width: "100%", maxWidth: { sm: "100%", md: "1700px" } }}>
      <Grid2 container sx={{ my: 3 }} spacing={3} justifyContent="space-between" alignItems={"center"}>
        {!isLoading && isFirstFeedback && message.length > 0 ? (
          <>
            <Grid2 size={{ xs: 12, sm: 6 }}>
                <Player
                    src={animationHighFive}
                    className="player"
                    loop
                    autoplay
                    style={{ height: '60%', width: '60%' }}
                />            
            </Grid2>
            <Grid2 size={{ xs: 12, sm: 6 }}>
                <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    onClick={() => handleClick(PathConstants.HOME)}
                >
                    <ThumbUpOutlinedIcon />&nbsp;
                    Nice! Open Slack to chat with Branco.
                </Button>                    
            </Grid2>
          </>
        ) : (
          <>
            {!isLoading && !hasKpis && !skills && !competencies && 
                <Alert 
                    key="notification-missing-slack"
                    variant="outlined" 
                    severity={'info'}
                >
                    Feedback actions not enabled yet: team objectives incomplete. Your team objectives are missing goals with KR/KPI (and no competencies nor skills are defined).
                </Alert>
            }
            {!isLoading && !slackComplete && 
                <Alert 
                    key="notification-missing-kpi"
                    variant="outlined" 
                    severity={'info'}
                >
                    Feedback actions not enabled yet: Slack setup incomplete. Slack integration needs to be enabled for your company in Settings.
                </Alert>
            }
            <Grid2 size={{ xs: 12, sm: 6 }}>
              <Autocomplete
                multiple
                id="autocomp-team-mates"
                options={teamMates}
                disableCloseOnSelect
                onChange={(event, value) => {setSelectTeamMates(value); setIsLoading(false); setMessage("");}}
                getOptionLabel={(option) => option.user_name!}
                renderOption={(props, option, { selected }) => {
                  const { key, ...optionProps } = props;
                  return (
                    <li key={key} {...optionProps}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.user_name}
                      {option.user_id === user.user_id ? " (self)" : ""}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Select team member(s)" placeholder="type or select" />
                )}
              />
            </Grid2>
            <Grid2 size={{ xs: 6, sm: 3 }}>
              <Button 
                variant="outlined"
                size="small"
                color="primary"
                disabled={message.length > 0 || !slackComplete || (!isLoading && !hasKpis && !skills && !competencies)} onClick={() => getFeedback()}>
                Request feedback
              </Button>
            </Grid2>
            <Grid2 size={{ xs: 6, sm: 3 }}>
              <Button 
                variant="outlined"
                size="small"
                color="primary"
                disabled={message.length > 0 || selectTeamMates.length > 1 || !slackComplete || (!isLoading && !hasKpis && !skills && !competencies)} onClick={() => sendFeedback()}>
                Send feedback
              </Button>
            </Grid2>
            {message && 
              <Stack direction="row" alignContent={"center"}>
                <ThumbUpOutlinedIcon />
                <Typography sx={{ color: 'text.primary' }}>&nbsp;{message}</Typography>
              </Stack>
            }
          </>
        )}
      </Grid2>
    </Box>
  );
};
