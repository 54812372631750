import * as React from 'react';
import Box from '@mui/material/Box';

import { Accordion, AccordionDetails, AccordionSummary, Grid2, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {UserContext} from "../../utils/context";

import { isUserOrgLeader } from "../../services/api/Users";
import { getOrg } from "../../services/api/Orgs";

import { getCompanyToolCount } from "../../services/api/CompanyTool";
import { getTeamGoalsCount } from "../../services/api/TeamGoals";
import { getFeedbacksCount, getFeedbacksReceivedCount } from "../../services/api/Feedback";
import { getActionPlanCount } from "../../services/api/ActionPlan";
import { myTeam, myDialogsCount } from "../../services/api/Users";

import MainAlert from '../_spa/MainAlert';
import MainGridTeamMember from './MainGridTeamMember';
import MainGridTeamLeader from './MainGridTeamLeader';
import OnboardingOrgLeader from './OnboardingOrgLeader';

import { Org } from '../../services/api/schemas/Org';
import OnboardingUser from './OnboardingUser';

const SLACK_COMPANY_TOOL_ID = 1;


export default function MainGrid() {
  const [user] = React.useContext(UserContext);
  const [userOrgLeader, setUserOrgLeader] = React.useState<boolean>(false);
  const [org, setOrg] = React.useState<Org>();

  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  // Onboarding states
  const [slackComplete, setSlackComplete] = React.useState<boolean>(false);
  const [goalsComplete, setGoalsComplete] = React.useState<boolean>(false);
  const [oneFeedbackComplete, setOneFeedbackComplete] = React.useState<boolean>(false);
  const [teamJoinedComplete, setTeamJoinedComplete] = React.useState<boolean>(false);
  const [receivedFeebackComplete, setFeedbackReceivedComplete] = React.useState<boolean>(false);
  const [actionComplete, setActionComplete] = React.useState<boolean>(false);


  React.useEffect(() => {
      try {
      if (user && user.org_id) {
          fetchUserOrgLeadership(user.org_id);
      }
      } catch (err) {
      console.error("Failed to retrieve org leadership. ", err);
      }
  }, [user]);

  const fetchCompanyTool = async (
  ) => {
    return await getCompanyToolCount(user.company_id, SLACK_COMPANY_TOOL_ID).then((value) => {
      setSlackComplete(value > 0);
      fetchTeamGoalsCount();
    });
  };
  const fetchTeamGoalsCount = async (
  ) => {
    var count: number = 0;
    count = await getTeamGoalsCount(user.org_id);
    setGoalsComplete(count > 0);
    fetchFeedbackCount();
    fetchFeedbackReceivedCount();
};

  const fetchFeedbackCount = async (
  ) => {
    var count, dialog_count: number = 0;
    count = await getFeedbacksCount(user.user_id);
    if (count > 0) 
      setOneFeedbackComplete(count > 0);
    else {
      dialog_count = await myDialogsCount();
      setOneFeedbackComplete(dialog_count > 0);
    }
    fetchMyTeam();
  };
  const fetchMyTeam = async () => {
      return await myTeam(user.org_id).then((value) => {
        setTeamJoinedComplete(value.length > 1);
      });
  };


  const fetchFeedbackReceivedCount = async (
  ) => {
    var count, dialog_count: number = 0;
    count = await getFeedbacksReceivedCount(user.user_id);
    if (count > 0) 
      setFeedbackReceivedComplete(count > 0);
    else {
      dialog_count = await myDialogsCount();
      setFeedbackReceivedComplete(dialog_count > 0);
    }
    fetchActionPlanCount();  
  };
  const fetchActionPlanCount = async (
  ) => {
    var count: number = 0;
    count = await getActionPlanCount(user.user_id);
    setActionComplete(count > 0);
    setIsLoading(false);
  };

  
  const fetchUserOrgLeadership = async (org_id: number) => {
      return await isUserOrgLeader(org_id).then((value) => {
          //setUserOrgLeader(value);
          if (value) {
            setUserOrgLeader(value);
            fetchOrg(user.org_id);
          }
          fetchCompanyTool();
        });
  };

  const fetchOrg = async (org_id: number) => {
    return await getOrg(org_id).then((value) => {
      setOrg(value);
    });
};

  return (
    <Box sx={{ width: '100%', maxWidth: { sm: '100%', md: '1700px' } }}>
        <Grid2
          container
          spacing={1}
          columns={12}
        >
          <MainAlert />
            {(userOrgLeader) ? (
              <>
                {(!isLoading && (!slackComplete || !goalsComplete || !oneFeedbackComplete || !teamJoinedComplete)) &&
                  <OnboardingOrgLeader
                    slackComplete={slackComplete}
                    goalsComplete={goalsComplete}
                    oneFeedbackComplete={oneFeedbackComplete}
                    teamJoinedComplete={teamJoinedComplete}
                  />
                }
                {(!isLoading && slackComplete && goalsComplete && oneFeedbackComplete && teamJoinedComplete 
                && (!receivedFeebackComplete || !actionComplete)) &&
                  <OnboardingUser
                    slackComplete={slackComplete}
                    goalsComplete={goalsComplete}
                    receivedFeebackComplete={receivedFeebackComplete}
                    actionComplete={actionComplete}
                  />
                }
                <Grid2 size={{ xs: 12 }}>
                  <Accordion>
                      <AccordionSummary
                          expandIcon={<ExpandMoreIcon/>}
                          sx={{marginLeft: 2, paddingY: 2}}
                          id="panel-dash-team"
                      >
                          <Typography variant="h4">
                          {org && org.org_name} - Team dashboard
                          </Typography>
                      </AccordionSummary>
                      <AccordionDetails sx={{marginLeft: 2, paddingY: 2}}>
                        <MainGridTeamLeader />
                      </AccordionDetails>
                  </Accordion>
                </Grid2>
                <Grid2 size={{ xs: 12 }}>
                  {!isLoading && 
                    <Accordion defaultExpanded={(slackComplete && goalsComplete && oneFeedbackComplete)}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            sx={{marginLeft: 2, paddingY: 2}}
                            id="panel-dash-individual"
                        >
                            <Typography variant="h4">
                            {user.user_name} - Dashboard
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{marginLeft: 2, paddingY: 2}}>
                          <MainGridTeamMember />
                        </AccordionDetails>
                    </Accordion>
                  }
                </Grid2>
              </>
            ) : (
              <>
                {(!isLoading && slackComplete && teamJoinedComplete 
                && (!receivedFeebackComplete || !actionComplete)) &&
                  <OnboardingUser
                    slackComplete={slackComplete}
                    goalsComplete={goalsComplete}
                    receivedFeebackComplete={receivedFeebackComplete}
                    actionComplete={actionComplete}
                  />
                }
                <Grid2 size={{ xs: 12 }}>
                  {!isLoading && <MainGridTeamMember />}
                </Grid2>
              </>
            )}
        </Grid2>
      </Box>
  );
}