import React, { useContext, useEffect, useState } from "react";
import { Box, Grid2, Stack, Typography } from "@mui/material";

import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';

import { UserContext } from "../../utils/context";
import { slackConfig } from "../../services/slack/config";
//import { CompanyTool } from "../../services/api/schemas/CompanyTool";
import { getCompanyToolCount } from "../../services/api/CompanyTool";
import { PageHeader } from "../_PageHeader";

const SLACK_COMPANY_TOOL_ID = 1;

export const SlackIntegrate = () => {
    const [user] = useContext(UserContext);
    const [companyToolCount, setCompanyToolCount] = useState<number>(0);

    useEffect(() => {
        try {
        if (user && user.company_id) {
          fetchCompanyToolCount(user.company_id, SLACK_COMPANY_TOOL_ID);
        }
        } catch (err) {
        console.error("Failed to retrieve company tool. ", err);
        }
    }, [user]);

    const fetchCompanyToolCount = async (company_id: number, tool_id: number) => {
        return await getCompanyToolCount(company_id, tool_id).then((value) => {
            setCompanyToolCount(value);
        });
    };

  const getUrl = () => {
    return `${slackConfig.url}&client_id=${slackConfig.clientId}&redirectUrl=${slackConfig.redirectUrl}&state=${user.user_id}`;
  };

  return (
    <Box sx={{ width: "100%", maxWidth: { sm: "100%", md: "1700px" } }}>
      <PageHeader
        title="Slack Integration"
        subtitle="Connect Branco bot in your team Slack."
        subtitle2=""
        />
      {companyToolCount > 0 ? 
        (<Stack direction="row" spacing={2}>
            <CheckBoxOutlinedIcon />
            <Typography sx={{ color: 'text.primary', mb: '8px'  }}>Slack integration enabled: <b>you're all good!</b> You can manage your connected apps at <a href={slackConfig.manageApp} target="_blank">{slackConfig.manageApp}</a></Typography>
         </Stack>
        )
        :
        (<a href={getUrl()} className="slack-button" type={slackConfig.url}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="20px"
            width="30px"
            className="slack-button-svg"
            viewBox="0 0 122.8 122.8"
          >
              <path
                d="M25.8 77.6c0 7.1-5.8 12.9-12.9 12.9S0 84.7 0 77.6s5.8-12.9 12.9-12.9h12.9v12.9zm6.5 0c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9v32.3c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V77.6z"
                fill="#e01e5a"
              ></path>
              <path
                d="M45.2 25.8c-7.1 0-12.9-5.8-12.9-12.9S38.1 0 45.2 0s12.9 5.8 12.9 12.9v12.9H45.2zm0 6.5c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H12.9C5.8 58.1 0 52.3 0 45.2s5.8-12.9 12.9-12.9h32.3z"
                fill="#36c5f0"
              ></path>
              <path
                d="M97 45.2c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9-5.8 12.9-12.9 12.9H97V45.2zm-6.5 0c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V12.9C64.7 5.8 70.5 0 77.6 0s12.9 5.8 12.9 12.9v32.3z"
                fill="#2eb67d"
              ></path>
              <path
                d="M77.6 97c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9-12.9-5.8-12.9-12.9V97h12.9zm0-6.5c-7.1 0-12.9-5.8-12.9-12.9s5.8-12.9 12.9-12.9h32.3c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H77.6z"
                fill="#ecb22e"
              ></path>
            </svg>
            Enable Branco
          </a>        
        )
        }
    </Box>
  );
};
