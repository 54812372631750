import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import RocketLaunchOutlinedIcon from '@mui/icons-material/RocketLaunchOutlined';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';
import ArchitectureOutlinedIcon from '@mui/icons-material/ArchitectureOutlined';
import HourglassTopOutlinedIcon from '@mui/icons-material/HourglassTopOutlined';

const items = [
  {
    icon: <LockOutlinedIcon />,
    title: 'We Share Knowledge, Not Your Data',
    description:
      'Your data is yours, period. Our AI agents keep it private and never use it to train external models or share it with others.',
  },
  {
    icon: <SupportAgentRoundedIcon />,
    title: 'Reliable Support, Every Step of the Way',
    description:
      'We’re here for you from your first setup to mastery. Our templates get you up and running in hours.',
  },
  {
    icon: <ArchitectureOutlinedIcon />,
    title: 'We Make the Complex Simple',
    description:
      'We integrate seamlessly with the tools you already use. Use Slack or Teams, we play nice with others.',
  },
  {
    icon: <HourglassTopOutlinedIcon />,
    title: 'Save employees and leaders weeks',
    description:
    'We’re obsessed with efficiency. Want best in class feedback and coaching while saving hundreds of hours? Yes, it’s possible.',
  },
  {
    icon: <RocketLaunchOutlinedIcon />,
    title: 'Boost professional growth',
    description:
      'We exist to empower your teams. Timely, continuous feedback and tools for employees to take charge of their future.',
  },
  {
    icon: <FavoriteBorderIcon />,
    title: 'Built for Leaders, by Leaders',
    description:
      'We’ve poured over 50 years of leadership experience combined into this product, continually refining it to help you and your team thrive.',
  },
];

export default function Highlights() {
  return (
    <Box
      id="highlights"
      sx={{
        //py: { xs: 12, lg: 16 },
        pt: { xs: 12, sm: 12 },
        pb: { xs: 8, sm: 10 },
        color: 'white',
        bgcolor: 'grey.900',
      }}
    >
      <Container
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: '100%', md: '60%' },
            textAlign: { sm: 'left', md: 'center' },
          }}
        >
          <Typography component="h2" variant="h4" gutterBottom>
            Why Branco?
          </Typography>
          <Typography variant="body1" sx={{ color: 'grey.400' }}>
            Here's how we're different.<br />
          </Typography>
        </Box>
        <Grid container spacing={2}>
          {items.map((item, index) => (
            <Grid size={{ xs: 12, sm: 6, md: 4 }} key={index}>
              <Stack
                direction="column"
                component={Card}
                spacing={1}
                useFlexGap
                sx={{
                  color: 'inherit',
                  p: 3,
                  height: '100%',
                  borderColor: 'hsla(220, 25%, 25%, 0.3)',
                  backgroundColor: 'grey.800',
                }}
              >
                <Box sx={{ opacity: '50%' }}>{item.icon}</Box>
                <div>
                  <Typography gutterBottom sx={{ fontWeight: 'medium' }}>
                    {item.title}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'grey.400' }}>
                    {item.description}
                  </Typography>
                </div>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
