import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export function BrancoIcon() {
  return (
    <SvgIcon>
      <svg width="335" height="267" viewBox="0 0 335 267" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M48.4748 84.1761C92.0685 12.707 185.98 -15.7096 264.002 8.87675C342.025 33.4631 346.194 87.7459 321.658 165.606C314.181 189.336 307.903 209.258 298.555 224.972C277.23 260.817 239.928 274.767 135.961 262.066C-33.9986 241.305 -20.6159 197.446 48.4748 84.1761Z" fill="#FA9500"/>
      <path d="M264.048 54.2525C263.589 51.3843 248.896 51.8651 238.932 51.6551C226.365 51.3902 215.868 40.1107 202.391 39.6831C143.61 37.818 105.149 73.6908 99.3778 110.655C83.671 125.604 70.4671 145.451 65.773 158.638C82.5582 150.654 97.4618 153.775 118.093 160.526C135.745 178.482 166.339 194.83 213.285 206.271C215.691 218.15 224.465 232.012 236.114 233.934C237.417 231.047 235.894 226.899 236.661 221.614C237.428 216.329 246.967 200.976 252.283 186.964C255.507 183.079 262.521 181.107 258.556 177.865C249.75 177.961 221.187 186.892 216.695 195.562C177.784 174.121 164.879 153.251 165.123 134.971C168.634 141.989 173.554 149.655 181.186 149.571C188.818 149.488 186.405 111.341 176.994 105.287C190.629 88.0049 212.037 76.1625 219.197 73.2159C235.933 66.328 247.191 63.8809 257.849 61.7089C268.507 59.537 264.507 57.1208 264.048 54.2525Z" fill="#2C2738"/>
      <path d="M99.3778 110.655C105.149 73.6908 143.61 37.818 202.391 39.6831C215.868 40.1107 226.365 51.3902 238.932 51.6551C248.896 51.8651 263.589 51.3843 264.048 54.2525C264.507 57.1208 268.507 59.537 257.849 61.7089C247.191 63.8809 235.933 66.328 219.197 73.2159C212.037 76.1625 190.629 88.0049 176.994 105.287M99.3778 110.655C83.671 125.604 70.4671 145.451 65.773 158.638C82.5582 150.654 97.4618 153.775 118.093 160.526M99.3778 110.655C96.8283 126.985 102.244 144.404 118.093 160.526M118.093 160.526C135.745 178.482 166.339 194.83 213.285 206.271C215.691 218.15 224.465 232.012 236.114 233.934C237.417 231.047 235.894 226.899 236.661 221.614C237.428 216.329 246.967 200.976 252.283 186.964C255.507 183.079 262.521 181.107 258.556 177.865C249.75 177.961 221.187 186.892 216.695 195.562C177.784 174.121 164.879 153.251 165.123 134.971M176.994 105.287C186.405 111.341 188.818 149.488 181.186 149.571C173.554 149.655 168.634 141.989 165.123 134.971M176.994 105.287C170.153 113.958 165.269 123.998 165.123 134.971" stroke="#2C2738" strokeWidth="2"/>
      </svg>
    </SvgIcon>
  );
}

export function BrancoLogo() {
  return (
    <SvgIcon sx={{ height: 40, width: 135 }}>
      <svg width="723" height="267" viewBox="0 0 723 267" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M436.475 84.1759C480.069 12.7068 573.98 -15.7098 652.002 8.87657C730.025 33.4629 734.194 87.7458 709.658 165.606C702.181 189.336 695.903 209.258 686.555 224.972C665.23 260.817 627.928 274.766 523.961 262.066C354.001 241.304 367.384 197.446 436.475 84.1759Z" fill="#FA9500"/>
      <mask id="path-2-outside-1_0_1" maskUnits="userSpaceOnUse" x="0" y="55.5771" width="699" height="137" fill="black">
      <rect fill="white" y="55.5771" width="699" height="137"/>
      <path d="M72.9461 119.769C77.6883 121.142 81.869 123.203 85.488 125.95C89.2319 128.698 92.1646 132.257 94.2861 136.628C96.5324 140.874 97.6556 145.869 97.6556 151.614C97.6556 158.982 95.7836 165.413 92.0398 170.908C88.4207 176.403 83.2417 180.711 76.5028 183.833C69.7638 186.831 61.7769 188.329 52.5421 188.329H2V57.5771H50.6701C64.3976 57.5771 75.13 60.5743 82.8673 66.5687C90.6046 72.5631 94.4733 80.4307 94.4733 90.1715C94.4733 94.7922 93.5997 99.1006 91.8526 103.097C90.2302 107.093 87.7967 110.527 84.5521 113.4C81.3074 116.272 77.4387 118.395 72.9461 119.769ZM49.7342 74.4363H21.8424V113.025H49.7342C57.0971 113.025 62.9001 111.214 67.1431 107.593C71.511 103.971 73.6949 99.1631 73.6949 93.1687C73.6949 87.2992 71.511 82.741 67.1431 79.494C62.7753 76.1222 56.9723 74.4363 49.7342 74.4363ZM21.8424 171.47H52.1677C59.7802 171.47 65.7704 169.597 70.1382 165.85C74.6308 162.104 76.8772 157.046 76.8772 150.677C76.8772 144.058 74.506 138.938 69.7638 135.317C65.1464 131.695 58.657 129.884 50.2958 129.884H21.8424V171.47Z"/>
      <path d="M196.941 129.51V117.146C196.941 111.776 195.319 107.468 192.074 104.221C188.829 100.849 184.149 99.1631 178.034 99.1631C170.297 99.1631 163.371 102.098 157.256 107.967C151.141 113.712 146.274 122.516 142.655 134.38L140.034 115.273C142.78 108.155 146.087 102.223 149.955 97.4771C153.949 92.7316 158.504 89.1725 163.621 86.7997C168.862 84.302 174.603 83.0532 180.842 83.0532C191.2 83.0532 199.624 86.0504 206.113 92.0448C212.603 97.9142 215.847 106.281 215.847 117.146V129.51H196.941ZM127.305 188.329V85.3011H145.088L146.212 108.717V188.329H127.305Z"/>
      <path d="M317.566 188.329L316.817 164.914V120.893C316.817 113.525 314.384 107.78 309.517 103.659C304.775 99.4128 298.223 97.2898 289.862 97.2898C281.75 97.2898 275.136 99.1631 270.019 102.91C264.903 106.531 262.095 111.589 261.596 118.083H243.063C243.687 110.715 246.059 104.471 250.177 99.3504C254.295 94.1053 259.786 90.1091 266.65 87.3617C273.514 84.4894 281.438 83.0532 290.423 83.0532C299.658 83.0532 307.645 84.6142 314.384 87.7363C321.123 90.7335 326.302 95.1044 329.921 100.849C333.665 106.594 335.537 113.587 335.537 121.829V188.329H317.566ZM280.315 190.577C273.576 190.577 267.648 189.391 262.532 187.018C257.415 184.52 253.421 181.024 250.551 176.528C247.681 171.907 246.246 166.537 246.246 160.418C246.246 149.803 250.302 141.561 258.413 135.691C266.65 129.822 278.131 126.887 292.857 126.887H319.251V140.936H292.108C283.622 140.936 276.945 142.56 272.078 145.807C267.336 149.054 264.965 153.612 264.965 159.481C264.965 164.352 266.65 168.161 270.019 170.908C273.514 173.656 278.193 175.029 284.059 175.029C291.546 175.029 298.161 173.031 303.901 169.035C309.767 164.914 314.509 159.294 318.128 152.176L320.187 166.038C316.942 173.905 311.95 179.962 305.211 184.208C298.473 188.454 290.174 190.577 280.315 190.577Z"/>
      <path d="M440.063 188.329V123.328C440.063 116.085 438.004 110.278 433.886 105.907C429.768 101.411 424.402 99.1631 417.788 99.1631C410.175 99.1631 403.374 101.973 397.384 107.593C391.518 113.087 386.838 121.704 383.344 133.443L380.349 112.276C384.218 102.535 389.771 95.2293 397.009 90.3588C404.372 85.4884 412.359 83.0532 420.97 83.0532C428.707 83.0532 435.384 84.7391 440.999 88.1109C446.74 91.3579 451.17 95.9161 454.29 101.786C457.41 107.655 458.97 114.586 458.97 122.579V188.329H440.063ZM366.684 188.329V85.3011H384.654L385.59 108.529V188.329H366.684Z"/>
      <path d="M645.522 190.577C634.914 190.577 625.742 188.392 618.005 184.021C610.392 179.65 604.527 173.468 600.408 165.476C596.29 157.358 594.231 147.805 594.231 136.815C594.231 125.576 596.29 116.022 600.408 108.155C604.527 100.162 610.392 93.9804 618.005 89.6095C625.742 85.2386 634.977 83.0532 645.709 83.0532C661.683 83.0532 674.225 87.8612 683.335 97.4771C692.445 106.968 697 120.081 697 136.815C697 147.805 694.941 157.358 690.823 165.476C686.704 173.468 680.777 179.65 673.039 184.021C665.427 188.392 656.254 190.577 645.522 190.577ZM645.709 174.842C655.693 174.842 663.555 171.47 669.295 164.726C675.161 157.858 678.094 148.554 678.094 136.815C678.094 124.951 675.161 115.648 669.295 108.904C663.555 102.16 655.693 98.7884 645.709 98.7884C635.601 98.7884 627.676 102.16 621.936 108.904C616.195 115.648 613.325 124.951 613.325 136.815C613.325 148.554 616.195 157.858 621.936 164.726C627.676 171.47 635.601 174.842 645.709 174.842Z"/>
      </mask>
      <path d="M72.9461 119.769C77.6883 121.142 81.869 123.203 85.488 125.95C89.2319 128.698 92.1646 132.257 94.2861 136.628C96.5324 140.874 97.6556 145.869 97.6556 151.614C97.6556 158.982 95.7836 165.413 92.0398 170.908C88.4207 176.403 83.2417 180.711 76.5028 183.833C69.7638 186.831 61.7769 188.329 52.5421 188.329H2V57.5771H50.6701C64.3976 57.5771 75.13 60.5743 82.8673 66.5687C90.6046 72.5631 94.4733 80.4307 94.4733 90.1715C94.4733 94.7922 93.5997 99.1006 91.8526 103.097C90.2302 107.093 87.7967 110.527 84.5521 113.4C81.3074 116.272 77.4387 118.395 72.9461 119.769ZM49.7342 74.4363H21.8424V113.025H49.7342C57.0971 113.025 62.9001 111.214 67.1431 107.593C71.511 103.971 73.6949 99.1631 73.6949 93.1687C73.6949 87.2992 71.511 82.741 67.1431 79.494C62.7753 76.1222 56.9723 74.4363 49.7342 74.4363ZM21.8424 171.47H52.1677C59.7802 171.47 65.7704 169.597 70.1382 165.85C74.6308 162.104 76.8772 157.046 76.8772 150.677C76.8772 144.058 74.506 138.938 69.7638 135.317C65.1464 131.695 58.657 129.884 50.2958 129.884H21.8424V171.47Z" fill="#2C2738"/>
      <path d="M196.941 129.51V117.146C196.941 111.776 195.319 107.468 192.074 104.221C188.829 100.849 184.149 99.1631 178.034 99.1631C170.297 99.1631 163.371 102.098 157.256 107.967C151.141 113.712 146.274 122.516 142.655 134.38L140.034 115.273C142.78 108.155 146.087 102.223 149.955 97.4771C153.949 92.7316 158.504 89.1725 163.621 86.7997C168.862 84.302 174.603 83.0532 180.842 83.0532C191.2 83.0532 199.624 86.0504 206.113 92.0448C212.603 97.9142 215.847 106.281 215.847 117.146V129.51H196.941ZM127.305 188.329V85.3011H145.088L146.212 108.717V188.329H127.305Z" fill="#2C2738"/>
      <path d="M317.566 188.329L316.817 164.914V120.893C316.817 113.525 314.384 107.78 309.517 103.659C304.775 99.4128 298.223 97.2898 289.862 97.2898C281.75 97.2898 275.136 99.1631 270.019 102.91C264.903 106.531 262.095 111.589 261.596 118.083H243.063C243.687 110.715 246.059 104.471 250.177 99.3504C254.295 94.1053 259.786 90.1091 266.65 87.3617C273.514 84.4894 281.438 83.0532 290.423 83.0532C299.658 83.0532 307.645 84.6142 314.384 87.7363C321.123 90.7335 326.302 95.1044 329.921 100.849C333.665 106.594 335.537 113.587 335.537 121.829V188.329H317.566ZM280.315 190.577C273.576 190.577 267.648 189.391 262.532 187.018C257.415 184.52 253.421 181.024 250.551 176.528C247.681 171.907 246.246 166.537 246.246 160.418C246.246 149.803 250.302 141.561 258.413 135.691C266.65 129.822 278.131 126.887 292.857 126.887H319.251V140.936H292.108C283.622 140.936 276.945 142.56 272.078 145.807C267.336 149.054 264.965 153.612 264.965 159.481C264.965 164.352 266.65 168.161 270.019 170.908C273.514 173.656 278.193 175.029 284.059 175.029C291.546 175.029 298.161 173.031 303.901 169.035C309.767 164.914 314.509 159.294 318.128 152.176L320.187 166.038C316.942 173.905 311.95 179.962 305.211 184.208C298.473 188.454 290.174 190.577 280.315 190.577Z" fill="#2C2738"/>
      <path d="M440.063 188.329V123.328C440.063 116.085 438.004 110.278 433.886 105.907C429.768 101.411 424.402 99.1631 417.788 99.1631C410.175 99.1631 403.374 101.973 397.384 107.593C391.518 113.087 386.838 121.704 383.344 133.443L380.349 112.276C384.218 102.535 389.771 95.2293 397.009 90.3588C404.372 85.4884 412.359 83.0532 420.97 83.0532C428.707 83.0532 435.384 84.7391 440.999 88.1109C446.74 91.3579 451.17 95.9161 454.29 101.786C457.41 107.655 458.97 114.586 458.97 122.579V188.329H440.063ZM366.684 188.329V85.3011H384.654L385.59 108.529V188.329H366.684Z" fill="#2C2738"/>
      <path d="M645.522 190.577C634.914 190.577 625.742 188.392 618.005 184.021C610.392 179.65 604.527 173.468 600.408 165.476C596.29 157.358 594.231 147.805 594.231 136.815C594.231 125.576 596.29 116.022 600.408 108.155C604.527 100.162 610.392 93.9804 618.005 89.6095C625.742 85.2386 634.977 83.0532 645.709 83.0532C661.683 83.0532 674.225 87.8612 683.335 97.4771C692.445 106.968 697 120.081 697 136.815C697 147.805 694.941 157.358 690.823 165.476C686.704 173.468 680.777 179.65 673.039 184.021C665.427 188.392 656.254 190.577 645.522 190.577ZM645.709 174.842C655.693 174.842 663.555 171.47 669.295 164.726C675.161 157.858 678.094 148.554 678.094 136.815C678.094 124.951 675.161 115.648 669.295 108.904C663.555 102.16 655.693 98.7884 645.709 98.7884C635.601 98.7884 627.676 102.16 621.936 108.904C616.195 115.648 613.325 124.951 613.325 136.815C613.325 148.554 616.195 157.858 621.936 164.726C627.676 171.47 635.601 174.842 645.709 174.842Z" fill="#2C2738"/>
      <path d="M72.9461 119.769C77.6883 121.142 81.869 123.203 85.488 125.95C89.2319 128.698 92.1646 132.257 94.2861 136.628C96.5324 140.874 97.6556 145.869 97.6556 151.614C97.6556 158.982 95.7836 165.413 92.0398 170.908C88.4207 176.403 83.2417 180.711 76.5028 183.833C69.7638 186.831 61.7769 188.329 52.5421 188.329H2V57.5771H50.6701C64.3976 57.5771 75.13 60.5743 82.8673 66.5687C90.6046 72.5631 94.4733 80.4307 94.4733 90.1715C94.4733 94.7922 93.5997 99.1006 91.8526 103.097C90.2302 107.093 87.7967 110.527 84.5521 113.4C81.3074 116.272 77.4387 118.395 72.9461 119.769ZM49.7342 74.4363H21.8424V113.025H49.7342C57.0971 113.025 62.9001 111.214 67.1431 107.593C71.511 103.971 73.6949 99.1631 73.6949 93.1687C73.6949 87.2992 71.511 82.741 67.1431 79.494C62.7753 76.1222 56.9723 74.4363 49.7342 74.4363ZM21.8424 171.47H52.1677C59.7802 171.47 65.7704 169.597 70.1382 165.85C74.6308 162.104 76.8772 157.046 76.8772 150.677C76.8772 144.058 74.506 138.938 69.7638 135.317C65.1464 131.695 58.657 129.884 50.2958 129.884H21.8424V171.47Z" stroke="#FA9500" strokeWidth="4" mask="url(#path-2-outside-1_0_1)"/>
      <path d="M196.941 129.51V117.146C196.941 111.776 195.319 107.468 192.074 104.221C188.829 100.849 184.149 99.1631 178.034 99.1631C170.297 99.1631 163.371 102.098 157.256 107.967C151.141 113.712 146.274 122.516 142.655 134.38L140.034 115.273C142.78 108.155 146.087 102.223 149.955 97.4771C153.949 92.7316 158.504 89.1725 163.621 86.7997C168.862 84.302 174.603 83.0532 180.842 83.0532C191.2 83.0532 199.624 86.0504 206.113 92.0448C212.603 97.9142 215.847 106.281 215.847 117.146V129.51H196.941ZM127.305 188.329V85.3011H145.088L146.212 108.717V188.329H127.305Z" stroke="#FA9500" strokeWidth="4" mask="url(#path-2-outside-1_0_1)"/>
      <path d="M317.566 188.329L316.817 164.914V120.893C316.817 113.525 314.384 107.78 309.517 103.659C304.775 99.4128 298.223 97.2898 289.862 97.2898C281.75 97.2898 275.136 99.1631 270.019 102.91C264.903 106.531 262.095 111.589 261.596 118.083H243.063C243.687 110.715 246.059 104.471 250.177 99.3504C254.295 94.1053 259.786 90.1091 266.65 87.3617C273.514 84.4894 281.438 83.0532 290.423 83.0532C299.658 83.0532 307.645 84.6142 314.384 87.7363C321.123 90.7335 326.302 95.1044 329.921 100.849C333.665 106.594 335.537 113.587 335.537 121.829V188.329H317.566ZM280.315 190.577C273.576 190.577 267.648 189.391 262.532 187.018C257.415 184.52 253.421 181.024 250.551 176.528C247.681 171.907 246.246 166.537 246.246 160.418C246.246 149.803 250.302 141.561 258.413 135.691C266.65 129.822 278.131 126.887 292.857 126.887H319.251V140.936H292.108C283.622 140.936 276.945 142.56 272.078 145.807C267.336 149.054 264.965 153.612 264.965 159.481C264.965 164.352 266.65 168.161 270.019 170.908C273.514 173.656 278.193 175.029 284.059 175.029C291.546 175.029 298.161 173.031 303.901 169.035C309.767 164.914 314.509 159.294 318.128 152.176L320.187 166.038C316.942 173.905 311.95 179.962 305.211 184.208C298.473 188.454 290.174 190.577 280.315 190.577Z" stroke="#FA9500" strokeWidth="4" mask="url(#path-2-outside-1_0_1)"/>
      <path d="M440.063 188.329V123.328C440.063 116.085 438.004 110.278 433.886 105.907C429.768 101.411 424.402 99.1631 417.788 99.1631C410.175 99.1631 403.374 101.973 397.384 107.593C391.518 113.087 386.838 121.704 383.344 133.443L380.349 112.276C384.218 102.535 389.771 95.2293 397.009 90.3588C404.372 85.4884 412.359 83.0532 420.97 83.0532C428.707 83.0532 435.384 84.7391 440.999 88.1109C446.74 91.3579 451.17 95.9161 454.29 101.786C457.41 107.655 458.97 114.586 458.97 122.579V188.329H440.063ZM366.684 188.329V85.3011H384.654L385.59 108.529V188.329H366.684Z" stroke="#FA9500" strokeWidth="4" mask="url(#path-2-outside-1_0_1)"/>
      <path d="M645.522 190.577C634.914 190.577 625.742 188.392 618.005 184.021C610.392 179.65 604.527 173.468 600.408 165.476C596.29 157.358 594.231 147.805 594.231 136.815C594.231 125.576 596.29 116.022 600.408 108.155C604.527 100.162 610.392 93.9804 618.005 89.6095C625.742 85.2386 634.977 83.0532 645.709 83.0532C661.683 83.0532 674.225 87.8612 683.335 97.4771C692.445 106.968 697 120.081 697 136.815C697 147.805 694.941 157.358 690.823 165.476C686.704 173.468 680.777 179.65 673.039 184.021C665.427 188.392 656.254 190.577 645.522 190.577ZM645.709 174.842C655.693 174.842 663.555 171.47 669.295 164.726C675.161 157.858 678.094 148.554 678.094 136.815C678.094 124.951 675.161 115.648 669.295 108.904C663.555 102.16 655.693 98.7884 645.709 98.7884C635.601 98.7884 627.676 102.16 621.936 108.904C616.195 115.648 613.325 124.951 613.325 136.815C613.325 148.554 616.195 157.858 621.936 164.726C627.676 171.47 635.601 174.842 645.709 174.842Z" stroke="#FA9500" strokeWidth="4" mask="url(#path-2-outside-1_0_1)"/>
      <path d="M594.324 85.2119C594.029 83.3612 584.565 83.6712 578.148 83.5356C570.055 83.3645 563.294 76.0863 554.615 75.8102C516.756 74.606 491.983 97.7522 488.264 121.603C478.148 131.249 469.642 144.055 466.619 152.563C477.43 147.412 487.028 149.426 500.315 153.782C511.683 165.369 531.387 175.917 561.622 183.3C563.171 190.965 568.821 199.91 576.323 201.15C577.163 199.287 576.182 196.611 576.677 193.201C577.171 189.791 583.315 179.884 586.74 170.843C588.817 168.336 593.334 167.064 590.78 164.972C585.109 165.034 566.712 170.797 563.819 176.39C538.759 162.555 530.449 149.089 530.607 137.294C532.868 141.822 536.036 146.769 540.951 146.715C545.867 146.661 544.315 122.047 538.254 118.141C547.036 106.989 560.825 99.3485 565.437 97.4473C576.216 93.0032 583.467 91.4243 590.331 90.023C597.196 88.6217 594.62 87.0626 594.324 85.2119Z" fill="#2C2738"/>
      <path d="M488.264 121.603C491.983 97.7522 516.756 74.606 554.615 75.8102C563.294 76.0863 570.055 83.3645 578.148 83.5356C584.565 83.6712 594.029 83.3612 594.324 85.2119C594.62 87.0626 597.196 88.6217 590.331 90.023C583.467 91.4243 576.216 93.0032 565.437 97.4473C560.825 99.3485 547.036 106.989 538.254 118.141M488.264 121.603C478.148 131.249 469.642 144.055 466.619 152.563C477.43 147.412 487.028 149.426 500.315 153.782M488.264 121.603C486.622 132.14 490.109 143.38 500.315 153.782M500.315 153.782C511.683 165.369 531.387 175.917 561.622 183.3C563.171 190.965 568.821 199.91 576.323 201.15C577.163 199.287 576.182 196.611 576.677 193.201C577.171 189.791 583.315 179.884 586.74 170.843C588.817 168.336 593.334 167.064 590.78 164.972C585.109 165.034 566.712 170.797 563.819 176.39C538.759 162.555 530.449 149.089 530.607 137.294M538.254 118.141C544.315 122.047 545.867 146.661 540.951 146.715C536.036 146.769 532.868 141.822 530.607 137.294M538.254 118.141C533.848 123.735 530.701 130.214 530.607 137.294" stroke="#2C2738" strokeWidth="2"/>
      <rect x="369" y="57" width="14" height="14" fill="#2C2738" stroke="#FA9500" strokeWidth="2"/>
      </svg>
    </SvgIcon>
  );
}

export function BrancoLogoWhite() {
  return (
    <SvgIcon sx={{ height: 40, width: 135 }}>
      <svg width="723" height="267" viewBox="0 0 723 267" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M436.475 84.1759C480.069 12.7068 573.98 -15.7098 652.002 8.87657C730.025 33.4629 734.194 87.7458 709.658 165.606C702.181 189.336 695.903 209.258 686.555 224.972C665.23 260.817 627.928 274.766 523.961 262.066C354.001 241.304 367.384 197.446 436.475 84.1759Z" fill="#FA9500"/>
        <mask id="path-2-outside-1_0_1" maskUnits="userSpaceOnUse" x="0" y="55.5771" width="699" height="137" fill="black">
        <rect fill="white" y="55.5771" width="699" height="137"/>
        <path d="M72.9461 119.769C77.6883 121.142 81.869 123.203 85.488 125.95C89.2319 128.698 92.1646 132.257 94.2861 136.628C96.5324 140.874 97.6556 145.869 97.6556 151.614C97.6556 158.982 95.7836 165.413 92.0398 170.908C88.4207 176.403 83.2417 180.711 76.5028 183.833C69.7638 186.831 61.7769 188.329 52.5421 188.329H2V57.5771H50.6701C64.3976 57.5771 75.13 60.5743 82.8673 66.5687C90.6046 72.5631 94.4733 80.4307 94.4733 90.1715C94.4733 94.7922 93.5997 99.1006 91.8526 103.097C90.2302 107.093 87.7967 110.527 84.5521 113.4C81.3074 116.272 77.4387 118.395 72.9461 119.769ZM49.7342 74.4363H21.8424V113.025H49.7342C57.0971 113.025 62.9001 111.214 67.1431 107.593C71.511 103.971 73.6949 99.1631 73.6949 93.1687C73.6949 87.2992 71.511 82.741 67.1431 79.494C62.7753 76.1222 56.9723 74.4363 49.7342 74.4363ZM21.8424 171.47H52.1677C59.7802 171.47 65.7704 169.597 70.1382 165.85C74.6308 162.104 76.8772 157.046 76.8772 150.677C76.8772 144.058 74.506 138.938 69.7638 135.317C65.1464 131.695 58.657 129.884 50.2958 129.884H21.8424V171.47Z"/>
        <path d="M196.941 129.51V117.146C196.941 111.776 195.319 107.468 192.074 104.221C188.829 100.849 184.149 99.1631 178.034 99.1631C170.297 99.1631 163.371 102.098 157.256 107.967C151.141 113.712 146.274 122.516 142.655 134.38L140.034 115.273C142.78 108.155 146.087 102.223 149.955 97.4771C153.949 92.7316 158.504 89.1725 163.621 86.7997C168.862 84.302 174.603 83.0532 180.842 83.0532C191.2 83.0532 199.624 86.0504 206.113 92.0448C212.603 97.9142 215.847 106.281 215.847 117.146V129.51H196.941ZM127.305 188.329V85.3011H145.088L146.212 108.717V188.329H127.305Z"/>
        <path d="M317.566 188.329L316.817 164.914V120.893C316.817 113.525 314.384 107.78 309.517 103.659C304.775 99.4128 298.223 97.2898 289.862 97.2898C281.75 97.2898 275.136 99.1631 270.019 102.91C264.903 106.531 262.095 111.589 261.596 118.083H243.063C243.687 110.715 246.059 104.471 250.177 99.3504C254.295 94.1053 259.786 90.1091 266.65 87.3617C273.514 84.4894 281.438 83.0532 290.423 83.0532C299.658 83.0532 307.645 84.6142 314.384 87.7363C321.123 90.7335 326.302 95.1044 329.921 100.849C333.665 106.594 335.537 113.587 335.537 121.829V188.329H317.566ZM280.315 190.577C273.576 190.577 267.648 189.391 262.532 187.018C257.415 184.52 253.421 181.024 250.551 176.528C247.681 171.907 246.246 166.537 246.246 160.418C246.246 149.803 250.302 141.561 258.413 135.691C266.65 129.822 278.131 126.887 292.857 126.887H319.251V140.936H292.108C283.622 140.936 276.945 142.56 272.078 145.807C267.336 149.054 264.965 153.612 264.965 159.481C264.965 164.352 266.65 168.161 270.019 170.908C273.514 173.656 278.193 175.029 284.059 175.029C291.546 175.029 298.161 173.031 303.901 169.035C309.767 164.914 314.509 159.294 318.128 152.176L320.187 166.038C316.942 173.905 311.95 179.962 305.211 184.208C298.473 188.454 290.174 190.577 280.315 190.577Z"/>
        <path d="M440.063 188.329V123.328C440.063 116.085 438.004 110.278 433.886 105.907C429.768 101.411 424.402 99.1631 417.788 99.1631C410.175 99.1631 403.374 101.973 397.384 107.593C391.518 113.087 386.838 121.704 383.344 133.443L380.349 112.276C384.218 102.535 389.771 95.2293 397.009 90.3588C404.372 85.4884 412.359 83.0532 420.97 83.0532C428.707 83.0532 435.384 84.7391 440.999 88.1109C446.74 91.3579 451.17 95.9161 454.29 101.786C457.41 107.655 458.97 114.586 458.97 122.579V188.329H440.063ZM366.684 188.329V85.3011H384.654L385.59 108.529V188.329H366.684Z"/>
        <path d="M645.522 190.577C634.914 190.577 625.742 188.392 618.005 184.021C610.392 179.65 604.527 173.468 600.408 165.476C596.29 157.358 594.231 147.805 594.231 136.815C594.231 125.576 596.29 116.022 600.408 108.155C604.527 100.162 610.392 93.9804 618.005 89.6095C625.742 85.2386 634.977 83.0532 645.709 83.0532C661.683 83.0532 674.225 87.8612 683.335 97.4771C692.445 106.968 697 120.081 697 136.815C697 147.805 694.941 157.358 690.823 165.476C686.704 173.468 680.777 179.65 673.039 184.021C665.427 188.392 656.254 190.577 645.522 190.577ZM645.709 174.842C655.693 174.842 663.555 171.47 669.295 164.726C675.161 157.858 678.094 148.554 678.094 136.815C678.094 124.951 675.161 115.648 669.295 108.904C663.555 102.16 655.693 98.7884 645.709 98.7884C635.601 98.7884 627.676 102.16 621.936 108.904C616.195 115.648 613.325 124.951 613.325 136.815C613.325 148.554 616.195 157.858 621.936 164.726C627.676 171.47 635.601 174.842 645.709 174.842Z"/>
        </mask>
        <path d="M72.9461 119.769C77.6883 121.142 81.869 123.203 85.488 125.95C89.2319 128.698 92.1646 132.257 94.2861 136.628C96.5324 140.874 97.6556 145.869 97.6556 151.614C97.6556 158.982 95.7836 165.413 92.0398 170.908C88.4207 176.403 83.2417 180.711 76.5028 183.833C69.7638 186.831 61.7769 188.329 52.5421 188.329H2V57.5771H50.6701C64.3976 57.5771 75.13 60.5743 82.8673 66.5687C90.6046 72.5631 94.4733 80.4307 94.4733 90.1715C94.4733 94.7922 93.5997 99.1006 91.8526 103.097C90.2302 107.093 87.7967 110.527 84.5521 113.4C81.3074 116.272 77.4387 118.395 72.9461 119.769ZM49.7342 74.4363H21.8424V113.025H49.7342C57.0971 113.025 62.9001 111.214 67.1431 107.593C71.511 103.971 73.6949 99.1631 73.6949 93.1687C73.6949 87.2992 71.511 82.741 67.1431 79.494C62.7753 76.1222 56.9723 74.4363 49.7342 74.4363ZM21.8424 171.47H52.1677C59.7802 171.47 65.7704 169.597 70.1382 165.85C74.6308 162.104 76.8772 157.046 76.8772 150.677C76.8772 144.058 74.506 138.938 69.7638 135.317C65.1464 131.695 58.657 129.884 50.2958 129.884H21.8424V171.47Z" fill="#fff"/>
        <path d="M196.941 129.51V117.146C196.941 111.776 195.319 107.468 192.074 104.221C188.829 100.849 184.149 99.1631 178.034 99.1631C170.297 99.1631 163.371 102.098 157.256 107.967C151.141 113.712 146.274 122.516 142.655 134.38L140.034 115.273C142.78 108.155 146.087 102.223 149.955 97.4771C153.949 92.7316 158.504 89.1725 163.621 86.7997C168.862 84.302 174.603 83.0532 180.842 83.0532C191.2 83.0532 199.624 86.0504 206.113 92.0448C212.603 97.9142 215.847 106.281 215.847 117.146V129.51H196.941ZM127.305 188.329V85.3011H145.088L146.212 108.717V188.329H127.305Z" fill="#fff"/>
        <path d="M317.566 188.329L316.817 164.914V120.893C316.817 113.525 314.384 107.78 309.517 103.659C304.775 99.4128 298.223 97.2898 289.862 97.2898C281.75 97.2898 275.136 99.1631 270.019 102.91C264.903 106.531 262.095 111.589 261.596 118.083H243.063C243.687 110.715 246.059 104.471 250.177 99.3504C254.295 94.1053 259.786 90.1091 266.65 87.3617C273.514 84.4894 281.438 83.0532 290.423 83.0532C299.658 83.0532 307.645 84.6142 314.384 87.7363C321.123 90.7335 326.302 95.1044 329.921 100.849C333.665 106.594 335.537 113.587 335.537 121.829V188.329H317.566ZM280.315 190.577C273.576 190.577 267.648 189.391 262.532 187.018C257.415 184.52 253.421 181.024 250.551 176.528C247.681 171.907 246.246 166.537 246.246 160.418C246.246 149.803 250.302 141.561 258.413 135.691C266.65 129.822 278.131 126.887 292.857 126.887H319.251V140.936H292.108C283.622 140.936 276.945 142.56 272.078 145.807C267.336 149.054 264.965 153.612 264.965 159.481C264.965 164.352 266.65 168.161 270.019 170.908C273.514 173.656 278.193 175.029 284.059 175.029C291.546 175.029 298.161 173.031 303.901 169.035C309.767 164.914 314.509 159.294 318.128 152.176L320.187 166.038C316.942 173.905 311.95 179.962 305.211 184.208C298.473 188.454 290.174 190.577 280.315 190.577Z" fill="#fff"/>
        <path d="M440.063 188.329V123.328C440.063 116.085 438.004 110.278 433.886 105.907C429.768 101.411 424.402 99.1631 417.788 99.1631C410.175 99.1631 403.374 101.973 397.384 107.593C391.518 113.087 386.838 121.704 383.344 133.443L380.349 112.276C384.218 102.535 389.771 95.2293 397.009 90.3588C404.372 85.4884 412.359 83.0532 420.97 83.0532C428.707 83.0532 435.384 84.7391 440.999 88.1109C446.74 91.3579 451.17 95.9161 454.29 101.786C457.41 107.655 458.97 114.586 458.97 122.579V188.329H440.063ZM366.684 188.329V85.3011H384.654L385.59 108.529V188.329H366.684Z" fill="#fff"/>
        <path d="M645.522 190.577C634.914 190.577 625.742 188.392 618.005 184.021C610.392 179.65 604.527 173.468 600.408 165.476C596.29 157.358 594.231 147.805 594.231 136.815C594.231 125.576 596.29 116.022 600.408 108.155C604.527 100.162 610.392 93.9804 618.005 89.6095C625.742 85.2386 634.977 83.0532 645.709 83.0532C661.683 83.0532 674.225 87.8612 683.335 97.4771C692.445 106.968 697 120.081 697 136.815C697 147.805 694.941 157.358 690.823 165.476C686.704 173.468 680.777 179.65 673.039 184.021C665.427 188.392 656.254 190.577 645.522 190.577ZM645.709 174.842C655.693 174.842 663.555 171.47 669.295 164.726C675.161 157.858 678.094 148.554 678.094 136.815C678.094 124.951 675.161 115.648 669.295 108.904C663.555 102.16 655.693 98.7884 645.709 98.7884C635.601 98.7884 627.676 102.16 621.936 108.904C616.195 115.648 613.325 124.951 613.325 136.815C613.325 148.554 616.195 157.858 621.936 164.726C627.676 171.47 635.601 174.842 645.709 174.842Z" fill="#fff"/>
        <path d="M72.9461 119.769C77.6883 121.142 81.869 123.203 85.488 125.95C89.2319 128.698 92.1646 132.257 94.2861 136.628C96.5324 140.874 97.6556 145.869 97.6556 151.614C97.6556 158.982 95.7836 165.413 92.0398 170.908C88.4207 176.403 83.2417 180.711 76.5028 183.833C69.7638 186.831 61.7769 188.329 52.5421 188.329H2V57.5771H50.6701C64.3976 57.5771 75.13 60.5743 82.8673 66.5687C90.6046 72.5631 94.4733 80.4307 94.4733 90.1715C94.4733 94.7922 93.5997 99.1006 91.8526 103.097C90.2302 107.093 87.7967 110.527 84.5521 113.4C81.3074 116.272 77.4387 118.395 72.9461 119.769ZM49.7342 74.4363H21.8424V113.025H49.7342C57.0971 113.025 62.9001 111.214 67.1431 107.593C71.511 103.971 73.6949 99.1631 73.6949 93.1687C73.6949 87.2992 71.511 82.741 67.1431 79.494C62.7753 76.1222 56.9723 74.4363 49.7342 74.4363ZM21.8424 171.47H52.1677C59.7802 171.47 65.7704 169.597 70.1382 165.85C74.6308 162.104 76.8772 157.046 76.8772 150.677C76.8772 144.058 74.506 138.938 69.7638 135.317C65.1464 131.695 58.657 129.884 50.2958 129.884H21.8424V171.47Z" stroke="#FA9500" strokeWidth="4" mask="url(#path-2-outside-1_0_1)"/>
        <path d="M196.941 129.51V117.146C196.941 111.776 195.319 107.468 192.074 104.221C188.829 100.849 184.149 99.1631 178.034 99.1631C170.297 99.1631 163.371 102.098 157.256 107.967C151.141 113.712 146.274 122.516 142.655 134.38L140.034 115.273C142.78 108.155 146.087 102.223 149.955 97.4771C153.949 92.7316 158.504 89.1725 163.621 86.7997C168.862 84.302 174.603 83.0532 180.842 83.0532C191.2 83.0532 199.624 86.0504 206.113 92.0448C212.603 97.9142 215.847 106.281 215.847 117.146V129.51H196.941ZM127.305 188.329V85.3011H145.088L146.212 108.717V188.329H127.305Z" stroke="#FA9500" strokeWidth="4" mask="url(#path-2-outside-1_0_1)"/>
        <path d="M317.566 188.329L316.817 164.914V120.893C316.817 113.525 314.384 107.78 309.517 103.659C304.775 99.4128 298.223 97.2898 289.862 97.2898C281.75 97.2898 275.136 99.1631 270.019 102.91C264.903 106.531 262.095 111.589 261.596 118.083H243.063C243.687 110.715 246.059 104.471 250.177 99.3504C254.295 94.1053 259.786 90.1091 266.65 87.3617C273.514 84.4894 281.438 83.0532 290.423 83.0532C299.658 83.0532 307.645 84.6142 314.384 87.7363C321.123 90.7335 326.302 95.1044 329.921 100.849C333.665 106.594 335.537 113.587 335.537 121.829V188.329H317.566ZM280.315 190.577C273.576 190.577 267.648 189.391 262.532 187.018C257.415 184.52 253.421 181.024 250.551 176.528C247.681 171.907 246.246 166.537 246.246 160.418C246.246 149.803 250.302 141.561 258.413 135.691C266.65 129.822 278.131 126.887 292.857 126.887H319.251V140.936H292.108C283.622 140.936 276.945 142.56 272.078 145.807C267.336 149.054 264.965 153.612 264.965 159.481C264.965 164.352 266.65 168.161 270.019 170.908C273.514 173.656 278.193 175.029 284.059 175.029C291.546 175.029 298.161 173.031 303.901 169.035C309.767 164.914 314.509 159.294 318.128 152.176L320.187 166.038C316.942 173.905 311.95 179.962 305.211 184.208C298.473 188.454 290.174 190.577 280.315 190.577Z" stroke="#FA9500" strokeWidth="4" mask="url(#path-2-outside-1_0_1)"/>
        <path d="M440.063 188.329V123.328C440.063 116.085 438.004 110.278 433.886 105.907C429.768 101.411 424.402 99.1631 417.788 99.1631C410.175 99.1631 403.374 101.973 397.384 107.593C391.518 113.087 386.838 121.704 383.344 133.443L380.349 112.276C384.218 102.535 389.771 95.2293 397.009 90.3588C404.372 85.4884 412.359 83.0532 420.97 83.0532C428.707 83.0532 435.384 84.7391 440.999 88.1109C446.74 91.3579 451.17 95.9161 454.29 101.786C457.41 107.655 458.97 114.586 458.97 122.579V188.329H440.063ZM366.684 188.329V85.3011H384.654L385.59 108.529V188.329H366.684Z" stroke="#FA9500" strokeWidth="4" mask="url(#path-2-outside-1_0_1)"/>
        <path d="M645.522 190.577C634.914 190.577 625.742 188.392 618.005 184.021C610.392 179.65 604.527 173.468 600.408 165.476C596.29 157.358 594.231 147.805 594.231 136.815C594.231 125.576 596.29 116.022 600.408 108.155C604.527 100.162 610.392 93.9804 618.005 89.6095C625.742 85.2386 634.977 83.0532 645.709 83.0532C661.683 83.0532 674.225 87.8612 683.335 97.4771C692.445 106.968 697 120.081 697 136.815C697 147.805 694.941 157.358 690.823 165.476C686.704 173.468 680.777 179.65 673.039 184.021C665.427 188.392 656.254 190.577 645.522 190.577ZM645.709 174.842C655.693 174.842 663.555 171.47 669.295 164.726C675.161 157.858 678.094 148.554 678.094 136.815C678.094 124.951 675.161 115.648 669.295 108.904C663.555 102.16 655.693 98.7884 645.709 98.7884C635.601 98.7884 627.676 102.16 621.936 108.904C616.195 115.648 613.325 124.951 613.325 136.815C613.325 148.554 616.195 157.858 621.936 164.726C627.676 171.47 635.601 174.842 645.709 174.842Z" stroke="#FA9500" strokeWidth="4" mask="url(#path-2-outside-1_0_1)"/>
        <path d="M594.324 85.2119C594.029 83.3612 584.565 83.6712 578.148 83.5356C570.055 83.3645 563.294 76.0863 554.615 75.8102C516.756 74.606 491.983 97.7522 488.264 121.603C478.148 131.249 469.642 144.055 466.619 152.563C477.43 147.412 487.028 149.426 500.315 153.782C511.683 165.369 531.387 175.917 561.622 183.3C563.171 190.965 568.821 199.91 576.323 201.15C577.163 199.287 576.182 196.611 576.677 193.201C577.171 189.791 583.315 179.884 586.74 170.843C588.817 168.336 593.334 167.064 590.78 164.972C585.109 165.034 566.712 170.797 563.819 176.39C538.759 162.555 530.449 149.089 530.607 137.294C532.868 141.822 536.036 146.769 540.951 146.715C545.867 146.661 544.315 122.047 538.254 118.141C547.036 106.989 560.825 99.3485 565.437 97.4473C576.216 93.0032 583.467 91.4243 590.331 90.023C597.196 88.6217 594.62 87.0626 594.324 85.2119Z" fill="#fff"/>
        <path d="M488.264 121.603C491.983 97.7522 516.756 74.606 554.615 75.8102C563.294 76.0863 570.055 83.3645 578.148 83.5356C584.565 83.6712 594.029 83.3612 594.324 85.2119C594.62 87.0626 597.196 88.6217 590.331 90.023C583.467 91.4243 576.216 93.0032 565.437 97.4473C560.825 99.3485 547.036 106.989 538.254 118.141M488.264 121.603C478.148 131.249 469.642 144.055 466.619 152.563C477.43 147.412 487.028 149.426 500.315 153.782M488.264 121.603C486.622 132.14 490.109 143.38 500.315 153.782M500.315 153.782C511.683 165.369 531.387 175.917 561.622 183.3C563.171 190.965 568.821 199.91 576.323 201.15C577.163 199.287 576.182 196.611 576.677 193.201C577.171 189.791 583.315 179.884 586.74 170.843C588.817 168.336 593.334 167.064 590.78 164.972C585.109 165.034 566.712 170.797 563.819 176.39C538.759 162.555 530.449 149.089 530.607 137.294M538.254 118.141C544.315 122.047 545.867 146.661 540.951 146.715C536.036 146.769 532.868 141.822 530.607 137.294M538.254 118.141C533.848 123.735 530.701 130.214 530.607 137.294" stroke="#fff" fill="#fff" strokeWidth="2"/>
        <rect x="369" y="57" width="14" height="14" fill="#fff" stroke="#FA9500" strokeWidth="2"/>
      </svg>
    </SvgIcon>
  );
}

export function BrancoLogoPublic() {
  return (
    <SvgIcon sx={{ height: 40, width: 277 }}>
      <svg width="723" height="267" viewBox="0 0 723 267" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M436.475 84.1759C480.069 12.7068 573.98 -15.7098 652.002 8.87657C730.025 33.4629 734.194 87.7458 709.658 165.606C702.181 189.336 695.903 209.258 686.555 224.972C665.23 260.817 627.928 274.766 523.961 262.066C354.001 241.304 367.384 197.446 436.475 84.1759Z" fill="#FA9500"/>
      <mask id="path-2-outside-1_0_1" maskUnits="userSpaceOnUse" x="0" y="55.5771" width="699" height="137" fill="black">
      <rect fill="white" y="55.5771" width="699" height="137"/>
      <path d="M72.9461 119.769C77.6883 121.142 81.869 123.203 85.488 125.95C89.2319 128.698 92.1646 132.257 94.2861 136.628C96.5324 140.874 97.6556 145.869 97.6556 151.614C97.6556 158.982 95.7836 165.413 92.0398 170.908C88.4207 176.403 83.2417 180.711 76.5028 183.833C69.7638 186.831 61.7769 188.329 52.5421 188.329H2V57.5771H50.6701C64.3976 57.5771 75.13 60.5743 82.8673 66.5687C90.6046 72.5631 94.4733 80.4307 94.4733 90.1715C94.4733 94.7922 93.5997 99.1006 91.8526 103.097C90.2302 107.093 87.7967 110.527 84.5521 113.4C81.3074 116.272 77.4387 118.395 72.9461 119.769ZM49.7342 74.4363H21.8424V113.025H49.7342C57.0971 113.025 62.9001 111.214 67.1431 107.593C71.511 103.971 73.6949 99.1631 73.6949 93.1687C73.6949 87.2992 71.511 82.741 67.1431 79.494C62.7753 76.1222 56.9723 74.4363 49.7342 74.4363ZM21.8424 171.47H52.1677C59.7802 171.47 65.7704 169.597 70.1382 165.85C74.6308 162.104 76.8772 157.046 76.8772 150.677C76.8772 144.058 74.506 138.938 69.7638 135.317C65.1464 131.695 58.657 129.884 50.2958 129.884H21.8424V171.47Z"/>
      <path d="M196.941 129.51V117.146C196.941 111.776 195.319 107.468 192.074 104.221C188.829 100.849 184.149 99.1631 178.034 99.1631C170.297 99.1631 163.371 102.098 157.256 107.967C151.141 113.712 146.274 122.516 142.655 134.38L140.034 115.273C142.78 108.155 146.087 102.223 149.955 97.4771C153.949 92.7316 158.504 89.1725 163.621 86.7997C168.862 84.302 174.603 83.0532 180.842 83.0532C191.2 83.0532 199.624 86.0504 206.113 92.0448C212.603 97.9142 215.847 106.281 215.847 117.146V129.51H196.941ZM127.305 188.329V85.3011H145.088L146.212 108.717V188.329H127.305Z"/>
      <path d="M317.566 188.329L316.817 164.914V120.893C316.817 113.525 314.384 107.78 309.517 103.659C304.775 99.4128 298.223 97.2898 289.862 97.2898C281.75 97.2898 275.136 99.1631 270.019 102.91C264.903 106.531 262.095 111.589 261.596 118.083H243.063C243.687 110.715 246.059 104.471 250.177 99.3504C254.295 94.1053 259.786 90.1091 266.65 87.3617C273.514 84.4894 281.438 83.0532 290.423 83.0532C299.658 83.0532 307.645 84.6142 314.384 87.7363C321.123 90.7335 326.302 95.1044 329.921 100.849C333.665 106.594 335.537 113.587 335.537 121.829V188.329H317.566ZM280.315 190.577C273.576 190.577 267.648 189.391 262.532 187.018C257.415 184.52 253.421 181.024 250.551 176.528C247.681 171.907 246.246 166.537 246.246 160.418C246.246 149.803 250.302 141.561 258.413 135.691C266.65 129.822 278.131 126.887 292.857 126.887H319.251V140.936H292.108C283.622 140.936 276.945 142.56 272.078 145.807C267.336 149.054 264.965 153.612 264.965 159.481C264.965 164.352 266.65 168.161 270.019 170.908C273.514 173.656 278.193 175.029 284.059 175.029C291.546 175.029 298.161 173.031 303.901 169.035C309.767 164.914 314.509 159.294 318.128 152.176L320.187 166.038C316.942 173.905 311.95 179.962 305.211 184.208C298.473 188.454 290.174 190.577 280.315 190.577Z"/>
      <path d="M440.063 188.329V123.328C440.063 116.085 438.004 110.278 433.886 105.907C429.768 101.411 424.402 99.1631 417.788 99.1631C410.175 99.1631 403.374 101.973 397.384 107.593C391.518 113.087 386.838 121.704 383.344 133.443L380.349 112.276C384.218 102.535 389.771 95.2293 397.009 90.3588C404.372 85.4884 412.359 83.0532 420.97 83.0532C428.707 83.0532 435.384 84.7391 440.999 88.1109C446.74 91.3579 451.17 95.9161 454.29 101.786C457.41 107.655 458.97 114.586 458.97 122.579V188.329H440.063ZM366.684 188.329V85.3011H384.654L385.59 108.529V188.329H366.684Z"/>
      <path d="M645.522 190.577C634.914 190.577 625.742 188.392 618.005 184.021C610.392 179.65 604.527 173.468 600.408 165.476C596.29 157.358 594.231 147.805 594.231 136.815C594.231 125.576 596.29 116.022 600.408 108.155C604.527 100.162 610.392 93.9804 618.005 89.6095C625.742 85.2386 634.977 83.0532 645.709 83.0532C661.683 83.0532 674.225 87.8612 683.335 97.4771C692.445 106.968 697 120.081 697 136.815C697 147.805 694.941 157.358 690.823 165.476C686.704 173.468 680.777 179.65 673.039 184.021C665.427 188.392 656.254 190.577 645.522 190.577ZM645.709 174.842C655.693 174.842 663.555 171.47 669.295 164.726C675.161 157.858 678.094 148.554 678.094 136.815C678.094 124.951 675.161 115.648 669.295 108.904C663.555 102.16 655.693 98.7884 645.709 98.7884C635.601 98.7884 627.676 102.16 621.936 108.904C616.195 115.648 613.325 124.951 613.325 136.815C613.325 148.554 616.195 157.858 621.936 164.726C627.676 171.47 635.601 174.842 645.709 174.842Z"/>
      </mask>
      <path d="M72.9461 119.769C77.6883 121.142 81.869 123.203 85.488 125.95C89.2319 128.698 92.1646 132.257 94.2861 136.628C96.5324 140.874 97.6556 145.869 97.6556 151.614C97.6556 158.982 95.7836 165.413 92.0398 170.908C88.4207 176.403 83.2417 180.711 76.5028 183.833C69.7638 186.831 61.7769 188.329 52.5421 188.329H2V57.5771H50.6701C64.3976 57.5771 75.13 60.5743 82.8673 66.5687C90.6046 72.5631 94.4733 80.4307 94.4733 90.1715C94.4733 94.7922 93.5997 99.1006 91.8526 103.097C90.2302 107.093 87.7967 110.527 84.5521 113.4C81.3074 116.272 77.4387 118.395 72.9461 119.769ZM49.7342 74.4363H21.8424V113.025H49.7342C57.0971 113.025 62.9001 111.214 67.1431 107.593C71.511 103.971 73.6949 99.1631 73.6949 93.1687C73.6949 87.2992 71.511 82.741 67.1431 79.494C62.7753 76.1222 56.9723 74.4363 49.7342 74.4363ZM21.8424 171.47H52.1677C59.7802 171.47 65.7704 169.597 70.1382 165.85C74.6308 162.104 76.8772 157.046 76.8772 150.677C76.8772 144.058 74.506 138.938 69.7638 135.317C65.1464 131.695 58.657 129.884 50.2958 129.884H21.8424V171.47Z" fill="#2C2738"/>
      <path d="M196.941 129.51V117.146C196.941 111.776 195.319 107.468 192.074 104.221C188.829 100.849 184.149 99.1631 178.034 99.1631C170.297 99.1631 163.371 102.098 157.256 107.967C151.141 113.712 146.274 122.516 142.655 134.38L140.034 115.273C142.78 108.155 146.087 102.223 149.955 97.4771C153.949 92.7316 158.504 89.1725 163.621 86.7997C168.862 84.302 174.603 83.0532 180.842 83.0532C191.2 83.0532 199.624 86.0504 206.113 92.0448C212.603 97.9142 215.847 106.281 215.847 117.146V129.51H196.941ZM127.305 188.329V85.3011H145.088L146.212 108.717V188.329H127.305Z" fill="#2C2738"/>
      <path d="M317.566 188.329L316.817 164.914V120.893C316.817 113.525 314.384 107.78 309.517 103.659C304.775 99.4128 298.223 97.2898 289.862 97.2898C281.75 97.2898 275.136 99.1631 270.019 102.91C264.903 106.531 262.095 111.589 261.596 118.083H243.063C243.687 110.715 246.059 104.471 250.177 99.3504C254.295 94.1053 259.786 90.1091 266.65 87.3617C273.514 84.4894 281.438 83.0532 290.423 83.0532C299.658 83.0532 307.645 84.6142 314.384 87.7363C321.123 90.7335 326.302 95.1044 329.921 100.849C333.665 106.594 335.537 113.587 335.537 121.829V188.329H317.566ZM280.315 190.577C273.576 190.577 267.648 189.391 262.532 187.018C257.415 184.52 253.421 181.024 250.551 176.528C247.681 171.907 246.246 166.537 246.246 160.418C246.246 149.803 250.302 141.561 258.413 135.691C266.65 129.822 278.131 126.887 292.857 126.887H319.251V140.936H292.108C283.622 140.936 276.945 142.56 272.078 145.807C267.336 149.054 264.965 153.612 264.965 159.481C264.965 164.352 266.65 168.161 270.019 170.908C273.514 173.656 278.193 175.029 284.059 175.029C291.546 175.029 298.161 173.031 303.901 169.035C309.767 164.914 314.509 159.294 318.128 152.176L320.187 166.038C316.942 173.905 311.95 179.962 305.211 184.208C298.473 188.454 290.174 190.577 280.315 190.577Z" fill="#2C2738"/>
      <path d="M440.063 188.329V123.328C440.063 116.085 438.004 110.278 433.886 105.907C429.768 101.411 424.402 99.1631 417.788 99.1631C410.175 99.1631 403.374 101.973 397.384 107.593C391.518 113.087 386.838 121.704 383.344 133.443L380.349 112.276C384.218 102.535 389.771 95.2293 397.009 90.3588C404.372 85.4884 412.359 83.0532 420.97 83.0532C428.707 83.0532 435.384 84.7391 440.999 88.1109C446.74 91.3579 451.17 95.9161 454.29 101.786C457.41 107.655 458.97 114.586 458.97 122.579V188.329H440.063ZM366.684 188.329V85.3011H384.654L385.59 108.529V188.329H366.684Z" fill="#2C2738"/>
      <path d="M645.522 190.577C634.914 190.577 625.742 188.392 618.005 184.021C610.392 179.65 604.527 173.468 600.408 165.476C596.29 157.358 594.231 147.805 594.231 136.815C594.231 125.576 596.29 116.022 600.408 108.155C604.527 100.162 610.392 93.9804 618.005 89.6095C625.742 85.2386 634.977 83.0532 645.709 83.0532C661.683 83.0532 674.225 87.8612 683.335 97.4771C692.445 106.968 697 120.081 697 136.815C697 147.805 694.941 157.358 690.823 165.476C686.704 173.468 680.777 179.65 673.039 184.021C665.427 188.392 656.254 190.577 645.522 190.577ZM645.709 174.842C655.693 174.842 663.555 171.47 669.295 164.726C675.161 157.858 678.094 148.554 678.094 136.815C678.094 124.951 675.161 115.648 669.295 108.904C663.555 102.16 655.693 98.7884 645.709 98.7884C635.601 98.7884 627.676 102.16 621.936 108.904C616.195 115.648 613.325 124.951 613.325 136.815C613.325 148.554 616.195 157.858 621.936 164.726C627.676 171.47 635.601 174.842 645.709 174.842Z" fill="#2C2738"/>
      <path d="M72.9461 119.769C77.6883 121.142 81.869 123.203 85.488 125.95C89.2319 128.698 92.1646 132.257 94.2861 136.628C96.5324 140.874 97.6556 145.869 97.6556 151.614C97.6556 158.982 95.7836 165.413 92.0398 170.908C88.4207 176.403 83.2417 180.711 76.5028 183.833C69.7638 186.831 61.7769 188.329 52.5421 188.329H2V57.5771H50.6701C64.3976 57.5771 75.13 60.5743 82.8673 66.5687C90.6046 72.5631 94.4733 80.4307 94.4733 90.1715C94.4733 94.7922 93.5997 99.1006 91.8526 103.097C90.2302 107.093 87.7967 110.527 84.5521 113.4C81.3074 116.272 77.4387 118.395 72.9461 119.769ZM49.7342 74.4363H21.8424V113.025H49.7342C57.0971 113.025 62.9001 111.214 67.1431 107.593C71.511 103.971 73.6949 99.1631 73.6949 93.1687C73.6949 87.2992 71.511 82.741 67.1431 79.494C62.7753 76.1222 56.9723 74.4363 49.7342 74.4363ZM21.8424 171.47H52.1677C59.7802 171.47 65.7704 169.597 70.1382 165.85C74.6308 162.104 76.8772 157.046 76.8772 150.677C76.8772 144.058 74.506 138.938 69.7638 135.317C65.1464 131.695 58.657 129.884 50.2958 129.884H21.8424V171.47Z" stroke="#FA9500" strokeWidth="4" mask="url(#path-2-outside-1_0_1)"/>
      <path d="M196.941 129.51V117.146C196.941 111.776 195.319 107.468 192.074 104.221C188.829 100.849 184.149 99.1631 178.034 99.1631C170.297 99.1631 163.371 102.098 157.256 107.967C151.141 113.712 146.274 122.516 142.655 134.38L140.034 115.273C142.78 108.155 146.087 102.223 149.955 97.4771C153.949 92.7316 158.504 89.1725 163.621 86.7997C168.862 84.302 174.603 83.0532 180.842 83.0532C191.2 83.0532 199.624 86.0504 206.113 92.0448C212.603 97.9142 215.847 106.281 215.847 117.146V129.51H196.941ZM127.305 188.329V85.3011H145.088L146.212 108.717V188.329H127.305Z" stroke="#FA9500" strokeWidth="4" mask="url(#path-2-outside-1_0_1)"/>
      <path d="M317.566 188.329L316.817 164.914V120.893C316.817 113.525 314.384 107.78 309.517 103.659C304.775 99.4128 298.223 97.2898 289.862 97.2898C281.75 97.2898 275.136 99.1631 270.019 102.91C264.903 106.531 262.095 111.589 261.596 118.083H243.063C243.687 110.715 246.059 104.471 250.177 99.3504C254.295 94.1053 259.786 90.1091 266.65 87.3617C273.514 84.4894 281.438 83.0532 290.423 83.0532C299.658 83.0532 307.645 84.6142 314.384 87.7363C321.123 90.7335 326.302 95.1044 329.921 100.849C333.665 106.594 335.537 113.587 335.537 121.829V188.329H317.566ZM280.315 190.577C273.576 190.577 267.648 189.391 262.532 187.018C257.415 184.52 253.421 181.024 250.551 176.528C247.681 171.907 246.246 166.537 246.246 160.418C246.246 149.803 250.302 141.561 258.413 135.691C266.65 129.822 278.131 126.887 292.857 126.887H319.251V140.936H292.108C283.622 140.936 276.945 142.56 272.078 145.807C267.336 149.054 264.965 153.612 264.965 159.481C264.965 164.352 266.65 168.161 270.019 170.908C273.514 173.656 278.193 175.029 284.059 175.029C291.546 175.029 298.161 173.031 303.901 169.035C309.767 164.914 314.509 159.294 318.128 152.176L320.187 166.038C316.942 173.905 311.95 179.962 305.211 184.208C298.473 188.454 290.174 190.577 280.315 190.577Z" stroke="#FA9500" strokeWidth="4" mask="url(#path-2-outside-1_0_1)"/>
      <path d="M440.063 188.329V123.328C440.063 116.085 438.004 110.278 433.886 105.907C429.768 101.411 424.402 99.1631 417.788 99.1631C410.175 99.1631 403.374 101.973 397.384 107.593C391.518 113.087 386.838 121.704 383.344 133.443L380.349 112.276C384.218 102.535 389.771 95.2293 397.009 90.3588C404.372 85.4884 412.359 83.0532 420.97 83.0532C428.707 83.0532 435.384 84.7391 440.999 88.1109C446.74 91.3579 451.17 95.9161 454.29 101.786C457.41 107.655 458.97 114.586 458.97 122.579V188.329H440.063ZM366.684 188.329V85.3011H384.654L385.59 108.529V188.329H366.684Z" stroke="#FA9500" strokeWidth="4" mask="url(#path-2-outside-1_0_1)"/>
      <path d="M645.522 190.577C634.914 190.577 625.742 188.392 618.005 184.021C610.392 179.65 604.527 173.468 600.408 165.476C596.29 157.358 594.231 147.805 594.231 136.815C594.231 125.576 596.29 116.022 600.408 108.155C604.527 100.162 610.392 93.9804 618.005 89.6095C625.742 85.2386 634.977 83.0532 645.709 83.0532C661.683 83.0532 674.225 87.8612 683.335 97.4771C692.445 106.968 697 120.081 697 136.815C697 147.805 694.941 157.358 690.823 165.476C686.704 173.468 680.777 179.65 673.039 184.021C665.427 188.392 656.254 190.577 645.522 190.577ZM645.709 174.842C655.693 174.842 663.555 171.47 669.295 164.726C675.161 157.858 678.094 148.554 678.094 136.815C678.094 124.951 675.161 115.648 669.295 108.904C663.555 102.16 655.693 98.7884 645.709 98.7884C635.601 98.7884 627.676 102.16 621.936 108.904C616.195 115.648 613.325 124.951 613.325 136.815C613.325 148.554 616.195 157.858 621.936 164.726C627.676 171.47 635.601 174.842 645.709 174.842Z" stroke="#FA9500" strokeWidth="4" mask="url(#path-2-outside-1_0_1)"/>
      <path d="M594.324 85.2119C594.029 83.3612 584.565 83.6712 578.148 83.5356C570.055 83.3645 563.294 76.0863 554.615 75.8102C516.756 74.606 491.983 97.7522 488.264 121.603C478.148 131.249 469.642 144.055 466.619 152.563C477.43 147.412 487.028 149.426 500.315 153.782C511.683 165.369 531.387 175.917 561.622 183.3C563.171 190.965 568.821 199.91 576.323 201.15C577.163 199.287 576.182 196.611 576.677 193.201C577.171 189.791 583.315 179.884 586.74 170.843C588.817 168.336 593.334 167.064 590.78 164.972C585.109 165.034 566.712 170.797 563.819 176.39C538.759 162.555 530.449 149.089 530.607 137.294C532.868 141.822 536.036 146.769 540.951 146.715C545.867 146.661 544.315 122.047 538.254 118.141C547.036 106.989 560.825 99.3485 565.437 97.4473C576.216 93.0032 583.467 91.4243 590.331 90.023C597.196 88.6217 594.62 87.0626 594.324 85.2119Z" fill="#2C2738"/>
      <path d="M488.264 121.603C491.983 97.7522 516.756 74.606 554.615 75.8102C563.294 76.0863 570.055 83.3645 578.148 83.5356C584.565 83.6712 594.029 83.3612 594.324 85.2119C594.62 87.0626 597.196 88.6217 590.331 90.023C583.467 91.4243 576.216 93.0032 565.437 97.4473C560.825 99.3485 547.036 106.989 538.254 118.141M488.264 121.603C478.148 131.249 469.642 144.055 466.619 152.563C477.43 147.412 487.028 149.426 500.315 153.782M488.264 121.603C486.622 132.14 490.109 143.38 500.315 153.782M500.315 153.782C511.683 165.369 531.387 175.917 561.622 183.3C563.171 190.965 568.821 199.91 576.323 201.15C577.163 199.287 576.182 196.611 576.677 193.201C577.171 189.791 583.315 179.884 586.74 170.843C588.817 168.336 593.334 167.064 590.78 164.972C585.109 165.034 566.712 170.797 563.819 176.39C538.759 162.555 530.449 149.089 530.607 137.294M538.254 118.141C544.315 122.047 545.867 146.661 540.951 146.715C536.036 146.769 532.868 141.822 530.607 137.294M538.254 118.141C533.848 123.735 530.701 130.214 530.607 137.294" stroke="#2C2738" strokeWidth="2"/>
      <rect x="369" y="57" width="14" height="14" fill="#2C2738" stroke="#FA9500" strokeWidth="2"/>
      </svg>
    </SvgIcon>
  );
}

export function BrancoLogoPublicWhite() {
  return (
    <SvgIcon sx={{ height: 40, width: 277 }}>
      <svg width="723" height="267" viewBox="0 0 723 267" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M436.475 84.1759C480.069 12.7068 573.98 -15.7098 652.002 8.87657C730.025 33.4629 734.194 87.7458 709.658 165.606C702.181 189.336 695.903 209.258 686.555 224.972C665.23 260.817 627.928 274.766 523.961 262.066C354.001 241.304 367.384 197.446 436.475 84.1759Z" fill="#FA9500"/>
        <mask id="path-2-outside-1_0_1" maskUnits="userSpaceOnUse" x="0" y="55.5771" width="699" height="137" fill="black">
        <rect fill="white" y="55.5771" width="699" height="137"/>
        <path d="M72.9461 119.769C77.6883 121.142 81.869 123.203 85.488 125.95C89.2319 128.698 92.1646 132.257 94.2861 136.628C96.5324 140.874 97.6556 145.869 97.6556 151.614C97.6556 158.982 95.7836 165.413 92.0398 170.908C88.4207 176.403 83.2417 180.711 76.5028 183.833C69.7638 186.831 61.7769 188.329 52.5421 188.329H2V57.5771H50.6701C64.3976 57.5771 75.13 60.5743 82.8673 66.5687C90.6046 72.5631 94.4733 80.4307 94.4733 90.1715C94.4733 94.7922 93.5997 99.1006 91.8526 103.097C90.2302 107.093 87.7967 110.527 84.5521 113.4C81.3074 116.272 77.4387 118.395 72.9461 119.769ZM49.7342 74.4363H21.8424V113.025H49.7342C57.0971 113.025 62.9001 111.214 67.1431 107.593C71.511 103.971 73.6949 99.1631 73.6949 93.1687C73.6949 87.2992 71.511 82.741 67.1431 79.494C62.7753 76.1222 56.9723 74.4363 49.7342 74.4363ZM21.8424 171.47H52.1677C59.7802 171.47 65.7704 169.597 70.1382 165.85C74.6308 162.104 76.8772 157.046 76.8772 150.677C76.8772 144.058 74.506 138.938 69.7638 135.317C65.1464 131.695 58.657 129.884 50.2958 129.884H21.8424V171.47Z"/>
        <path d="M196.941 129.51V117.146C196.941 111.776 195.319 107.468 192.074 104.221C188.829 100.849 184.149 99.1631 178.034 99.1631C170.297 99.1631 163.371 102.098 157.256 107.967C151.141 113.712 146.274 122.516 142.655 134.38L140.034 115.273C142.78 108.155 146.087 102.223 149.955 97.4771C153.949 92.7316 158.504 89.1725 163.621 86.7997C168.862 84.302 174.603 83.0532 180.842 83.0532C191.2 83.0532 199.624 86.0504 206.113 92.0448C212.603 97.9142 215.847 106.281 215.847 117.146V129.51H196.941ZM127.305 188.329V85.3011H145.088L146.212 108.717V188.329H127.305Z"/>
        <path d="M317.566 188.329L316.817 164.914V120.893C316.817 113.525 314.384 107.78 309.517 103.659C304.775 99.4128 298.223 97.2898 289.862 97.2898C281.75 97.2898 275.136 99.1631 270.019 102.91C264.903 106.531 262.095 111.589 261.596 118.083H243.063C243.687 110.715 246.059 104.471 250.177 99.3504C254.295 94.1053 259.786 90.1091 266.65 87.3617C273.514 84.4894 281.438 83.0532 290.423 83.0532C299.658 83.0532 307.645 84.6142 314.384 87.7363C321.123 90.7335 326.302 95.1044 329.921 100.849C333.665 106.594 335.537 113.587 335.537 121.829V188.329H317.566ZM280.315 190.577C273.576 190.577 267.648 189.391 262.532 187.018C257.415 184.52 253.421 181.024 250.551 176.528C247.681 171.907 246.246 166.537 246.246 160.418C246.246 149.803 250.302 141.561 258.413 135.691C266.65 129.822 278.131 126.887 292.857 126.887H319.251V140.936H292.108C283.622 140.936 276.945 142.56 272.078 145.807C267.336 149.054 264.965 153.612 264.965 159.481C264.965 164.352 266.65 168.161 270.019 170.908C273.514 173.656 278.193 175.029 284.059 175.029C291.546 175.029 298.161 173.031 303.901 169.035C309.767 164.914 314.509 159.294 318.128 152.176L320.187 166.038C316.942 173.905 311.95 179.962 305.211 184.208C298.473 188.454 290.174 190.577 280.315 190.577Z"/>
        <path d="M440.063 188.329V123.328C440.063 116.085 438.004 110.278 433.886 105.907C429.768 101.411 424.402 99.1631 417.788 99.1631C410.175 99.1631 403.374 101.973 397.384 107.593C391.518 113.087 386.838 121.704 383.344 133.443L380.349 112.276C384.218 102.535 389.771 95.2293 397.009 90.3588C404.372 85.4884 412.359 83.0532 420.97 83.0532C428.707 83.0532 435.384 84.7391 440.999 88.1109C446.74 91.3579 451.17 95.9161 454.29 101.786C457.41 107.655 458.97 114.586 458.97 122.579V188.329H440.063ZM366.684 188.329V85.3011H384.654L385.59 108.529V188.329H366.684Z"/>
        <path d="M645.522 190.577C634.914 190.577 625.742 188.392 618.005 184.021C610.392 179.65 604.527 173.468 600.408 165.476C596.29 157.358 594.231 147.805 594.231 136.815C594.231 125.576 596.29 116.022 600.408 108.155C604.527 100.162 610.392 93.9804 618.005 89.6095C625.742 85.2386 634.977 83.0532 645.709 83.0532C661.683 83.0532 674.225 87.8612 683.335 97.4771C692.445 106.968 697 120.081 697 136.815C697 147.805 694.941 157.358 690.823 165.476C686.704 173.468 680.777 179.65 673.039 184.021C665.427 188.392 656.254 190.577 645.522 190.577ZM645.709 174.842C655.693 174.842 663.555 171.47 669.295 164.726C675.161 157.858 678.094 148.554 678.094 136.815C678.094 124.951 675.161 115.648 669.295 108.904C663.555 102.16 655.693 98.7884 645.709 98.7884C635.601 98.7884 627.676 102.16 621.936 108.904C616.195 115.648 613.325 124.951 613.325 136.815C613.325 148.554 616.195 157.858 621.936 164.726C627.676 171.47 635.601 174.842 645.709 174.842Z"/>
        </mask>
        <path d="M72.9461 119.769C77.6883 121.142 81.869 123.203 85.488 125.95C89.2319 128.698 92.1646 132.257 94.2861 136.628C96.5324 140.874 97.6556 145.869 97.6556 151.614C97.6556 158.982 95.7836 165.413 92.0398 170.908C88.4207 176.403 83.2417 180.711 76.5028 183.833C69.7638 186.831 61.7769 188.329 52.5421 188.329H2V57.5771H50.6701C64.3976 57.5771 75.13 60.5743 82.8673 66.5687C90.6046 72.5631 94.4733 80.4307 94.4733 90.1715C94.4733 94.7922 93.5997 99.1006 91.8526 103.097C90.2302 107.093 87.7967 110.527 84.5521 113.4C81.3074 116.272 77.4387 118.395 72.9461 119.769ZM49.7342 74.4363H21.8424V113.025H49.7342C57.0971 113.025 62.9001 111.214 67.1431 107.593C71.511 103.971 73.6949 99.1631 73.6949 93.1687C73.6949 87.2992 71.511 82.741 67.1431 79.494C62.7753 76.1222 56.9723 74.4363 49.7342 74.4363ZM21.8424 171.47H52.1677C59.7802 171.47 65.7704 169.597 70.1382 165.85C74.6308 162.104 76.8772 157.046 76.8772 150.677C76.8772 144.058 74.506 138.938 69.7638 135.317C65.1464 131.695 58.657 129.884 50.2958 129.884H21.8424V171.47Z" fill="#fff"/>
        <path d="M196.941 129.51V117.146C196.941 111.776 195.319 107.468 192.074 104.221C188.829 100.849 184.149 99.1631 178.034 99.1631C170.297 99.1631 163.371 102.098 157.256 107.967C151.141 113.712 146.274 122.516 142.655 134.38L140.034 115.273C142.78 108.155 146.087 102.223 149.955 97.4771C153.949 92.7316 158.504 89.1725 163.621 86.7997C168.862 84.302 174.603 83.0532 180.842 83.0532C191.2 83.0532 199.624 86.0504 206.113 92.0448C212.603 97.9142 215.847 106.281 215.847 117.146V129.51H196.941ZM127.305 188.329V85.3011H145.088L146.212 108.717V188.329H127.305Z" fill="#fff"/>
        <path d="M317.566 188.329L316.817 164.914V120.893C316.817 113.525 314.384 107.78 309.517 103.659C304.775 99.4128 298.223 97.2898 289.862 97.2898C281.75 97.2898 275.136 99.1631 270.019 102.91C264.903 106.531 262.095 111.589 261.596 118.083H243.063C243.687 110.715 246.059 104.471 250.177 99.3504C254.295 94.1053 259.786 90.1091 266.65 87.3617C273.514 84.4894 281.438 83.0532 290.423 83.0532C299.658 83.0532 307.645 84.6142 314.384 87.7363C321.123 90.7335 326.302 95.1044 329.921 100.849C333.665 106.594 335.537 113.587 335.537 121.829V188.329H317.566ZM280.315 190.577C273.576 190.577 267.648 189.391 262.532 187.018C257.415 184.52 253.421 181.024 250.551 176.528C247.681 171.907 246.246 166.537 246.246 160.418C246.246 149.803 250.302 141.561 258.413 135.691C266.65 129.822 278.131 126.887 292.857 126.887H319.251V140.936H292.108C283.622 140.936 276.945 142.56 272.078 145.807C267.336 149.054 264.965 153.612 264.965 159.481C264.965 164.352 266.65 168.161 270.019 170.908C273.514 173.656 278.193 175.029 284.059 175.029C291.546 175.029 298.161 173.031 303.901 169.035C309.767 164.914 314.509 159.294 318.128 152.176L320.187 166.038C316.942 173.905 311.95 179.962 305.211 184.208C298.473 188.454 290.174 190.577 280.315 190.577Z" fill="#fff"/>
        <path d="M440.063 188.329V123.328C440.063 116.085 438.004 110.278 433.886 105.907C429.768 101.411 424.402 99.1631 417.788 99.1631C410.175 99.1631 403.374 101.973 397.384 107.593C391.518 113.087 386.838 121.704 383.344 133.443L380.349 112.276C384.218 102.535 389.771 95.2293 397.009 90.3588C404.372 85.4884 412.359 83.0532 420.97 83.0532C428.707 83.0532 435.384 84.7391 440.999 88.1109C446.74 91.3579 451.17 95.9161 454.29 101.786C457.41 107.655 458.97 114.586 458.97 122.579V188.329H440.063ZM366.684 188.329V85.3011H384.654L385.59 108.529V188.329H366.684Z" fill="#fff"/>
        <path d="M645.522 190.577C634.914 190.577 625.742 188.392 618.005 184.021C610.392 179.65 604.527 173.468 600.408 165.476C596.29 157.358 594.231 147.805 594.231 136.815C594.231 125.576 596.29 116.022 600.408 108.155C604.527 100.162 610.392 93.9804 618.005 89.6095C625.742 85.2386 634.977 83.0532 645.709 83.0532C661.683 83.0532 674.225 87.8612 683.335 97.4771C692.445 106.968 697 120.081 697 136.815C697 147.805 694.941 157.358 690.823 165.476C686.704 173.468 680.777 179.65 673.039 184.021C665.427 188.392 656.254 190.577 645.522 190.577ZM645.709 174.842C655.693 174.842 663.555 171.47 669.295 164.726C675.161 157.858 678.094 148.554 678.094 136.815C678.094 124.951 675.161 115.648 669.295 108.904C663.555 102.16 655.693 98.7884 645.709 98.7884C635.601 98.7884 627.676 102.16 621.936 108.904C616.195 115.648 613.325 124.951 613.325 136.815C613.325 148.554 616.195 157.858 621.936 164.726C627.676 171.47 635.601 174.842 645.709 174.842Z" fill="#fff"/>
        <path d="M72.9461 119.769C77.6883 121.142 81.869 123.203 85.488 125.95C89.2319 128.698 92.1646 132.257 94.2861 136.628C96.5324 140.874 97.6556 145.869 97.6556 151.614C97.6556 158.982 95.7836 165.413 92.0398 170.908C88.4207 176.403 83.2417 180.711 76.5028 183.833C69.7638 186.831 61.7769 188.329 52.5421 188.329H2V57.5771H50.6701C64.3976 57.5771 75.13 60.5743 82.8673 66.5687C90.6046 72.5631 94.4733 80.4307 94.4733 90.1715C94.4733 94.7922 93.5997 99.1006 91.8526 103.097C90.2302 107.093 87.7967 110.527 84.5521 113.4C81.3074 116.272 77.4387 118.395 72.9461 119.769ZM49.7342 74.4363H21.8424V113.025H49.7342C57.0971 113.025 62.9001 111.214 67.1431 107.593C71.511 103.971 73.6949 99.1631 73.6949 93.1687C73.6949 87.2992 71.511 82.741 67.1431 79.494C62.7753 76.1222 56.9723 74.4363 49.7342 74.4363ZM21.8424 171.47H52.1677C59.7802 171.47 65.7704 169.597 70.1382 165.85C74.6308 162.104 76.8772 157.046 76.8772 150.677C76.8772 144.058 74.506 138.938 69.7638 135.317C65.1464 131.695 58.657 129.884 50.2958 129.884H21.8424V171.47Z" stroke="#FA9500" strokeWidth="4" mask="url(#path-2-outside-1_0_1)"/>
        <path d="M196.941 129.51V117.146C196.941 111.776 195.319 107.468 192.074 104.221C188.829 100.849 184.149 99.1631 178.034 99.1631C170.297 99.1631 163.371 102.098 157.256 107.967C151.141 113.712 146.274 122.516 142.655 134.38L140.034 115.273C142.78 108.155 146.087 102.223 149.955 97.4771C153.949 92.7316 158.504 89.1725 163.621 86.7997C168.862 84.302 174.603 83.0532 180.842 83.0532C191.2 83.0532 199.624 86.0504 206.113 92.0448C212.603 97.9142 215.847 106.281 215.847 117.146V129.51H196.941ZM127.305 188.329V85.3011H145.088L146.212 108.717V188.329H127.305Z" stroke="#FA9500" strokeWidth="4" mask="url(#path-2-outside-1_0_1)"/>
        <path d="M317.566 188.329L316.817 164.914V120.893C316.817 113.525 314.384 107.78 309.517 103.659C304.775 99.4128 298.223 97.2898 289.862 97.2898C281.75 97.2898 275.136 99.1631 270.019 102.91C264.903 106.531 262.095 111.589 261.596 118.083H243.063C243.687 110.715 246.059 104.471 250.177 99.3504C254.295 94.1053 259.786 90.1091 266.65 87.3617C273.514 84.4894 281.438 83.0532 290.423 83.0532C299.658 83.0532 307.645 84.6142 314.384 87.7363C321.123 90.7335 326.302 95.1044 329.921 100.849C333.665 106.594 335.537 113.587 335.537 121.829V188.329H317.566ZM280.315 190.577C273.576 190.577 267.648 189.391 262.532 187.018C257.415 184.52 253.421 181.024 250.551 176.528C247.681 171.907 246.246 166.537 246.246 160.418C246.246 149.803 250.302 141.561 258.413 135.691C266.65 129.822 278.131 126.887 292.857 126.887H319.251V140.936H292.108C283.622 140.936 276.945 142.56 272.078 145.807C267.336 149.054 264.965 153.612 264.965 159.481C264.965 164.352 266.65 168.161 270.019 170.908C273.514 173.656 278.193 175.029 284.059 175.029C291.546 175.029 298.161 173.031 303.901 169.035C309.767 164.914 314.509 159.294 318.128 152.176L320.187 166.038C316.942 173.905 311.95 179.962 305.211 184.208C298.473 188.454 290.174 190.577 280.315 190.577Z" stroke="#FA9500" strokeWidth="4" mask="url(#path-2-outside-1_0_1)"/>
        <path d="M440.063 188.329V123.328C440.063 116.085 438.004 110.278 433.886 105.907C429.768 101.411 424.402 99.1631 417.788 99.1631C410.175 99.1631 403.374 101.973 397.384 107.593C391.518 113.087 386.838 121.704 383.344 133.443L380.349 112.276C384.218 102.535 389.771 95.2293 397.009 90.3588C404.372 85.4884 412.359 83.0532 420.97 83.0532C428.707 83.0532 435.384 84.7391 440.999 88.1109C446.74 91.3579 451.17 95.9161 454.29 101.786C457.41 107.655 458.97 114.586 458.97 122.579V188.329H440.063ZM366.684 188.329V85.3011H384.654L385.59 108.529V188.329H366.684Z" stroke="#FA9500" strokeWidth="4" mask="url(#path-2-outside-1_0_1)"/>
        <path d="M645.522 190.577C634.914 190.577 625.742 188.392 618.005 184.021C610.392 179.65 604.527 173.468 600.408 165.476C596.29 157.358 594.231 147.805 594.231 136.815C594.231 125.576 596.29 116.022 600.408 108.155C604.527 100.162 610.392 93.9804 618.005 89.6095C625.742 85.2386 634.977 83.0532 645.709 83.0532C661.683 83.0532 674.225 87.8612 683.335 97.4771C692.445 106.968 697 120.081 697 136.815C697 147.805 694.941 157.358 690.823 165.476C686.704 173.468 680.777 179.65 673.039 184.021C665.427 188.392 656.254 190.577 645.522 190.577ZM645.709 174.842C655.693 174.842 663.555 171.47 669.295 164.726C675.161 157.858 678.094 148.554 678.094 136.815C678.094 124.951 675.161 115.648 669.295 108.904C663.555 102.16 655.693 98.7884 645.709 98.7884C635.601 98.7884 627.676 102.16 621.936 108.904C616.195 115.648 613.325 124.951 613.325 136.815C613.325 148.554 616.195 157.858 621.936 164.726C627.676 171.47 635.601 174.842 645.709 174.842Z" stroke="#FA9500" strokeWidth="4" mask="url(#path-2-outside-1_0_1)"/>
        <path d="M594.324 85.2119C594.029 83.3612 584.565 83.6712 578.148 83.5356C570.055 83.3645 563.294 76.0863 554.615 75.8102C516.756 74.606 491.983 97.7522 488.264 121.603C478.148 131.249 469.642 144.055 466.619 152.563C477.43 147.412 487.028 149.426 500.315 153.782C511.683 165.369 531.387 175.917 561.622 183.3C563.171 190.965 568.821 199.91 576.323 201.15C577.163 199.287 576.182 196.611 576.677 193.201C577.171 189.791 583.315 179.884 586.74 170.843C588.817 168.336 593.334 167.064 590.78 164.972C585.109 165.034 566.712 170.797 563.819 176.39C538.759 162.555 530.449 149.089 530.607 137.294C532.868 141.822 536.036 146.769 540.951 146.715C545.867 146.661 544.315 122.047 538.254 118.141C547.036 106.989 560.825 99.3485 565.437 97.4473C576.216 93.0032 583.467 91.4243 590.331 90.023C597.196 88.6217 594.62 87.0626 594.324 85.2119Z" fill="#fff"/>
        <path d="M488.264 121.603C491.983 97.7522 516.756 74.606 554.615 75.8102C563.294 76.0863 570.055 83.3645 578.148 83.5356C584.565 83.6712 594.029 83.3612 594.324 85.2119C594.62 87.0626 597.196 88.6217 590.331 90.023C583.467 91.4243 576.216 93.0032 565.437 97.4473C560.825 99.3485 547.036 106.989 538.254 118.141M488.264 121.603C478.148 131.249 469.642 144.055 466.619 152.563C477.43 147.412 487.028 149.426 500.315 153.782M488.264 121.603C486.622 132.14 490.109 143.38 500.315 153.782M500.315 153.782C511.683 165.369 531.387 175.917 561.622 183.3C563.171 190.965 568.821 199.91 576.323 201.15C577.163 199.287 576.182 196.611 576.677 193.201C577.171 189.791 583.315 179.884 586.74 170.843C588.817 168.336 593.334 167.064 590.78 164.972C585.109 165.034 566.712 170.797 563.819 176.39C538.759 162.555 530.449 149.089 530.607 137.294M538.254 118.141C544.315 122.047 545.867 146.661 540.951 146.715C536.036 146.769 532.868 141.822 530.607 137.294M538.254 118.141C533.848 123.735 530.701 130.214 530.607 137.294" stroke="#ff" strokeWidth="2" fill="#fff"/>
        <rect x="369" y="57" width="14" height="14" fill="#fff" stroke="#FA9500" strokeWidth="2"/>
      </svg>
    </SvgIcon>
  );
}


export function BrancoLogoMobile() {
  return (
    <SvgIcon sx={{ height: 26, width: 72 }}>
      <svg width="723" height="267" viewBox="0 0 723 267" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M436.475 84.1759C480.069 12.7068 573.98 -15.7098 652.002 8.87657C730.025 33.4629 734.194 87.7458 709.658 165.606C702.181 189.336 695.903 209.258 686.555 224.972C665.23 260.817 627.928 274.766 523.961 262.066C354.001 241.304 367.384 197.446 436.475 84.1759Z" fill="#FA9500"/>
      <mask id="path-2-outside-1_0_1" maskUnits="userSpaceOnUse" x="0" y="55.5771" width="699" height="137" fill="black">
      <rect fill="white" y="55.5771" width="699" height="137"/>
      <path d="M72.9461 119.769C77.6883 121.142 81.869 123.203 85.488 125.95C89.2319 128.698 92.1646 132.257 94.2861 136.628C96.5324 140.874 97.6556 145.869 97.6556 151.614C97.6556 158.982 95.7836 165.413 92.0398 170.908C88.4207 176.403 83.2417 180.711 76.5028 183.833C69.7638 186.831 61.7769 188.329 52.5421 188.329H2V57.5771H50.6701C64.3976 57.5771 75.13 60.5743 82.8673 66.5687C90.6046 72.5631 94.4733 80.4307 94.4733 90.1715C94.4733 94.7922 93.5997 99.1006 91.8526 103.097C90.2302 107.093 87.7967 110.527 84.5521 113.4C81.3074 116.272 77.4387 118.395 72.9461 119.769ZM49.7342 74.4363H21.8424V113.025H49.7342C57.0971 113.025 62.9001 111.214 67.1431 107.593C71.511 103.971 73.6949 99.1631 73.6949 93.1687C73.6949 87.2992 71.511 82.741 67.1431 79.494C62.7753 76.1222 56.9723 74.4363 49.7342 74.4363ZM21.8424 171.47H52.1677C59.7802 171.47 65.7704 169.597 70.1382 165.85C74.6308 162.104 76.8772 157.046 76.8772 150.677C76.8772 144.058 74.506 138.938 69.7638 135.317C65.1464 131.695 58.657 129.884 50.2958 129.884H21.8424V171.47Z"/>
      <path d="M196.941 129.51V117.146C196.941 111.776 195.319 107.468 192.074 104.221C188.829 100.849 184.149 99.1631 178.034 99.1631C170.297 99.1631 163.371 102.098 157.256 107.967C151.141 113.712 146.274 122.516 142.655 134.38L140.034 115.273C142.78 108.155 146.087 102.223 149.955 97.4771C153.949 92.7316 158.504 89.1725 163.621 86.7997C168.862 84.302 174.603 83.0532 180.842 83.0532C191.2 83.0532 199.624 86.0504 206.113 92.0448C212.603 97.9142 215.847 106.281 215.847 117.146V129.51H196.941ZM127.305 188.329V85.3011H145.088L146.212 108.717V188.329H127.305Z"/>
      <path d="M317.566 188.329L316.817 164.914V120.893C316.817 113.525 314.384 107.78 309.517 103.659C304.775 99.4128 298.223 97.2898 289.862 97.2898C281.75 97.2898 275.136 99.1631 270.019 102.91C264.903 106.531 262.095 111.589 261.596 118.083H243.063C243.687 110.715 246.059 104.471 250.177 99.3504C254.295 94.1053 259.786 90.1091 266.65 87.3617C273.514 84.4894 281.438 83.0532 290.423 83.0532C299.658 83.0532 307.645 84.6142 314.384 87.7363C321.123 90.7335 326.302 95.1044 329.921 100.849C333.665 106.594 335.537 113.587 335.537 121.829V188.329H317.566ZM280.315 190.577C273.576 190.577 267.648 189.391 262.532 187.018C257.415 184.52 253.421 181.024 250.551 176.528C247.681 171.907 246.246 166.537 246.246 160.418C246.246 149.803 250.302 141.561 258.413 135.691C266.65 129.822 278.131 126.887 292.857 126.887H319.251V140.936H292.108C283.622 140.936 276.945 142.56 272.078 145.807C267.336 149.054 264.965 153.612 264.965 159.481C264.965 164.352 266.65 168.161 270.019 170.908C273.514 173.656 278.193 175.029 284.059 175.029C291.546 175.029 298.161 173.031 303.901 169.035C309.767 164.914 314.509 159.294 318.128 152.176L320.187 166.038C316.942 173.905 311.95 179.962 305.211 184.208C298.473 188.454 290.174 190.577 280.315 190.577Z"/>
      <path d="M440.063 188.329V123.328C440.063 116.085 438.004 110.278 433.886 105.907C429.768 101.411 424.402 99.1631 417.788 99.1631C410.175 99.1631 403.374 101.973 397.384 107.593C391.518 113.087 386.838 121.704 383.344 133.443L380.349 112.276C384.218 102.535 389.771 95.2293 397.009 90.3588C404.372 85.4884 412.359 83.0532 420.97 83.0532C428.707 83.0532 435.384 84.7391 440.999 88.1109C446.74 91.3579 451.17 95.9161 454.29 101.786C457.41 107.655 458.97 114.586 458.97 122.579V188.329H440.063ZM366.684 188.329V85.3011H384.654L385.59 108.529V188.329H366.684Z"/>
      <path d="M645.522 190.577C634.914 190.577 625.742 188.392 618.005 184.021C610.392 179.65 604.527 173.468 600.408 165.476C596.29 157.358 594.231 147.805 594.231 136.815C594.231 125.576 596.29 116.022 600.408 108.155C604.527 100.162 610.392 93.9804 618.005 89.6095C625.742 85.2386 634.977 83.0532 645.709 83.0532C661.683 83.0532 674.225 87.8612 683.335 97.4771C692.445 106.968 697 120.081 697 136.815C697 147.805 694.941 157.358 690.823 165.476C686.704 173.468 680.777 179.65 673.039 184.021C665.427 188.392 656.254 190.577 645.522 190.577ZM645.709 174.842C655.693 174.842 663.555 171.47 669.295 164.726C675.161 157.858 678.094 148.554 678.094 136.815C678.094 124.951 675.161 115.648 669.295 108.904C663.555 102.16 655.693 98.7884 645.709 98.7884C635.601 98.7884 627.676 102.16 621.936 108.904C616.195 115.648 613.325 124.951 613.325 136.815C613.325 148.554 616.195 157.858 621.936 164.726C627.676 171.47 635.601 174.842 645.709 174.842Z"/>
      </mask>
      <path d="M72.9461 119.769C77.6883 121.142 81.869 123.203 85.488 125.95C89.2319 128.698 92.1646 132.257 94.2861 136.628C96.5324 140.874 97.6556 145.869 97.6556 151.614C97.6556 158.982 95.7836 165.413 92.0398 170.908C88.4207 176.403 83.2417 180.711 76.5028 183.833C69.7638 186.831 61.7769 188.329 52.5421 188.329H2V57.5771H50.6701C64.3976 57.5771 75.13 60.5743 82.8673 66.5687C90.6046 72.5631 94.4733 80.4307 94.4733 90.1715C94.4733 94.7922 93.5997 99.1006 91.8526 103.097C90.2302 107.093 87.7967 110.527 84.5521 113.4C81.3074 116.272 77.4387 118.395 72.9461 119.769ZM49.7342 74.4363H21.8424V113.025H49.7342C57.0971 113.025 62.9001 111.214 67.1431 107.593C71.511 103.971 73.6949 99.1631 73.6949 93.1687C73.6949 87.2992 71.511 82.741 67.1431 79.494C62.7753 76.1222 56.9723 74.4363 49.7342 74.4363ZM21.8424 171.47H52.1677C59.7802 171.47 65.7704 169.597 70.1382 165.85C74.6308 162.104 76.8772 157.046 76.8772 150.677C76.8772 144.058 74.506 138.938 69.7638 135.317C65.1464 131.695 58.657 129.884 50.2958 129.884H21.8424V171.47Z" fill="#2C2738"/>
      <path d="M196.941 129.51V117.146C196.941 111.776 195.319 107.468 192.074 104.221C188.829 100.849 184.149 99.1631 178.034 99.1631C170.297 99.1631 163.371 102.098 157.256 107.967C151.141 113.712 146.274 122.516 142.655 134.38L140.034 115.273C142.78 108.155 146.087 102.223 149.955 97.4771C153.949 92.7316 158.504 89.1725 163.621 86.7997C168.862 84.302 174.603 83.0532 180.842 83.0532C191.2 83.0532 199.624 86.0504 206.113 92.0448C212.603 97.9142 215.847 106.281 215.847 117.146V129.51H196.941ZM127.305 188.329V85.3011H145.088L146.212 108.717V188.329H127.305Z" fill="#2C2738"/>
      <path d="M317.566 188.329L316.817 164.914V120.893C316.817 113.525 314.384 107.78 309.517 103.659C304.775 99.4128 298.223 97.2898 289.862 97.2898C281.75 97.2898 275.136 99.1631 270.019 102.91C264.903 106.531 262.095 111.589 261.596 118.083H243.063C243.687 110.715 246.059 104.471 250.177 99.3504C254.295 94.1053 259.786 90.1091 266.65 87.3617C273.514 84.4894 281.438 83.0532 290.423 83.0532C299.658 83.0532 307.645 84.6142 314.384 87.7363C321.123 90.7335 326.302 95.1044 329.921 100.849C333.665 106.594 335.537 113.587 335.537 121.829V188.329H317.566ZM280.315 190.577C273.576 190.577 267.648 189.391 262.532 187.018C257.415 184.52 253.421 181.024 250.551 176.528C247.681 171.907 246.246 166.537 246.246 160.418C246.246 149.803 250.302 141.561 258.413 135.691C266.65 129.822 278.131 126.887 292.857 126.887H319.251V140.936H292.108C283.622 140.936 276.945 142.56 272.078 145.807C267.336 149.054 264.965 153.612 264.965 159.481C264.965 164.352 266.65 168.161 270.019 170.908C273.514 173.656 278.193 175.029 284.059 175.029C291.546 175.029 298.161 173.031 303.901 169.035C309.767 164.914 314.509 159.294 318.128 152.176L320.187 166.038C316.942 173.905 311.95 179.962 305.211 184.208C298.473 188.454 290.174 190.577 280.315 190.577Z" fill="#2C2738"/>
      <path d="M440.063 188.329V123.328C440.063 116.085 438.004 110.278 433.886 105.907C429.768 101.411 424.402 99.1631 417.788 99.1631C410.175 99.1631 403.374 101.973 397.384 107.593C391.518 113.087 386.838 121.704 383.344 133.443L380.349 112.276C384.218 102.535 389.771 95.2293 397.009 90.3588C404.372 85.4884 412.359 83.0532 420.97 83.0532C428.707 83.0532 435.384 84.7391 440.999 88.1109C446.74 91.3579 451.17 95.9161 454.29 101.786C457.41 107.655 458.97 114.586 458.97 122.579V188.329H440.063ZM366.684 188.329V85.3011H384.654L385.59 108.529V188.329H366.684Z" fill="#2C2738"/>
      <path d="M645.522 190.577C634.914 190.577 625.742 188.392 618.005 184.021C610.392 179.65 604.527 173.468 600.408 165.476C596.29 157.358 594.231 147.805 594.231 136.815C594.231 125.576 596.29 116.022 600.408 108.155C604.527 100.162 610.392 93.9804 618.005 89.6095C625.742 85.2386 634.977 83.0532 645.709 83.0532C661.683 83.0532 674.225 87.8612 683.335 97.4771C692.445 106.968 697 120.081 697 136.815C697 147.805 694.941 157.358 690.823 165.476C686.704 173.468 680.777 179.65 673.039 184.021C665.427 188.392 656.254 190.577 645.522 190.577ZM645.709 174.842C655.693 174.842 663.555 171.47 669.295 164.726C675.161 157.858 678.094 148.554 678.094 136.815C678.094 124.951 675.161 115.648 669.295 108.904C663.555 102.16 655.693 98.7884 645.709 98.7884C635.601 98.7884 627.676 102.16 621.936 108.904C616.195 115.648 613.325 124.951 613.325 136.815C613.325 148.554 616.195 157.858 621.936 164.726C627.676 171.47 635.601 174.842 645.709 174.842Z" fill="#2C2738"/>
      <path d="M72.9461 119.769C77.6883 121.142 81.869 123.203 85.488 125.95C89.2319 128.698 92.1646 132.257 94.2861 136.628C96.5324 140.874 97.6556 145.869 97.6556 151.614C97.6556 158.982 95.7836 165.413 92.0398 170.908C88.4207 176.403 83.2417 180.711 76.5028 183.833C69.7638 186.831 61.7769 188.329 52.5421 188.329H2V57.5771H50.6701C64.3976 57.5771 75.13 60.5743 82.8673 66.5687C90.6046 72.5631 94.4733 80.4307 94.4733 90.1715C94.4733 94.7922 93.5997 99.1006 91.8526 103.097C90.2302 107.093 87.7967 110.527 84.5521 113.4C81.3074 116.272 77.4387 118.395 72.9461 119.769ZM49.7342 74.4363H21.8424V113.025H49.7342C57.0971 113.025 62.9001 111.214 67.1431 107.593C71.511 103.971 73.6949 99.1631 73.6949 93.1687C73.6949 87.2992 71.511 82.741 67.1431 79.494C62.7753 76.1222 56.9723 74.4363 49.7342 74.4363ZM21.8424 171.47H52.1677C59.7802 171.47 65.7704 169.597 70.1382 165.85C74.6308 162.104 76.8772 157.046 76.8772 150.677C76.8772 144.058 74.506 138.938 69.7638 135.317C65.1464 131.695 58.657 129.884 50.2958 129.884H21.8424V171.47Z" stroke="#FA9500" strokeWidth="4" mask="url(#path-2-outside-1_0_1)"/>
      <path d="M196.941 129.51V117.146C196.941 111.776 195.319 107.468 192.074 104.221C188.829 100.849 184.149 99.1631 178.034 99.1631C170.297 99.1631 163.371 102.098 157.256 107.967C151.141 113.712 146.274 122.516 142.655 134.38L140.034 115.273C142.78 108.155 146.087 102.223 149.955 97.4771C153.949 92.7316 158.504 89.1725 163.621 86.7997C168.862 84.302 174.603 83.0532 180.842 83.0532C191.2 83.0532 199.624 86.0504 206.113 92.0448C212.603 97.9142 215.847 106.281 215.847 117.146V129.51H196.941ZM127.305 188.329V85.3011H145.088L146.212 108.717V188.329H127.305Z" stroke="#FA9500" strokeWidth="4" mask="url(#path-2-outside-1_0_1)"/>
      <path d="M317.566 188.329L316.817 164.914V120.893C316.817 113.525 314.384 107.78 309.517 103.659C304.775 99.4128 298.223 97.2898 289.862 97.2898C281.75 97.2898 275.136 99.1631 270.019 102.91C264.903 106.531 262.095 111.589 261.596 118.083H243.063C243.687 110.715 246.059 104.471 250.177 99.3504C254.295 94.1053 259.786 90.1091 266.65 87.3617C273.514 84.4894 281.438 83.0532 290.423 83.0532C299.658 83.0532 307.645 84.6142 314.384 87.7363C321.123 90.7335 326.302 95.1044 329.921 100.849C333.665 106.594 335.537 113.587 335.537 121.829V188.329H317.566ZM280.315 190.577C273.576 190.577 267.648 189.391 262.532 187.018C257.415 184.52 253.421 181.024 250.551 176.528C247.681 171.907 246.246 166.537 246.246 160.418C246.246 149.803 250.302 141.561 258.413 135.691C266.65 129.822 278.131 126.887 292.857 126.887H319.251V140.936H292.108C283.622 140.936 276.945 142.56 272.078 145.807C267.336 149.054 264.965 153.612 264.965 159.481C264.965 164.352 266.65 168.161 270.019 170.908C273.514 173.656 278.193 175.029 284.059 175.029C291.546 175.029 298.161 173.031 303.901 169.035C309.767 164.914 314.509 159.294 318.128 152.176L320.187 166.038C316.942 173.905 311.95 179.962 305.211 184.208C298.473 188.454 290.174 190.577 280.315 190.577Z" stroke="#FA9500" strokeWidth="4" mask="url(#path-2-outside-1_0_1)"/>
      <path d="M440.063 188.329V123.328C440.063 116.085 438.004 110.278 433.886 105.907C429.768 101.411 424.402 99.1631 417.788 99.1631C410.175 99.1631 403.374 101.973 397.384 107.593C391.518 113.087 386.838 121.704 383.344 133.443L380.349 112.276C384.218 102.535 389.771 95.2293 397.009 90.3588C404.372 85.4884 412.359 83.0532 420.97 83.0532C428.707 83.0532 435.384 84.7391 440.999 88.1109C446.74 91.3579 451.17 95.9161 454.29 101.786C457.41 107.655 458.97 114.586 458.97 122.579V188.329H440.063ZM366.684 188.329V85.3011H384.654L385.59 108.529V188.329H366.684Z" stroke="#FA9500" strokeWidth="4" mask="url(#path-2-outside-1_0_1)"/>
      <path d="M645.522 190.577C634.914 190.577 625.742 188.392 618.005 184.021C610.392 179.65 604.527 173.468 600.408 165.476C596.29 157.358 594.231 147.805 594.231 136.815C594.231 125.576 596.29 116.022 600.408 108.155C604.527 100.162 610.392 93.9804 618.005 89.6095C625.742 85.2386 634.977 83.0532 645.709 83.0532C661.683 83.0532 674.225 87.8612 683.335 97.4771C692.445 106.968 697 120.081 697 136.815C697 147.805 694.941 157.358 690.823 165.476C686.704 173.468 680.777 179.65 673.039 184.021C665.427 188.392 656.254 190.577 645.522 190.577ZM645.709 174.842C655.693 174.842 663.555 171.47 669.295 164.726C675.161 157.858 678.094 148.554 678.094 136.815C678.094 124.951 675.161 115.648 669.295 108.904C663.555 102.16 655.693 98.7884 645.709 98.7884C635.601 98.7884 627.676 102.16 621.936 108.904C616.195 115.648 613.325 124.951 613.325 136.815C613.325 148.554 616.195 157.858 621.936 164.726C627.676 171.47 635.601 174.842 645.709 174.842Z" stroke="#FA9500" strokeWidth="4" mask="url(#path-2-outside-1_0_1)"/>
      <path d="M594.324 85.2119C594.029 83.3612 584.565 83.6712 578.148 83.5356C570.055 83.3645 563.294 76.0863 554.615 75.8102C516.756 74.606 491.983 97.7522 488.264 121.603C478.148 131.249 469.642 144.055 466.619 152.563C477.43 147.412 487.028 149.426 500.315 153.782C511.683 165.369 531.387 175.917 561.622 183.3C563.171 190.965 568.821 199.91 576.323 201.15C577.163 199.287 576.182 196.611 576.677 193.201C577.171 189.791 583.315 179.884 586.74 170.843C588.817 168.336 593.334 167.064 590.78 164.972C585.109 165.034 566.712 170.797 563.819 176.39C538.759 162.555 530.449 149.089 530.607 137.294C532.868 141.822 536.036 146.769 540.951 146.715C545.867 146.661 544.315 122.047 538.254 118.141C547.036 106.989 560.825 99.3485 565.437 97.4473C576.216 93.0032 583.467 91.4243 590.331 90.023C597.196 88.6217 594.62 87.0626 594.324 85.2119Z" fill="#2C2738"/>
      <path d="M488.264 121.603C491.983 97.7522 516.756 74.606 554.615 75.8102C563.294 76.0863 570.055 83.3645 578.148 83.5356C584.565 83.6712 594.029 83.3612 594.324 85.2119C594.62 87.0626 597.196 88.6217 590.331 90.023C583.467 91.4243 576.216 93.0032 565.437 97.4473C560.825 99.3485 547.036 106.989 538.254 118.141M488.264 121.603C478.148 131.249 469.642 144.055 466.619 152.563C477.43 147.412 487.028 149.426 500.315 153.782M488.264 121.603C486.622 132.14 490.109 143.38 500.315 153.782M500.315 153.782C511.683 165.369 531.387 175.917 561.622 183.3C563.171 190.965 568.821 199.91 576.323 201.15C577.163 199.287 576.182 196.611 576.677 193.201C577.171 189.791 583.315 179.884 586.74 170.843C588.817 168.336 593.334 167.064 590.78 164.972C585.109 165.034 566.712 170.797 563.819 176.39C538.759 162.555 530.449 149.089 530.607 137.294M538.254 118.141C544.315 122.047 545.867 146.661 540.951 146.715C536.036 146.769 532.868 141.822 530.607 137.294M538.254 118.141C533.848 123.735 530.701 130.214 530.607 137.294" stroke="#2C2738" strokeWidth="1"/>
      <rect x="369" y="57" width="14" height="14" fill="#2C2738" stroke="#FA9500" strokeWidth="1"/>
      </svg>
    </SvgIcon>
  );
}

export function BrancoLogoMobileWhite() {
  return (
    <SvgIcon sx={{ height: 26, width: 72 }}>
      <svg width="723" height="267" viewBox="0 0 723 267" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M436.475 84.1759C480.069 12.7068 573.98 -15.7098 652.002 8.87657C730.025 33.4629 734.194 87.7458 709.658 165.606C702.181 189.336 695.903 209.258 686.555 224.972C665.23 260.817 627.928 274.766 523.961 262.066C354.001 241.304 367.384 197.446 436.475 84.1759Z" fill="#FA9500"/>
      <mask id="path-2-outside-1_0_1" maskUnits="userSpaceOnUse" x="0" y="55.5771" width="699" height="137" fill="black">
      <rect fill="white" y="55.5771" width="699" height="137"/>
      <path d="M72.9461 119.769C77.6883 121.142 81.869 123.203 85.488 125.95C89.2319 128.698 92.1646 132.257 94.2861 136.628C96.5324 140.874 97.6556 145.869 97.6556 151.614C97.6556 158.982 95.7836 165.413 92.0398 170.908C88.4207 176.403 83.2417 180.711 76.5028 183.833C69.7638 186.831 61.7769 188.329 52.5421 188.329H2V57.5771H50.6701C64.3976 57.5771 75.13 60.5743 82.8673 66.5687C90.6046 72.5631 94.4733 80.4307 94.4733 90.1715C94.4733 94.7922 93.5997 99.1006 91.8526 103.097C90.2302 107.093 87.7967 110.527 84.5521 113.4C81.3074 116.272 77.4387 118.395 72.9461 119.769ZM49.7342 74.4363H21.8424V113.025H49.7342C57.0971 113.025 62.9001 111.214 67.1431 107.593C71.511 103.971 73.6949 99.1631 73.6949 93.1687C73.6949 87.2992 71.511 82.741 67.1431 79.494C62.7753 76.1222 56.9723 74.4363 49.7342 74.4363ZM21.8424 171.47H52.1677C59.7802 171.47 65.7704 169.597 70.1382 165.85C74.6308 162.104 76.8772 157.046 76.8772 150.677C76.8772 144.058 74.506 138.938 69.7638 135.317C65.1464 131.695 58.657 129.884 50.2958 129.884H21.8424V171.47Z"/>
      <path d="M196.941 129.51V117.146C196.941 111.776 195.319 107.468 192.074 104.221C188.829 100.849 184.149 99.1631 178.034 99.1631C170.297 99.1631 163.371 102.098 157.256 107.967C151.141 113.712 146.274 122.516 142.655 134.38L140.034 115.273C142.78 108.155 146.087 102.223 149.955 97.4771C153.949 92.7316 158.504 89.1725 163.621 86.7997C168.862 84.302 174.603 83.0532 180.842 83.0532C191.2 83.0532 199.624 86.0504 206.113 92.0448C212.603 97.9142 215.847 106.281 215.847 117.146V129.51H196.941ZM127.305 188.329V85.3011H145.088L146.212 108.717V188.329H127.305Z"/>
      <path d="M317.566 188.329L316.817 164.914V120.893C316.817 113.525 314.384 107.78 309.517 103.659C304.775 99.4128 298.223 97.2898 289.862 97.2898C281.75 97.2898 275.136 99.1631 270.019 102.91C264.903 106.531 262.095 111.589 261.596 118.083H243.063C243.687 110.715 246.059 104.471 250.177 99.3504C254.295 94.1053 259.786 90.1091 266.65 87.3617C273.514 84.4894 281.438 83.0532 290.423 83.0532C299.658 83.0532 307.645 84.6142 314.384 87.7363C321.123 90.7335 326.302 95.1044 329.921 100.849C333.665 106.594 335.537 113.587 335.537 121.829V188.329H317.566ZM280.315 190.577C273.576 190.577 267.648 189.391 262.532 187.018C257.415 184.52 253.421 181.024 250.551 176.528C247.681 171.907 246.246 166.537 246.246 160.418C246.246 149.803 250.302 141.561 258.413 135.691C266.65 129.822 278.131 126.887 292.857 126.887H319.251V140.936H292.108C283.622 140.936 276.945 142.56 272.078 145.807C267.336 149.054 264.965 153.612 264.965 159.481C264.965 164.352 266.65 168.161 270.019 170.908C273.514 173.656 278.193 175.029 284.059 175.029C291.546 175.029 298.161 173.031 303.901 169.035C309.767 164.914 314.509 159.294 318.128 152.176L320.187 166.038C316.942 173.905 311.95 179.962 305.211 184.208C298.473 188.454 290.174 190.577 280.315 190.577Z"/>
      <path d="M440.063 188.329V123.328C440.063 116.085 438.004 110.278 433.886 105.907C429.768 101.411 424.402 99.1631 417.788 99.1631C410.175 99.1631 403.374 101.973 397.384 107.593C391.518 113.087 386.838 121.704 383.344 133.443L380.349 112.276C384.218 102.535 389.771 95.2293 397.009 90.3588C404.372 85.4884 412.359 83.0532 420.97 83.0532C428.707 83.0532 435.384 84.7391 440.999 88.1109C446.74 91.3579 451.17 95.9161 454.29 101.786C457.41 107.655 458.97 114.586 458.97 122.579V188.329H440.063ZM366.684 188.329V85.3011H384.654L385.59 108.529V188.329H366.684Z"/>
      <path d="M645.522 190.577C634.914 190.577 625.742 188.392 618.005 184.021C610.392 179.65 604.527 173.468 600.408 165.476C596.29 157.358 594.231 147.805 594.231 136.815C594.231 125.576 596.29 116.022 600.408 108.155C604.527 100.162 610.392 93.9804 618.005 89.6095C625.742 85.2386 634.977 83.0532 645.709 83.0532C661.683 83.0532 674.225 87.8612 683.335 97.4771C692.445 106.968 697 120.081 697 136.815C697 147.805 694.941 157.358 690.823 165.476C686.704 173.468 680.777 179.65 673.039 184.021C665.427 188.392 656.254 190.577 645.522 190.577ZM645.709 174.842C655.693 174.842 663.555 171.47 669.295 164.726C675.161 157.858 678.094 148.554 678.094 136.815C678.094 124.951 675.161 115.648 669.295 108.904C663.555 102.16 655.693 98.7884 645.709 98.7884C635.601 98.7884 627.676 102.16 621.936 108.904C616.195 115.648 613.325 124.951 613.325 136.815C613.325 148.554 616.195 157.858 621.936 164.726C627.676 171.47 635.601 174.842 645.709 174.842Z"/>
      </mask>
      <path d="M72.9461 119.769C77.6883 121.142 81.869 123.203 85.488 125.95C89.2319 128.698 92.1646 132.257 94.2861 136.628C96.5324 140.874 97.6556 145.869 97.6556 151.614C97.6556 158.982 95.7836 165.413 92.0398 170.908C88.4207 176.403 83.2417 180.711 76.5028 183.833C69.7638 186.831 61.7769 188.329 52.5421 188.329H2V57.5771H50.6701C64.3976 57.5771 75.13 60.5743 82.8673 66.5687C90.6046 72.5631 94.4733 80.4307 94.4733 90.1715C94.4733 94.7922 93.5997 99.1006 91.8526 103.097C90.2302 107.093 87.7967 110.527 84.5521 113.4C81.3074 116.272 77.4387 118.395 72.9461 119.769ZM49.7342 74.4363H21.8424V113.025H49.7342C57.0971 113.025 62.9001 111.214 67.1431 107.593C71.511 103.971 73.6949 99.1631 73.6949 93.1687C73.6949 87.2992 71.511 82.741 67.1431 79.494C62.7753 76.1222 56.9723 74.4363 49.7342 74.4363ZM21.8424 171.47H52.1677C59.7802 171.47 65.7704 169.597 70.1382 165.85C74.6308 162.104 76.8772 157.046 76.8772 150.677C76.8772 144.058 74.506 138.938 69.7638 135.317C65.1464 131.695 58.657 129.884 50.2958 129.884H21.8424V171.47Z" fill="#fff"/>
      <path d="M196.941 129.51V117.146C196.941 111.776 195.319 107.468 192.074 104.221C188.829 100.849 184.149 99.1631 178.034 99.1631C170.297 99.1631 163.371 102.098 157.256 107.967C151.141 113.712 146.274 122.516 142.655 134.38L140.034 115.273C142.78 108.155 146.087 102.223 149.955 97.4771C153.949 92.7316 158.504 89.1725 163.621 86.7997C168.862 84.302 174.603 83.0532 180.842 83.0532C191.2 83.0532 199.624 86.0504 206.113 92.0448C212.603 97.9142 215.847 106.281 215.847 117.146V129.51H196.941ZM127.305 188.329V85.3011H145.088L146.212 108.717V188.329H127.305Z" fill="#fff"/>
      <path d="M317.566 188.329L316.817 164.914V120.893C316.817 113.525 314.384 107.78 309.517 103.659C304.775 99.4128 298.223 97.2898 289.862 97.2898C281.75 97.2898 275.136 99.1631 270.019 102.91C264.903 106.531 262.095 111.589 261.596 118.083H243.063C243.687 110.715 246.059 104.471 250.177 99.3504C254.295 94.1053 259.786 90.1091 266.65 87.3617C273.514 84.4894 281.438 83.0532 290.423 83.0532C299.658 83.0532 307.645 84.6142 314.384 87.7363C321.123 90.7335 326.302 95.1044 329.921 100.849C333.665 106.594 335.537 113.587 335.537 121.829V188.329H317.566ZM280.315 190.577C273.576 190.577 267.648 189.391 262.532 187.018C257.415 184.52 253.421 181.024 250.551 176.528C247.681 171.907 246.246 166.537 246.246 160.418C246.246 149.803 250.302 141.561 258.413 135.691C266.65 129.822 278.131 126.887 292.857 126.887H319.251V140.936H292.108C283.622 140.936 276.945 142.56 272.078 145.807C267.336 149.054 264.965 153.612 264.965 159.481C264.965 164.352 266.65 168.161 270.019 170.908C273.514 173.656 278.193 175.029 284.059 175.029C291.546 175.029 298.161 173.031 303.901 169.035C309.767 164.914 314.509 159.294 318.128 152.176L320.187 166.038C316.942 173.905 311.95 179.962 305.211 184.208C298.473 188.454 290.174 190.577 280.315 190.577Z" fill="#fff"/>
      <path d="M440.063 188.329V123.328C440.063 116.085 438.004 110.278 433.886 105.907C429.768 101.411 424.402 99.1631 417.788 99.1631C410.175 99.1631 403.374 101.973 397.384 107.593C391.518 113.087 386.838 121.704 383.344 133.443L380.349 112.276C384.218 102.535 389.771 95.2293 397.009 90.3588C404.372 85.4884 412.359 83.0532 420.97 83.0532C428.707 83.0532 435.384 84.7391 440.999 88.1109C446.74 91.3579 451.17 95.9161 454.29 101.786C457.41 107.655 458.97 114.586 458.97 122.579V188.329H440.063ZM366.684 188.329V85.3011H384.654L385.59 108.529V188.329H366.684Z" fill="#fff"/>
      <path d="M645.522 190.577C634.914 190.577 625.742 188.392 618.005 184.021C610.392 179.65 604.527 173.468 600.408 165.476C596.29 157.358 594.231 147.805 594.231 136.815C594.231 125.576 596.29 116.022 600.408 108.155C604.527 100.162 610.392 93.9804 618.005 89.6095C625.742 85.2386 634.977 83.0532 645.709 83.0532C661.683 83.0532 674.225 87.8612 683.335 97.4771C692.445 106.968 697 120.081 697 136.815C697 147.805 694.941 157.358 690.823 165.476C686.704 173.468 680.777 179.65 673.039 184.021C665.427 188.392 656.254 190.577 645.522 190.577ZM645.709 174.842C655.693 174.842 663.555 171.47 669.295 164.726C675.161 157.858 678.094 148.554 678.094 136.815C678.094 124.951 675.161 115.648 669.295 108.904C663.555 102.16 655.693 98.7884 645.709 98.7884C635.601 98.7884 627.676 102.16 621.936 108.904C616.195 115.648 613.325 124.951 613.325 136.815C613.325 148.554 616.195 157.858 621.936 164.726C627.676 171.47 635.601 174.842 645.709 174.842Z" fill="#fff"/>
      <path d="M72.9461 119.769C77.6883 121.142 81.869 123.203 85.488 125.95C89.2319 128.698 92.1646 132.257 94.2861 136.628C96.5324 140.874 97.6556 145.869 97.6556 151.614C97.6556 158.982 95.7836 165.413 92.0398 170.908C88.4207 176.403 83.2417 180.711 76.5028 183.833C69.7638 186.831 61.7769 188.329 52.5421 188.329H2V57.5771H50.6701C64.3976 57.5771 75.13 60.5743 82.8673 66.5687C90.6046 72.5631 94.4733 80.4307 94.4733 90.1715C94.4733 94.7922 93.5997 99.1006 91.8526 103.097C90.2302 107.093 87.7967 110.527 84.5521 113.4C81.3074 116.272 77.4387 118.395 72.9461 119.769ZM49.7342 74.4363H21.8424V113.025H49.7342C57.0971 113.025 62.9001 111.214 67.1431 107.593C71.511 103.971 73.6949 99.1631 73.6949 93.1687C73.6949 87.2992 71.511 82.741 67.1431 79.494C62.7753 76.1222 56.9723 74.4363 49.7342 74.4363ZM21.8424 171.47H52.1677C59.7802 171.47 65.7704 169.597 70.1382 165.85C74.6308 162.104 76.8772 157.046 76.8772 150.677C76.8772 144.058 74.506 138.938 69.7638 135.317C65.1464 131.695 58.657 129.884 50.2958 129.884H21.8424V171.47Z" stroke="#FA9500" strokeWidth="4" mask="url(#path-2-outside-1_0_1)"/>
      <path d="M196.941 129.51V117.146C196.941 111.776 195.319 107.468 192.074 104.221C188.829 100.849 184.149 99.1631 178.034 99.1631C170.297 99.1631 163.371 102.098 157.256 107.967C151.141 113.712 146.274 122.516 142.655 134.38L140.034 115.273C142.78 108.155 146.087 102.223 149.955 97.4771C153.949 92.7316 158.504 89.1725 163.621 86.7997C168.862 84.302 174.603 83.0532 180.842 83.0532C191.2 83.0532 199.624 86.0504 206.113 92.0448C212.603 97.9142 215.847 106.281 215.847 117.146V129.51H196.941ZM127.305 188.329V85.3011H145.088L146.212 108.717V188.329H127.305Z" stroke="#FA9500" strokeWidth="4" mask="url(#path-2-outside-1_0_1)"/>
      <path d="M317.566 188.329L316.817 164.914V120.893C316.817 113.525 314.384 107.78 309.517 103.659C304.775 99.4128 298.223 97.2898 289.862 97.2898C281.75 97.2898 275.136 99.1631 270.019 102.91C264.903 106.531 262.095 111.589 261.596 118.083H243.063C243.687 110.715 246.059 104.471 250.177 99.3504C254.295 94.1053 259.786 90.1091 266.65 87.3617C273.514 84.4894 281.438 83.0532 290.423 83.0532C299.658 83.0532 307.645 84.6142 314.384 87.7363C321.123 90.7335 326.302 95.1044 329.921 100.849C333.665 106.594 335.537 113.587 335.537 121.829V188.329H317.566ZM280.315 190.577C273.576 190.577 267.648 189.391 262.532 187.018C257.415 184.52 253.421 181.024 250.551 176.528C247.681 171.907 246.246 166.537 246.246 160.418C246.246 149.803 250.302 141.561 258.413 135.691C266.65 129.822 278.131 126.887 292.857 126.887H319.251V140.936H292.108C283.622 140.936 276.945 142.56 272.078 145.807C267.336 149.054 264.965 153.612 264.965 159.481C264.965 164.352 266.65 168.161 270.019 170.908C273.514 173.656 278.193 175.029 284.059 175.029C291.546 175.029 298.161 173.031 303.901 169.035C309.767 164.914 314.509 159.294 318.128 152.176L320.187 166.038C316.942 173.905 311.95 179.962 305.211 184.208C298.473 188.454 290.174 190.577 280.315 190.577Z" stroke="#FA9500" strokeWidth="4" mask="url(#path-2-outside-1_0_1)"/>
      <path d="M440.063 188.329V123.328C440.063 116.085 438.004 110.278 433.886 105.907C429.768 101.411 424.402 99.1631 417.788 99.1631C410.175 99.1631 403.374 101.973 397.384 107.593C391.518 113.087 386.838 121.704 383.344 133.443L380.349 112.276C384.218 102.535 389.771 95.2293 397.009 90.3588C404.372 85.4884 412.359 83.0532 420.97 83.0532C428.707 83.0532 435.384 84.7391 440.999 88.1109C446.74 91.3579 451.17 95.9161 454.29 101.786C457.41 107.655 458.97 114.586 458.97 122.579V188.329H440.063ZM366.684 188.329V85.3011H384.654L385.59 108.529V188.329H366.684Z" stroke="#FA9500" strokeWidth="4" mask="url(#path-2-outside-1_0_1)"/>
      <path d="M645.522 190.577C634.914 190.577 625.742 188.392 618.005 184.021C610.392 179.65 604.527 173.468 600.408 165.476C596.29 157.358 594.231 147.805 594.231 136.815C594.231 125.576 596.29 116.022 600.408 108.155C604.527 100.162 610.392 93.9804 618.005 89.6095C625.742 85.2386 634.977 83.0532 645.709 83.0532C661.683 83.0532 674.225 87.8612 683.335 97.4771C692.445 106.968 697 120.081 697 136.815C697 147.805 694.941 157.358 690.823 165.476C686.704 173.468 680.777 179.65 673.039 184.021C665.427 188.392 656.254 190.577 645.522 190.577ZM645.709 174.842C655.693 174.842 663.555 171.47 669.295 164.726C675.161 157.858 678.094 148.554 678.094 136.815C678.094 124.951 675.161 115.648 669.295 108.904C663.555 102.16 655.693 98.7884 645.709 98.7884C635.601 98.7884 627.676 102.16 621.936 108.904C616.195 115.648 613.325 124.951 613.325 136.815C613.325 148.554 616.195 157.858 621.936 164.726C627.676 171.47 635.601 174.842 645.709 174.842Z" stroke="#FA9500" strokeWidth="4" mask="url(#path-2-outside-1_0_1)"/>
      <path d="M594.324 85.2119C594.029 83.3612 584.565 83.6712 578.148 83.5356C570.055 83.3645 563.294 76.0863 554.615 75.8102C516.756 74.606 491.983 97.7522 488.264 121.603C478.148 131.249 469.642 144.055 466.619 152.563C477.43 147.412 487.028 149.426 500.315 153.782C511.683 165.369 531.387 175.917 561.622 183.3C563.171 190.965 568.821 199.91 576.323 201.15C577.163 199.287 576.182 196.611 576.677 193.201C577.171 189.791 583.315 179.884 586.74 170.843C588.817 168.336 593.334 167.064 590.78 164.972C585.109 165.034 566.712 170.797 563.819 176.39C538.759 162.555 530.449 149.089 530.607 137.294C532.868 141.822 536.036 146.769 540.951 146.715C545.867 146.661 544.315 122.047 538.254 118.141C547.036 106.989 560.825 99.3485 565.437 97.4473C576.216 93.0032 583.467 91.4243 590.331 90.023C597.196 88.6217 594.62 87.0626 594.324 85.2119Z" fill="#fff"/>
      <path d="M488.264 121.603C491.983 97.7522 516.756 74.606 554.615 75.8102C563.294 76.0863 570.055 83.3645 578.148 83.5356C584.565 83.6712 594.029 83.3612 594.324 85.2119C594.62 87.0626 597.196 88.6217 590.331 90.023C583.467 91.4243 576.216 93.0032 565.437 97.4473C560.825 99.3485 547.036 106.989 538.254 118.141M488.264 121.603C478.148 131.249 469.642 144.055 466.619 152.563C477.43 147.412 487.028 149.426 500.315 153.782M488.264 121.603C486.622 132.14 490.109 143.38 500.315 153.782M500.315 153.782C511.683 165.369 531.387 175.917 561.622 183.3C563.171 190.965 568.821 199.91 576.323 201.15C577.163 199.287 576.182 196.611 576.677 193.201C577.171 189.791 583.315 179.884 586.74 170.843C588.817 168.336 593.334 167.064 590.78 164.972C585.109 165.034 566.712 170.797 563.819 176.39C538.759 162.555 530.449 149.089 530.607 137.294M538.254 118.141C544.315 122.047 545.867 146.661 540.951 146.715C536.036 146.769 532.868 141.822 530.607 137.294M538.254 118.141C533.848 123.735 530.701 130.214 530.607 137.294" stroke="#ff" fill="#fff" strokeWidth="1"/>
      <rect x="369" y="57" width="14" height="14" fill="#fff" stroke="#FA9500" strokeWidth="1"/>
      </svg>
    </SvgIcon>
  );
}


export function FacebookIcon() {
  return (
    <SvgIcon>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.68 15.92C2.88 15.24 0 11.96 0 8C0 3.6 3.6 0 8 0C12.4 0 16 3.6 16 8C16 11.96 13.12 15.24 9.32 15.92L8.88 15.56H7.12L6.68 15.92Z"
          fill="url(#paint0_linear_795_116)"
        />
        <path
          d="M11.12 10.2391L11.48 7.99914H9.36V6.43914C9.36 5.79914 9.6 5.31914 10.56 5.31914H11.6V3.27914C11.04 3.19914 10.4 3.11914 9.84 3.11914C8 3.11914 6.72 4.23914 6.72 6.23914V7.99914H4.72V10.2391H6.72V15.8791C7.16 15.9591 7.6 15.9991 8.04 15.9991C8.48 15.9991 8.92 15.9591 9.36 15.8791V10.2391H11.12Z"
          fill="white"
        />
        <defs>
          <linearGradient
            id="paint0_linear_795_116"
            x1="8"
            y1="0"
            x2="8"
            y2="15.9991"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#1AAFFF" />
            <stop offset="1" stopColor="#0163E0" />
          </linearGradient>
        </defs>
      </svg>
    </SvgIcon>
  );
}

export function GoogleIcon() {
  return (
    <SvgIcon>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.68 8.18182C15.68 7.61455 15.6291 7.06909 15.5345 6.54545H8V9.64364H12.3055C12.1164 10.64 11.5491 11.4836 10.6982 12.0509V14.0655H13.2945C14.8073 12.6691 15.68 10.6182 15.68 8.18182Z"
          fill="#4285F4"
        />
        <path
          d="M8 16C10.16 16 11.9709 15.2873 13.2945 14.0655L10.6982 12.0509C9.98545 12.5309 9.07636 12.8218 8 12.8218C5.92 12.8218 4.15273 11.4182 3.52 9.52727H0.858182V11.5927C2.17455 14.2036 4.87273 16 8 16Z"
          fill="#34A853"
        />
        <path
          d="M3.52 9.52C3.36 9.04 3.26545 8.53091 3.26545 8C3.26545 7.46909 3.36 6.96 3.52 6.48V4.41455H0.858182C0.312727 5.49091 0 6.70545 0 8C0 9.29455 0.312727 10.5091 0.858182 11.5855L2.93091 9.97091L3.52 9.52Z"
          fill="#FBBC05"
        />
        <path
          d="M8 3.18545C9.17818 3.18545 10.2255 3.59273 11.0618 4.37818L13.3527 2.08727C11.9636 0.792727 10.16 0 8 0C4.87273 0 2.17455 1.79636 0.858182 4.41455L3.52 6.48C4.15273 4.58909 5.92 3.18545 8 3.18545Z"
          fill="#EA4335"
        />
      </svg>
    </SvgIcon>
  );
}
