import { useRouteError, useNavigate } from 'react-router-dom';
import { Player } from '@lottiefiles/react-lottie-player';

import { Box, Button, Grid2, Typography } from "@mui/material";

import { PathConstants } from '../utils/nav';
import { PageHeader } from './_PageHeader';


type RouteError = {
    statusText?: string,
    message?: string,
  }

export const ErrorPage = () => {     
    const error = useRouteError() as RouteError
    console.log(error)

    const navigate = useNavigate();

    const ctaClick = (route: string) => {
        navigate(route);
    };

    const animation404 = "/static/lotties/not-found.json";

    return (
        <Grid2
        width='100%'
        container
        spacing={3}
        columns={12}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        padding={3} >
            <Grid2 size={{ xs: 12 }}>
                <PageHeader
                    title="Ooops, we can't find what you're looking for"
                    subtitle="We have the details in our logs." 
                    subtitle2="" 
                />
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
                <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    onClick={() => ctaClick(PathConstants.LANDING)}
                >
                    Return to Branco
                </Button>
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
                <Player
                    src={animation404}
                    className="player"
                    loop
                    autoplay
                    style={{ height: '60%', width: '60%' }}
                />            
            </Grid2>
        </Grid2>
    );
};