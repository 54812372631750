import * as React from 'react';
import { useEffect, useContext } from "react";
import { styled } from '@mui/material/styles';

import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
//import { Button, IconButton, Popover, Slider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import { Slider, Tooltip } from '@mui/material';
import Grid from '@mui/material/Grid2';

import ModeStandbyOutlinedIcon from "@mui/icons-material/ModeStandbyOutlined";
import EmergencyOutlinedIcon from '@mui/icons-material/EmergencyOutlined';
/*
import Stack from '@mui/material/Stack';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutral';
import ThumbDownOutlinedIcon from "@mui/icons-material/ThumbDownOutlined";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
*/
import { UserContext } from "../../utils/context.js";

import { getTeamGoals } from "../../services/api/TeamGoals";
import { getUserRatings } from '../../services/api/Dashboard';

type KpiMapper = {
  kpi_id: number;
  kpi_name: string;
  kpi_parent_goal_name: string;
  kpi_weight?: number | null;
};

const TEAM_GOALS_EXPECTED_RATING = 88;
const RANGE_WIDTH = 10;

export default function ExpectationsTeamImpact() {
  const [user] = useContext(UserContext);

  const [kpis, setKpis] = React.useState<KpiMapper[]>([]);
  const [strengthsopps, setUserRatings] = React.useState<StrengthOpportunityInfo[]>([]);

  /*
  const [selected_competency_name, setSelectedCompetencyName] = React.useState<string>("");
  const [selected_competency_description, setSelectedCompetencyDescription] = React.useState<string>("");
  const [selected_competency_ratings, setSelectedCompetencyRatings] = React.useState<CompetencyRating[]>([]);
  */

  const getUserTeamGoalsRating = (kpi_id: number) => {
    const foundKpi = strengthsopps.find(
      (strengthsopps_details:StrengthOpportunityInfo) => strengthsopps_details.kpi_id === kpi_id
    );
    return foundKpi ? foundKpi.average_rating : -1; 
  };
  const getUserTeamGoalsRatingDisplay = (prefix: string, kpi_id: number, expected_rating: number) => {
    const rating = getUserTeamGoalsRating(kpi_id);
    var ratingDisplay = "";
    if (rating > 0){
      if (rating < (expected_rating - (RANGE_WIDTH * 2))) {
        ratingDisplay += "below expectations";
      }
      else if (rating < (expected_rating - RANGE_WIDTH)) {
        ratingDisplay += "approaching expectations";
      }
      else if (rating > (expected_rating + RANGE_WIDTH)) {
        ratingDisplay += "exceeding expectations";
      }
      else {
        ratingDisplay += "meeting expectations";
      }
    }
    if (ratingDisplay) 
      ratingDisplay = prefix + ratingDisplay;

    return ratingDisplay; 
  };

  const getUserTeamGoalsFeedbackCount = (kpi_id: number) => {
    const foundKpi = strengthsopps.find(
      (strengthsopps_details:StrengthOpportunityInfo) => strengthsopps_details.kpi_id === kpi_id
    );
    return foundKpi ? foundKpi.feedback_count : 0; 
  };
  const getUserTeamGoalsFeedbackCountDisplay = (kpi_id: number) => {
    const count = getUserTeamGoalsFeedbackCount(kpi_id);
    var feedbackDisplayCount = "";
    switch(count) {
      case 0: {
        feedbackDisplayCount = "no feedback yet";
        break;
      }
      case 1: {
        feedbackDisplayCount = count + " feedback";
        break;
      }
      default: {
        feedbackDisplayCount = count + " feedbacks";
      }
    }
    return feedbackDisplayCount; 
  };


  const fetchUserRatingsAndFilterTeamImpact = async (user_id: number, org_id: number) => {
    await getUserRatings(user_id).then((results) => {
      const all_results = results as StrengthOpportunityInfo[] || [];
      let team_result = all_results.filter(function (el) {
        return el.kpi_id;
        }
      );
      // Fetchinng goals and filtering only rated
      fetchGoals(org_id, team_result);

      setUserRatings(team_result);
    }).catch((err) => {
      console.error("Failed to fetch user ratings (this include fetching goals and kpis): ", err);
    });
  };
  
  const fetchGoals = async (org_id: number, user_rated_kpis: StrengthOpportunityInfo[]) => {
    return await getTeamGoals(org_id, "").then((value) => {
      const goals = value as TeamGoalInfo[];
      const kpiMapper: KpiMapper[] = [];
      for (let i = 0; i < goals.length; i++) {
        if (goals[i].goal_kpis)
          for (let j = 0; j < goals[i].goal_kpis!.length; j++) {
            const foundKpi = user_rated_kpis.find(
              (user_rated_kpi:StrengthOpportunityInfo) => user_rated_kpi.kpi_id === goals[i].goal_kpis![j].kpi_id!
            );
            if (foundKpi)
              kpiMapper.push({
                kpi_id: goals[i].goal_kpis![j].kpi_id!,
                kpi_parent_goal_name: goals[i].goal_description,
                kpi_weight: goals[i].goal_kpis![j].kpi_weight,
                kpi_name: goals[i].goal_kpis![j].kpi_name
              });
          }
      }
      kpiMapper.sort((a, b) => {
        if (a.kpi_weight && b.kpi_weight) {
          if (a.kpi_weight > b.kpi_weight) {
            return -1;
          }
          if (a.kpi_weight < b.kpi_weight) {
              return 1;
          }
          return 0;
        }
        return 1;
      });
      setKpis(kpiMapper);
    });
};


  useEffect(() => {
      try {
        if (user && user.org_id){
          fetchUserRatingsAndFilterTeamImpact(user.user_id, user.org_id);
        }
      } catch (err) {
        console.error("Failed to retrieve team objectives expectations. ", err);
      }
    }, [user]);


const StyledSlider = styled(Slider)(({ theme }) => ({
  '& .MuiSlider-rail': {
    height: '10px',
    borderRadius: '4px',
  },
  '& .MuiSlider-track': {
    backgroundColor: theme.palette.success.main, // Expected range color
    borderColor: theme.palette.success.main, // Expected range color
    height: '10px',
    borderRadius: '4px',
    opacity: 0.6,
  },
  '& .MuiSlider-thumb': {
    width: '0px',
    height: '0px',
    backgroundColor: theme.palette.success.main, // Highlighted user rating
    opacity: 0.6,
  },
  '& .MuiSlider-mark': {
    backgroundColor: theme.palette.primary.main, // Actual rating marker
    width: '10px',
    height: '20px',
    borderRadius: '30%',
    boxShadow: `0 0 1px ${theme.palette.primary.main}`,
    '&:hover': {
      transform: 'scale(1.2)',
    },
  },
  '& .MuiSlider-markActive': {
    backgroundColor: theme.palette.primary.main,
    boxShadow: `0 0 5px ${theme.palette.primary.main}`,
  },
}));


  return (
    <Card key="career-team-imapact-card"
      variant="outlined"
      sx={{ display: 'flex', flexDirection: 'column', gap: '8px', flexGrow: 1 }}
    >
      {kpis && kpis.length > 0 && (
        <CardContent>
          <Typography component="h2" variant="h6" sx={{ mb: 1 }}>
            <ModeStandbyOutlinedIcon /> Impact on team objectives
          </Typography>
          <Typography sx={{ color: 'text.secondary', mb: '8px'  }}>
            How your teammates see your impact on the team work you're doing together.
          </Typography>
          {kpis.map((kpi:KpiMapper) => (
            <Grid container   
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Grid size={1}>
              {kpi.kpi_weight && kpi.kpi_weight > 25 &&
                <Tooltip title="High stake" arrow>
                  <EmergencyOutlinedIcon />
                </Tooltip>
              }
            </Grid>
            <Grid size={6}>
              <Tooltip title={"Part of the goal: " + kpi.kpi_parent_goal_name} arrow>
                <Typography>
                  {kpi.kpi_name}
                </Typography>
              </Tooltip>
            </Grid>
            <Grid size={5} sx={{textAlign: 'right'}}>
                <Typography variant="body2">
                  {getUserTeamGoalsFeedbackCountDisplay(kpi.kpi_id)}
                  {getUserTeamGoalsRatingDisplay(": ", kpi.kpi_id, TEAM_GOALS_EXPECTED_RATING)}
                </Typography>
            </Grid>
            <Grid size={1}>
            </Grid>
            <Grid size={11}>
                <StyledSlider
                  getAriaLabel={() => 'Expected rating at level'}
                  disabled
                  value={[TEAM_GOALS_EXPECTED_RATING - RANGE_WIDTH, TEAM_GOALS_EXPECTED_RATING + RANGE_WIDTH]}
                  marks={[
                          {
                            value: getUserTeamGoalsRating(kpi.kpi_id)
                          },
                        ]}
                  valueLabelDisplay="auto"
                  min={0}
                  max={125}
                  //getAriaValueText={valuetext}
                />
            </Grid>
            <Grid size={12} padding={1}>
            </Grid>
          </Grid>
          ))}
        </CardContent>
      )}
    </Card>
  );
}