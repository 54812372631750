import React, { useState, useEffect, useContext } from "react";
import { Box, Grid2, FormControlLabel, Switch, Chip, Tooltip, Stack, styled } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutral';
import ThumbDownOutlinedIcon from "@mui/icons-material/ThumbDownOutlined";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";

import HandymanOutlinedIcon from "@mui/icons-material/HandymanOutlined";
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import ModeStandbyOutlinedIcon from "@mui/icons-material/ModeStandbyOutlined";
import Diversity3OutlinedIcon from "@mui/icons-material/Diversity3Outlined";

import { UserContext, SkillsContext, CompetenciesContext } from "../../utils/context.js";

import { PageHeader } from "../_PageHeader";

import { convertDateToShort } from "../../utils/monthNames";

import {
  getFeedbacksProvided,
  getFeedbacksReceived,
  getFeedbacksSelf,
  getFeedbacksReceivedCount,
} from "../../services/api/Feedback";

import { getTeamGoals } from "../../services/api/TeamGoals";
import { getActionPlan } from "../../services/api/ActionPlan";


const StyledEmptyState = styled('div')(({ theme }) => ({
  alignSelf: 'center',
  width: '100%',
  height: 500,
  marginTop: theme.spacing(1),
  borderRadius: (theme).shape.borderRadius,
  //outline: '6px solid',
  outlineColor: 'hsla(220, 25%, 80%, 0.2)',
  border: '1px solid',
  borderColor: (theme).palette.grey[200],
  boxShadow: '0 0 12px 8px hsla(220, 25%, 80%, 0.2)',
  backgroundImage: `url(${process.env.REACT_APP_TEMPLATE_IMAGE_URL || 'https://branco.ai'}/static/empty-state/feedback_pointer_trans.png)`,
  backgroundSize: 'cover',
  [theme.breakpoints.up('xs')]: {
      marginTop: theme.spacing(1),
      height: 300,
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(1),
      height: 400,
    },
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(1),
      height: 550,
    },
      ...theme.applyStyles('dark', {
    boxShadow: '0 0 24px 12px hsla(210, 100%, 25%, 0.2)',
    backgroundImage: `url(${process.env.REACT_APP_TEMPLATE_IMAGE_URL || 'https://branco.ai'}/static/empty-state/feedback_pointer_trans.png)`,
    outlineColor: 'hsla(220, 20%, 42%, 0.1)',
    borderColor: (theme).palette.grey[700],
  }),
}));


type KpiMapper = {
  kpi_id: number;
  kpi_name: string;
};
type StepMapper = {
  user_action_plan_step_id: number;
  user_action_plan_step_description: string;
};


enum feedbackType {
  Received,
  Provided,
  Self,
}

const FEEDBACK_HIGH_QUALITY_MIN = 7;

export const FeedbacksTable = () => {
  const [user] = useContext(UserContext);
  const [skills] = useContext(SkillsContext);
  const [competencies] = useContext(CompetenciesContext);
  const [feedbacks, setFeedback] = useState<FeedbackInfo[]>([]);
  const [tabValue, setTabValue] = React.useState<feedbackType>(
    feedbackType.Received
  );
  const [highQualityOnlyFeedback, setViewStatus] = useState<boolean>(true);

  const [kpis, setKpis] = useState<KpiMapper[]>([]);
  const [actionItemSteps, setActionItemSteps] = useState<StepMapper[]>([]);
  const [feedbackCount, setFeedbackCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  

  const getDisplayNameSkill = (career_step_skill_id: number) => {
    if (skills) {
      const foundSkill = skills.find(
        (feedback_details:FeedbackInfo) => feedback_details.career_step_skill_id === career_step_skill_id
      );
      return foundSkill ? foundSkill.skill.skill_name : '';   
    }
    else
      return '';
  };
  const getDisplayNameCompetency = (career_step_competency_id: number) => {
    if (competencies){
      const foundCompetency = competencies.find(
        (feedback_details:FeedbackInfo) => feedback_details.career_step_competency_id === career_step_competency_id
      );
      return foundCompetency ? foundCompetency.competency.competency_name : ''; 
  
    }
    else
      return ''; 
  };
  const getDisplayTeamKpi = (kpi_id: number) => {
    if (kpis){
      const foundKpi = kpis.find(
        (kpi_details:KpiMapper) => kpi_details.kpi_id === kpi_id
      );
      return foundKpi ? foundKpi.kpi_name : ''; 
    }
    else
      return ''; 
  };
  const getDisplayActionItemStep = (user_action_plan_step_id: number) => {
    if (actionItemSteps) {
      const foundStep = actionItemSteps.find(
        (step_details:StepMapper) => step_details.user_action_plan_step_id === user_action_plan_step_id
      );
      return foundStep ? foundStep.user_action_plan_step_description : '';   
    }
    else
      return ''; 
  };
  
  const fetchGoals = async (org_id: number) => {
      return await getTeamGoals(org_id, "").then((value) => {
        const goals = value as TeamGoalInfo[];
        const kpiMapper: KpiMapper[] = [];
        for (let i = 0; i < goals.length; i++) {
          if (goals[i].goal_kpis)
            for (let j = 0; j < goals[i].goal_kpis!.length; j++) {
              kpiMapper.push({
                kpi_id: goals[i].goal_kpis![j].kpi_id!,
                kpi_name: goals[i].goal_kpis![j].kpi_name
              });
            }
        }
        setKpis(kpiMapper);
      });
  };
  const fetchActionPlan = async (UserId: number) => {
      return await getActionPlan(UserId).then((value) => {
        const actionPlans = value as ActionItemInfo[];
        const stepMapper: StepMapper[] = [];
        for (let i = 0; i < actionPlans.length; i++) {
          if (actionPlans[i].user_action_plan_steps)
            for (let j = 0; j < actionPlans[i].user_action_plan_steps!.length; j++) {
              stepMapper.push({
                user_action_plan_step_id: actionPlans[i].user_action_plan_steps![j].user_action_plan_step_id!,
                user_action_plan_step_description: actionPlans[i].user_action_plan_steps![j].user_action_plan_step_description
              });
            }
        }
        setActionItemSteps(stepMapper);
      });
  };
  
  useEffect(() => {
    try {
      if (user){
        fetchFeedbackCount();
        fetchFeedbacks(
          feedbackType.Received,
          highQualityOnlyFeedback
        );
        if (user.org_id){
          fetchGoals(user.org_id);
        }
        fetchActionPlan(user.user_id);
      }
    } catch (err) {
      console.error("Failed to retrieve feedback (this includes goals and action plan). ", err);
    }
  }, [user, competencies, skills]);

  
  const fetchFeedbackCount = async (
  ) => {
    var results: number = 0;
    results = await getFeedbacksReceivedCount(user.user_id);
    setFeedbackCount(results);
    setIsLoading(false);
  };
  const fetchFeedbacks = async (
    type: feedbackType,
    highQualityOnlyFeedback: boolean
  ) => {
    var results: FeedbackInfo[] = [];

    switch (type) {
      case feedbackType.Received: {
        results = await getFeedbacksReceived(user.user_id);
        break;
      }
      case feedbackType.Provided: {
        results = await getFeedbacksProvided(user.user_id);
        break;
      }
      case feedbackType.Self: {
        results = await getFeedbacksSelf(user.user_id);
        break;
      }
    }

    if (!highQualityOnlyFeedback) {
      setFeedback(results);
    } else {
      var results_not_generic: FeedbackInfo[] = [];
      results.forEach((element) => {
        if (element.feedback_quality_level >= FEEDBACK_HIGH_QUALITY_MIN) results_not_generic.push(element);
        //if (element.feedback_notes) results_not_generic.push(element);
      });

      setFeedback(results_not_generic);
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    if (user) {
      fetchFeedbacks(newValue, highQualityOnlyFeedback);
    }
  };
  const viewStatusChange = async () => {
    setViewStatus(!highQualityOnlyFeedback);
    if (user) {
      fetchFeedbacks(tabValue, !highQualityOnlyFeedback);
    }
  };

  const chipStyle = {
    border: "1px solid grey",
  };

  return (
    <Box sx={{ width: "100%", maxWidth: { sm: "100%", md: "1700px" } }}>
      {!isLoading && feedbackCount === 0 &&
        <Grid2>
          <StyledEmptyState />
        </Grid2>
      }
      <Grid2>
        <PageHeader
          title="Feedback history"
          subtitle=""
          subtitle2=""
        />
      </Grid2>
      <Grid2 container sx={{ my: 2 }} justifyContent="flex-center">
        <Grid2 size={8} sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={tabValue} onChange={handleTabChange}>
            <Tab label="Received" />
            <Tab label="Provided" />
            <Tab label="Self" />
          </Tabs>
        </Grid2>
        <Grid2
          size={4}
          container
          justifyContent="flex-end"
          sx={{ borderBottom: 1, borderColor: "divider" }}
        >
          <FormControlLabel
            label="high quality only"
            control={<Switch id="feedback-viewStatusChange-switch" defaultChecked onClick={viewStatusChange} />}
          />
        </Grid2>
      </Grid2>
      <Grid2 container sx={{ my: 1 }} justifyContent="flex-start">
        {feedbacks && feedbacks.length > 0 && (
          <TableContainer>
            <Table>
              <TableBody>
                {feedbacks.map((feedback) => (
                  <TableRow key={feedback.feedback_id}>
                    <TableCell align="center" size="small">
                      <Stack
                        direction="column"
                        spacing={1}
                        sx={{
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <Chip
                          color="primary"
                          variant="outlined"
                          label={`${convertDateToShort(feedback.feedback_date)}`}
                          size="small"
                          style={chipStyle}
                          sx={{ marginBottom: 0 }}
                        />
                        <Stack
                          direction="row"
                          spacing={1}
                          sx={{
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          {feedback.feedback_rating > 100 && (
                            <Tooltip title="exceptional" arrow><StarBorderOutlinedIcon /></Tooltip>
                          )}
                          {(feedback.feedback_rating <= 100 && feedback.feedback_rating > 80) && (
                            <Tooltip title="good" arrow><ThumbUpOutlinedIcon /></Tooltip>
                          )}
                          {(feedback.feedback_rating <= 80 && feedback.feedback_rating > 60) && (
                            <Tooltip title="ok" arrow><SentimentNeutralIcon /></Tooltip>
                          )}
                          {(feedback.feedback_rating <= 60 && feedback.feedback_rating > 40) && (
                            <Tooltip title="to be improved" arrow><ThumbDownOutlinedIcon /></Tooltip>
                          )}
                          {feedback.feedback_rating <= 40 && (
                            <Tooltip title="concerning" arrow><WarningAmberOutlinedIcon /></Tooltip>
                          )}

                          {feedback.career_step_skill_id && (
                            <Tooltip title="Skill" arrow><HandymanOutlinedIcon /></Tooltip>
                          )}
                          {feedback.career_step_competency_id && (
                            <Tooltip title="Competency (soft skill)" arrow><Diversity3OutlinedIcon /></Tooltip>
                          )}
                          {feedback.kpi_id && (
                            <Tooltip title="Team impact" arrow><ModeStandbyOutlinedIcon /></Tooltip>
                          )}
                          {feedback.user_action_plan_step_id && (
                            <Tooltip title="Action plan" arrow><AssignmentTurnedInOutlinedIcon /></Tooltip>
                          )}
                        </Stack>
                      </Stack>
                    </TableCell>
                    <TableCell align="left">
                    <Stack
                        direction="column"
                        spacing={1}
                        sx={{
                          justifyContent: 'flex-start',
                          alignItems: 'flex-start',
                        }}
                      >
                        {feedback.career_step_skill_id && (
                          <b>
                            {getDisplayNameSkill(feedback.career_step_skill_id)}
                          </b>
                        )} 
                        {feedback.career_step_competency_id && (
                          <b>
                            {getDisplayNameCompetency(feedback.career_step_competency_id)}
                          </b>
                        )}
                        {feedback.kpi_id && (
                          <b>
                            {getDisplayTeamKpi(feedback.kpi_id)}
                          </b>
                        )}
                        {feedback.user_action_plan_step_id && (
                          <b>
                            {getDisplayActionItemStep(feedback.user_action_plan_step_id)}
                          </b>
                        )}
                        {feedback.feedback_notes} 
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Grid2>
    </Box>
  );
};
