import * as React from 'react';
import { useEffect, useContext } from "react";
import { styled } from '@mui/material/styles';

import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import { Button, IconButton, Popover, Slider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import Grid from '@mui/material/Grid2';

import HandymanOutlinedIcon from "@mui/icons-material/HandymanOutlined";
import EmergencyOutlinedIcon from '@mui/icons-material/EmergencyOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutral';
import ThumbDownOutlinedIcon from "@mui/icons-material/ThumbDownOutlined";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";

import { UserContext, SkillsContext } from "../../utils/context.js";
import { UserCareerStepSkill } from '../../services/api/schemas/UserCareerStepSkill';

import { getSkillRating } from '../../services/api/SkillRating';
import { SkillRating } from '../../services/api/schemas/SkillRating';
import { getUserRatings } from '../../services/api/Dashboard';

const RANGE_WIDTH = 10;

export default function ExpectationsSkills() {
  const [user] = useContext(UserContext);
  const [skills] = useContext(SkillsContext);
  
  const [strengthsopps, setUserRatings] = React.useState<StrengthOpportunityInfo[]>([]);
  const [selected_skill_name, setSelectedSkillName] = React.useState<string>("");
  const [selected_skill_definition, setSelectedSkillDefinition] = React.useState<string>("");
  const [selected_skill_ratings, setSelectedSkillRatings] = React.useState<SkillRating[]>([]);

  const setDisplayNameSkill = (skill_id: number) => {
    const foundSkill:UserCareerStepSkill = skills.find(
      (skill:UserCareerStepSkill) => skill.skill_id === skill_id
    );
    if (foundSkill) {
      setSelectedSkillName(foundSkill.skill.skill_name);
      setSelectedSkillDefinition(foundSkill.skill.skill_definition);
    } else {
      setSelectedSkillName("");
      setSelectedSkillDefinition("");
    }
  };

  const getUserSkillRating = (career_step_skill_id: number) => {
    const foundSkill = strengthsopps.find(
      (strengthsopps_details:StrengthOpportunityInfo) => strengthsopps_details.career_step_skill_id === career_step_skill_id
    );
    return foundSkill ? foundSkill.average_rating : -1; 
  };
  const getUserSkillRatingDisplay = (prefix: string, career_step_skill_id: number, expected_rating: number) => {
    const rating = getUserSkillRating(career_step_skill_id);
    var ratingDisplay = "";
    if (rating > 0){
      if (rating < (expected_rating - (RANGE_WIDTH * 2))) {
        ratingDisplay += "below expectations";
      }
      else if (rating < (expected_rating - RANGE_WIDTH)) {
        ratingDisplay += "approaching expectations";
      }
      else if (rating > (expected_rating + RANGE_WIDTH)) {
        ratingDisplay += "exceeding expectations";
      }
      else {
        ratingDisplay += "meeting expectations";
      }
    }
    if (ratingDisplay) 
      ratingDisplay = prefix + ratingDisplay;

    return ratingDisplay; 
  };

  const getUserSkillFeedbackCount = (career_step_skill_id: number) => {
    const foundSkill = strengthsopps.find(
      (strengthsopps_details:StrengthOpportunityInfo) => strengthsopps_details.career_step_skill_id === career_step_skill_id
    );
    return foundSkill ? foundSkill.feedback_count : 0; 
  };
  const getUserSkillFeedbackCountDisplay = (career_step_skill_id: number) => {
    const count = getUserSkillFeedbackCount(career_step_skill_id);
    var feedbackDisplayCount = "";
    switch(count) {
      case 0: {
        feedbackDisplayCount = "no feedback yet";
        break;
      }
      case 1: {
        feedbackDisplayCount = count + " feedback";
        break;

      }
      default: {
        feedbackDisplayCount = count + " feedbacks";
      }
    }
    return feedbackDisplayCount; 
  };

  const fetchUserRatingsAndFilterSkills = async (user_id: number) => {
    await getUserRatings(user_id).then((results) => {
      const all_results = results as StrengthOpportunityInfo[] || [];
      let skills_result = all_results.filter(function (el) {
        return el.career_step_skill_id;
        }
      );
      setUserRatings(skills_result);
    }).catch((err) => {
      console.error("Failed to fetch user ratings: ", err);
    });
  };
  
  const fetchSkillRating = async (skill_id: number) => {
    setDisplayNameSkill(skill_id);      
    await getSkillRating(skill_id).then((results) => {
      results.sort((one, two) => (one.skill_rating_level > two.skill_rating_level ? -1 : 1));
      setSelectedSkillRatings(results);
    }).catch((err) => {
      console.error("Failed to fetch skill ratings: ", err);
    });
  };

  useEffect(() => {
      try {
        if (user && skills){
          fetchUserRatingsAndFilterSkills(user.user_id);
        }      
      } catch (err) {
        console.error("Failed to retrieve skills expectations. ", err);
      }
    }, [user, skills]);
    
    // POPUP ratings
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const handleClickPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
      try {
        const {value} = event.currentTarget;
        var selected_skill_id: number = +value;
        fetchSkillRating(selected_skill_id);
        } catch (err) {
        console.error("Failed to retrieve skills ID from 'i' button (for rating). ", err);
      }
      setAnchorEl(event.currentTarget);
    };
    const handleClosePopover = () => {
      setAnchorEl(null);
    };
    const open = Boolean(anchorEl);


const StyledSlider = styled(Slider)(({ theme }) => ({
  '& .MuiSlider-rail': {
    height: '10px',
    borderRadius: '4px',
  },
  '& .MuiSlider-track': {
    backgroundColor: theme.palette.success.main, // Expected range color
    borderColor: theme.palette.success.main, // Expected range color
    height: '10px',
    borderRadius: '4px',
    opacity: 0.6,
  },
  '& .MuiSlider-thumb': {
    width: '0px',
    height: '0px',
    backgroundColor: theme.palette.success.main, // Highlighted user rating
    opacity: 0.6,
  },
  '& .MuiSlider-mark': {
    backgroundColor: theme.palette.primary.main, // Actual rating marker
    width: '10px',
    height: '20px',
    borderRadius: '30%',
    boxShadow: `0 0 1px ${theme.palette.primary.main}`,
    '&:hover': {
      transform: 'scale(1.2)',
    },
  },
  '& .MuiSlider-markActive': {
    backgroundColor: theme.palette.primary.main,
    boxShadow: `0 0 5px ${theme.palette.primary.main}`,
  },
}));


  return (
    <Card
      variant="outlined"
      sx={{ display: 'flex', flexDirection: 'column', gap: '8px', flexGrow: 1 }}
    >
      {skills && skills.length > 0 && (
        <CardContent>
          <Typography component="h2" variant="h6" sx={{ mb: 1 }}>
            <HandymanOutlinedIcon /> Skills expected
          </Typography>
          <Typography sx={{ color: 'text.secondary', mb: '8px'  }}>
            Skills are specific to your role. <br />
            Skill expectations are based on your seniority level.
          </Typography>
          {skills.map((skill:UserCareerStepSkill) => (
            <Grid container   
              sx={{
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Grid size={1}>
                {skill.must_have && 
                  <Tooltip title="Must have" arrow>
                    <EmergencyOutlinedIcon />
                  </Tooltip>
                }
              </Grid>
              <Grid size={6}>
                {skill.skill.skill_name} 
                    <Button aria-describedby={skill.skill.skill_name} value={skill.skill_id} size="small" onClick={handleClickPopover}>
                      <InfoOutlinedIcon />
                    </Button>
                    <Popover
                      id={'popover-' + skill.skill_id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClosePopover}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                      sx={{ width: '95%' }}
                    >
                      <Stack
                        direction="row"
                        padding={2}
                        sx={{
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          gap: 2,
                        }}
                      >
                        <Stack
                          direction="column"
                          spacing={2}
                          sx={{
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                            gap: 1,
                          }}
                        >
                          <b>
                            {selected_skill_name}
                          </b>
                          {selected_skill_definition}
                        </Stack>
                        <IconButton aria-label="Close" size="small" onClick={handleClosePopover}>
                          <CloseOutlinedIcon />
                        </IconButton>
                      </Stack>
                      {selected_skill_ratings && selected_skill_ratings.length > 0 && (
                        <TableContainer>
                        <Table size="small">
                          <TableHead>
                            <TableRow>
                              {/*<TableCell align="center" size="small"></TableCell>*/}
                              <TableCell align="center" size="small"><b>Rating</b></TableCell>
                              <TableCell align="left"><b>Definition</b></TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {selected_skill_ratings.map((skill_rating) => (
                              <TableRow key={skill_rating.skill_rating_level}>
                                <TableCell align="center">
                                  {skill_rating.skill_rating_level > 100 && (
                                    <Tooltip title="exceptional" arrow><StarBorderOutlinedIcon /></Tooltip>
                                  )}
                                  {(skill_rating.skill_rating_level <= 100 && skill_rating.skill_rating_level > 80) && (
                                    <Tooltip title="good" arrow><ThumbUpOutlinedIcon /></Tooltip>
                                  )}
                                  {(skill_rating.skill_rating_level <= 80 && skill_rating.skill_rating_level > 60) && (
                                    <Tooltip title="ok" arrow><SentimentNeutralIcon /></Tooltip>
                                  )}
                                  {(skill_rating.skill_rating_level <= 60 && skill_rating.skill_rating_level > 40) && (
                                    <Tooltip title="to be improved" arrow><ThumbDownOutlinedIcon /></Tooltip>
                                  )}
                                  {skill_rating.skill_rating_level < 40 && (
                                    <Tooltip title="concerning" arrow><WarningAmberOutlinedIcon /></Tooltip>
                                  )}
                                </TableCell>
                                <TableCell align="left">
                                  {skill_rating.skill_rating_definition}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )}
                  </Popover>
              </Grid>
              <Grid size={5} sx={{textAlign: 'right'}}>
                <Typography variant="body2">
                  {getUserSkillFeedbackCountDisplay(skill.career_step_skill_id)}
                  {getUserSkillRatingDisplay(": ", skill.career_step_skill_id, skill.expected_rating)}
                </Typography>
              </Grid>
              <Grid size={1}>
              </Grid>
              <Grid size={11}>
                  <StyledSlider
                    getAriaLabel={() => 'Expected rating at level'}
                    disabled
                    value={[skill.expected_rating - RANGE_WIDTH, skill.expected_rating + RANGE_WIDTH]}
                    marks={[
                            {
                              value: getUserSkillRating(skill.career_step_skill_id)
                            },
                          ]}
                    valueLabelDisplay="auto"
                    min={0}
                    max={125}
                    //getAriaValueText={valuetext}
                  />
              </Grid>
              <Grid size={12} padding={1}>
              </Grid>
            </Grid>
          ))}
        </CardContent>
      )}
    </Card>
  );
}