import { get, post } from "./helpers";
import { CompanyTool } from "./schemas/CompanyTool";

/*
export const getCompanyTool = async (
  company_id: number,
  tool_id: number
): Promise<CompanyTool | null> => {
  try {
    var result = await get<CompanyTool>(`/company_tools/${company_id}/${tool_id}`);
    return result
  } catch (err: any) { 
      if (err.response?.status !== 404) {
        console.error(err);
      }
      return null;
  }  
};
*/

export const getCompanyToolCount = async (
  company_id: number,
  tool_id: number
): Promise<number> => {
  return await get<number>(`/company_tools/${company_id}/${tool_id}/count`);
};

export const createSlack = async (
  code: string,
  state: string
): Promise<CompanyTool> => {
  return await post<CompanyTool>("/company_tools/slack", { code, state });
};
