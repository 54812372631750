import { get, post } from "./helpers";


export const getActionPlanSuggestions = async (
  UserId: number,
): Promise<ActionItemSuggestionInfo[]> => {
  try {
    const params = {user_id: UserId.toString()};
    return await get<ActionItemSuggestionInfo[]>("/user_action_plans/suggestions?" + new URLSearchParams(params).toString());
  } catch (err) {
    console.error(err);
    return [];
  }  
};

export const acceptActionPlanSuggestion = async (
  action_plan_suggestion_id: number
): Promise<ActionItemInfo | null> => {
  try {
    return await post<ActionItemInfo>("/user_action_plans/suggestions/" + action_plan_suggestion_id.toString() + "/accept",
    );
  } catch (err) {
      console.error(err);
      return null;
    } 
};

export const rejectActionPlanSuggestion = async (
  action_plan_suggestion_id: number
): Promise<ActionItemSuggestionInfo | null> => {
  try {
    return await post<ActionItemSuggestionInfo>("/user_action_plans/suggestions/" + action_plan_suggestion_id.toString() + "/reject",
    );
  } catch (err) {
      console.error(err);
      return null;
    } 
};


export const acceptActionPlanStepSuggestion = async (
  action_plan_suggestion_id: number,
  action_plan_step_suggestion_id: number
): Promise<ActionItemStepInfo | null> => {
  try {
    return await post<ActionItemStepInfo>("/user_action_plans/suggestions/" + action_plan_suggestion_id.toString() + "/steps/" + action_plan_step_suggestion_id.toString() + "/accept",
    );
  } catch (err) {
      console.error(err);
      return null;
    } 
};

export const rejectActionPlanStepSuggestion = async (
  action_plan_suggestion_id: number,
  action_plan_step_suggestion_id: number
): Promise<ActionItemStepSuggestionInfo | null> => {
  try {
    return await post<ActionItemStepSuggestionInfo>("/user_action_plans/suggestions/" + action_plan_suggestion_id.toString() + "/steps/" + action_plan_step_suggestion_id.toString() + "/reject",
    );
  } catch (err) {
      console.error(err);
      return null;
    } 
};
