import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';

import AppTheme from '../shared-theme/AppTheme';

import AppAppBar from './parts/AppAppBar';
import Hero from './parts/Hero';
//import LogoCollection from './parts/LogoCollection';
import Highlights from './parts/Highlights';
import Pricing from './parts/Pricing';
import Features from './parts/Features';
//import Testimonials from './parts/Testimonials';
import FAQ from './parts/FAQ';
import Footer from './parts/Footer';

export default function MarketingPage(props: { disableCustomTheme?: boolean }) {
  return (
    <AppTheme {...props}>
      <CssBaseline enableColorScheme />

      <AppAppBar />
      <Hero />
      <div>
        <Features />
        <Divider />
        <Highlights />
        <Divider />
        <Pricing />
        <Divider />
        <FAQ />
        <Footer />
      </div>
    </AppTheme>
  );
}
