import React, { useState, useEffect, useContext } from "react";
import Box from '@mui/material/Box';
import Grid2 from '@mui/material/Grid2';

import { PathConstants } from '../../utils/nav';

import { PageHeader } from '../_PageHeader';
import HighlightedCard from '../_HighlightedCard';
import StatCardX, { StatCardXProps } from './StatCardX';

import ChartStrengths from './ChartStrengths';
import ChartOpportunities from './ChartOpportunities';

import { getPendingDialogs } from "../../services/api/Users";
import {UserContext} from "../../utils/context";
import { Alert } from "@mui/material";


const engagementDataX: StatCardXProps[] = [
  { 
    title: 'Feedback received',
    trend_type: 'USER_FEEDBACK_RECEIVED_FREQUENCY',
  }, { 
    title: 'Feedback given',
    trend_type: 'USER_FEEDBACK_PROVIDED_FREQUENCY',
  }, { 
    title: 'Self-reflections',
    trend_type: 'USER_FEEDBACK_SELF_FREQUENCY',
  }, 
];
const ratingDataX: StatCardXProps[] = [
  { 
    title: 'Impact on team objectives',
    trend_type: 'USER_FEEDBACK_RECEIVED_IMPACT',
  }, { 
    title: 'Progress on your action plan',
    trend_type: 'USER_FEEDBACK_RECEIVED_ACTION_ITEM',
  }, { 
    title: 'Skills',
    trend_type: 'USER_FEEDBACK_RECEIVED_SKILLS',
  }, { 
    title: 'Competencies',
    trend_type: 'USER_FEEDBACK_RECEIVED_COMPETENCIES',
  },
];

export default function MainGridTeamMember() {
  const [user] = React.useContext(UserContext);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [pendingDialogs, setPendingDialogs] = useState<DialogInfo[]>([]);

  React.useEffect(() => {
      try {
      if (user && user.org_id) {
          fetchUserDialogs();
      }
      } catch (err) {
      console.error("Failed to retrieve dialogs. ", err);
      }
  }, [user]);

  const fetchUserDialogs = async (
  ) => {
    const pending_dialogs = await getPendingDialogs();
    setPendingDialogs(pending_dialogs);
    setIsLoading(false);
  };

  return (
    <Box sx={{ width: '100%', maxWidth: { sm: '100%', md: '1700px' } }}>
        {!isLoading && pendingDialogs.length > 0 && 
            <Alert 
                key="notification-pending_dialog"
                variant="outlined" 
                severity={'info'}
            >
                You have {pendingDialogs.length} pending feedback to provide, please head to Slack and help your team grow
            </Alert>
        }
        <PageHeader
          title="Strengths and Opportunities"
          subtitle=""
          subtitle2=""
        />
        <Grid2
          container
          spacing={2}
          columns={12}
          sx={{ mb: (theme) => theme.spacing(2) }}
        >
          <ChartStrengths />
          <ChartOpportunities />
        </Grid2>
        <PageHeader
          title="Feedback trends"
          subtitle=""
          subtitle2=""
        />
        <Grid2
          container
          spacing={2}
          columns={12}
          sx={{ mb: (theme) => theme.spacing(2) }}
        >
          {ratingDataX.map((card, index) => (
            <Grid2 key={index} size={{ xs: 12, sm: 6, lg: 3 }}>
              <StatCardX {...card} />
            </Grid2>
          ))}
        </Grid2>
        <PageHeader
          title="Feedback frequency"
          subtitle=""
          subtitle2=""
        />
        <Grid2
          container
          spacing={2}
          columns={12}
          sx={{ mb: (theme) => theme.spacing(2) }}
        >
          {engagementDataX.map((card, index) => (
            <Grid2 key={index} size={{ xs: 12, sm: 6, lg: 3 }}>
              <StatCardX {...card} />
            </Grid2>
          ))}
          <Grid2 size={{ xs: 12, sm: 6, lg: 3 }}>
            <HighlightedCard 
              title="Explore your feedback" 
              details="Uncover what generated these charts." 
              cta="Go to feedback" 
              route={PathConstants.FEEDBACK} />
          </Grid2>
        </Grid2>
      </Box>
  );
}