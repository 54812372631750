import { get, put } from "./helpers";


export const getUrgentNotifications = async (
  UserId: number,
): Promise<NotificationInfo[]> => {
  try {
    const params = {user_id: UserId.toString()};
    return await get<NotificationInfo[]>("/user_notifications/user_id?" + new URLSearchParams(params).toString());
  } catch (err) {
    console.error(err);
    return [];
  }  
};

export const updateUrgentNotifications = async (
  notification: NotificationInfo
): Promise<NotificationInfo | null> => {
  try {
    const params = {user_notification_id: notification.user_notification_id.toString()};

    const notificationBody: NotificationInfo = {
      user_notification_id: Number(notification.user_notification_id),
      user_id: Number(notification.user_id),
      user_notification_date_created: notification.user_notification_date_created,
      user_notification_date_dismissed: notification.user_notification_date_dismissed || null,
      user_notification_details: notification.user_notification_details || null,
      user_notification_link: notification.user_notification_link || null,
      user_notification_severity: notification.user_notification_severity || null,
    };
    return await put<NotificationInfo>("/user_notifications/user_notification_id?" + new URLSearchParams(params).toString(), 
      notificationBody,
    );
  } catch (err) {
      console.error(err);
      return null;
    } 
};
