import React, {useState} from "react";

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Checkbox,
    Chip,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableRow, Tooltip, Typography,
} from "@mui/material";

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {DeleteOutlined} from "@mui/icons-material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

import {KpiCard} from "./KpiCard";
import { convertDateToShort } from "../../utils/monthNames";
import { colorFromDate } from "../../utils/colorsFromDate";
import { User } from "../../services/api/schemas/User";

interface TeamGoalCardProps {
    goalInfo: TeamGoalInfo,
    onDelete: (item: TeamGoalInfo) => void,
    onEdit: () => void,
    onTeamGoalCompleted: (item: TeamGoalInfo, isCompleted: boolean) => void,
    onKpiCompleted: (step: TeamGoalKpiInfo, isCompleted: boolean) => void,
    showActiveOnly: boolean,
    viewLevelDetails: boolean,
    userOrgLeader: boolean,
    teamMates: User[]
}

export const TeamGoalCard = ({
                                       goalInfo,
                                       onDelete,
                                       onEdit,
                                       onTeamGoalCompleted,
                                       onKpiCompleted,
                                       showActiveOnly,
                                       viewLevelDetails,
                                       userOrgLeader,
                                       teamMates
                                   }: TeamGoalCardProps) => {
    const [goal] = useState<TeamGoalInfo>(goalInfo);

    function handleEdit(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.stopPropagation();
        onEdit();
    }

    function handleTeamGoalCompleted(event: React.ChangeEvent<HTMLInputElement>) {
        const isChecked = event.target.checked; // Get the current value of the checkbox
        onTeamGoalCompleted(goal, isChecked);
    }

    function handleDelete(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.stopPropagation();
        onDelete(goal);
    }

    const backgroundColor = colorFromDate(
        goal.goal_target_date? goal.goal_target_date: "", 
        goal.goal_achieved_date
    );

    // List of action items and action item suggestions
    const goalContent = (
        <Table sx={{tableLayout: "auto"}}>
            <TableBody>
                <TableRow sx={{
                    // Removes the bottom border of the last table row
                    "&:last-child td, &:last-child th": {
                        borderBottom: "none",
                    },
                    display: "flex",
                }}>
                    <TableCell size="small" width="5%" sx={{
                        paddingY: 0,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                        {userOrgLeader && 
                            goal.goal_achieved_date ? (
                                <Tooltip title="Reopen achieved goal" arrow>
                                    <Checkbox id={`item-check- ${goal.goal_id}`}
                                        checked={!!goal.goal_achieved_date}
                                        onClick={(event) => event.stopPropagation()}
                                        onChange={handleTeamGoalCompleted}/>
                                </Tooltip>
                            ):(
                                <Tooltip title="Mark as achieved" arrow>
                                    <Checkbox id={`item-check- ${goal.goal_id}`}
                                        checked={!!goal.goal_achieved_date}
                                        onClick={(event) => event.stopPropagation()}
                                        onChange={handleTeamGoalCompleted}/>
                                </Tooltip>
                            )
                        }
                    </TableCell>
                    <TableCell align="left" sx={{
                        padding: 0, display: "flex",
                        alignItems: "center"
                    }}>
                        <Stack direction={"column"} justifyContent="flex-end" spacing={0}>
                            <Typography variant="h5">
                                {goal.goal_description}
                                {(goal.goal_achieved_date || goal.goal_target_date) &&
                                    <Chip color={backgroundColor} sx={{marginLeft: 1}}
                                        label={goal.goal_achieved_date? `completed ${convertDateToShort(goal.goal_achieved_date)}` : `by ${convertDateToShort(goal.goal_target_date)}`}
                                        size="small"
                                    />
                                }
                            </Typography>
                            {viewLevelDetails && 
                                <i>
                                    Status: {goal.goal_status}
                                </i>
                            }
                        </Stack>
                    </TableCell>
                    {/*Placeholder cell to fill in the space and push the next cell to the right*/}
                    <TableCell sx={{flexGrow: 1}}/>
                    <TableCell align="right" sx={{
                        paddingY: 0,
                        margin: 0,
                        whiteSpace: "nowrap",
                        width: "1%",
                    }}>
                        <Stack direction={"row"} justifyContent="flex-end" spacing={1}>
                            {userOrgLeader && <>
                                <Tooltip title="Delete goal" arrow>
                                    <Button color="info" onClick={handleDelete}
                                        sx={{margin: 0, padding: 0, minWidth: "auto"}}>
                                        <DeleteOutlined fontSize="medium" color={"error"}/>
                                    </Button>
                                </Tooltip>
                                {!goal.goal_achieved_date && 
                                    <Tooltip title="Edit goal" arrow>
                                        <Button color="info" onClick={handleEdit}
                                                sx={{margin: 0, padding: 0, minWidth: "auto"}}>
                                            <EditOutlinedIcon fontSize="medium" color={"primary"}/>
                                        </Button>
                                    </Tooltip>
                                }
                            </>}
                        </Stack>
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );

    const renderKpiCards = () =>
        (goal.goal_kpis || []).map((itemKpi) => {
                const visible = !(showActiveOnly && itemKpi.kpi_achieved_date);
                return visible && (<KpiCard
                    kpiInfo={itemKpi}
                    key={itemKpi.kpi_id}
                    onKpiCompleted={onKpiCompleted}
                    viewLevelDetails={viewLevelDetails}
                    userOrgLeader={userOrgLeader}
                    teamMates={teamMates}
                />);
            }
        )

    // List of steps and step suggestions
    const stepsContent = (
        <Stack spacing={2}>
            <Table>
                <TableBody>
                    {renderKpiCards()}
                </TableBody>
            </Table>

        </Stack>
    );

    return (
        <Accordion defaultExpanded>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                id="panel-header"
            >
                {goalContent}
            </AccordionSummary>
            <AccordionDetails sx={{marginLeft: 2, paddingY: 0}}>
                {stepsContent}
            </AccordionDetails>
        </Accordion>
    );
};