import { get } from "./helpers";

export const getUserTrends = async (
  UserId: number,
  TrendType: string,
): Promise<StatCardTrendInfo | null> => {
  try {
    const params = {user_id: UserId.toString(), time_serie_type: TrendType};
    return await get<StatCardTrendInfo>("/dashboard/user_time_series?" + new URLSearchParams(params).toString());
  } catch (err) {
    console.error(err);
    return null;
  }  
};

export const getUserStrengths = async (
  UserId: number,
): Promise<StrengthOpportunityInfo[]> => {
  try {
    const params = {user_id: UserId.toString()};
    return await get<StrengthOpportunityInfo[]>("/dashboard/user_strengths?" + new URLSearchParams(params).toString());
  } catch (err) {
    console.error(err);
    return [];
  }  
};

export const getUserOpportunities = async (
  UserId: number,
): Promise<StrengthOpportunityInfo[]> => {
  try {
    const params = {user_id: UserId.toString()};
    return await get<StrengthOpportunityInfo[]>("/dashboard/user_opportunities?" + new URLSearchParams(params).toString());
  } catch (err) {
    console.error(err);
    return [];
  }  
};

export const getUserRatings = async (
  UserId: number,
): Promise<StrengthOpportunityInfo[]> => {
  try {
    const params = {user_id: UserId.toString()};
    return await get<StrengthOpportunityInfo[]>("/dashboard/user_ratings?" + new URLSearchParams(params).toString());
  } catch (err) {
    console.error(err);
    return [];
  }  
};


export const getTrends = async (
  Id: number,
  TrendType: string,
): Promise<StatCardTrendInfo | null> => {
  try {
    if (TrendType.startsWith("USER")) {
      const params = {user_id: Id.toString(), time_serie_type: TrendType};
      return await get<StatCardTrendInfo>("/dashboard/user_time_series?" + new URLSearchParams(params).toString());
    } else if (TrendType.startsWith("TEAM")) {
      const params = {org_id: Id.toString(), time_serie_type: TrendType};
      return await get<StatCardTrendInfo>("/dashboard/team_time_series?" + new URLSearchParams(params).toString());
    } else if (TrendType.startsWith("COMPANY")) {
      const params = {company_id: Id.toString(), time_serie_type: TrendType};
      return await get<StatCardTrendInfo>("/dashboard/company_time_series?" + new URLSearchParams(params).toString());
    } else {
      console.log("Unrecognized getTrends TrendType");
      return null;
    }
  } catch (err) {
    console.error(err);
    return null;
  }  
};

