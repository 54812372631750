import * as React from 'react';
import Box from '@mui/material/Box';
import Grid2 from '@mui/material/Grid2';

import { PathConstants } from '../../utils/nav';

import { PageHeader } from '../_PageHeader';
import HighlightedCard from '../_HighlightedCard';
import StatCardX, { StatCardXProps } from './StatCardX';



const engagementDataX: StatCardXProps[] = [
  { 
    title: 'All feedback activity',
    trend_type: 'TEAM_FEEDBACK_ALL',
  }, { 
    title: 'Peer feedback',
    trend_type: 'TEAM_FEEDBACK_PEERS',
  }, { 
    title: 'Self-reflections',
    trend_type: 'TEAM_FEEDBACK_SELF',
  }, 
];


export default function MainGridTeamLeader() {
  return (
    <Box sx={{ width: '100%', maxWidth: { sm: '100%', md: '1700px' } }}>
        <PageHeader
          title="Team feedback activity"
          subtitle="" 
          subtitle2=""
        />
        <Grid2
          container
          spacing={2}
          columns={12}
          sx={{ mb: (theme) => theme.spacing(2) }}
        >
          {engagementDataX.map((card, index) => (
            <Grid2 key={index} size={{ xs: 12, sm: 6, lg: 4 }}>
              <StatCardX {...card} />
            </Grid2>
          ))}
          {/*
          <Grid2 size={{ xs: 12, sm: 6, lg: 4 }}>
            <HighlightedCard 
              title="Explore your team feedback" 
              details="Uncover what generated these charts." 
              cta="Go to feedback" 
              route={PathConstants.FEEDBACK} />
          </Grid2>
          */}
        </Grid2>
      </Box>
  );
}