import React, {useState} from "react";
import dayjs from "dayjs";
import {
    Accordion,
    AccordionActions,
    AccordionSummary,
    Box,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    SelectChangeEvent,
    TextField,
    Tooltip
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import Stack from "@mui/material/Stack";
import Grid from '@mui/material/Grid2';

import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";

import {
    GOAL_STATUS_UX
} from "../../services/api/TeamGoals";

import {kpisEqual} from "../../utils/TeamGoalsUtils";
import { KpiEdit } from "./KpiEdit";

interface TeamGoalCardEditProps {
    goalEdit: TeamGoalInfo;
    userId: number;
    onCancel: () => void;
    onSave: (item: TeamGoalInfo) => void;
}

type T_GoalStatus = keyof typeof GOAL_STATUS_UX;

export const TeamGoalCardEdit = ({
                                           goalEdit,
                                           userId,
                                           onCancel,
                                           onSave,
                                       }: TeamGoalCardEditProps) => {
    const [goal, setTeamGoal] = useState<TeamGoalInfo>(goalEdit);
    const [goalDescriptionError, setGoalDescriptionError] = useState(false);

    const statuses: T_GoalStatus[] = Object.keys(GOAL_STATUS_UX) as T_GoalStatus[];


    function handleOnChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        event.stopPropagation();
        const {name, value} = event.target;
        setTeamGoal((prevData) => ({
            ...prevData,
            [name]: value, // Dynamically update the field
        }));
    }

    function handleChangeGoalDescription(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        event.stopPropagation();
        const {name, value, required} = event.target;
        if (required && value.length == 0){
            setGoalDescriptionError(true)
        } else {
            setGoalDescriptionError(false)
        }
        setTeamGoal((prevData) => ({
            ...prevData,
            [name]: value, // Dynamically update the field
        }));
    }

    const handleChangeStatus = (event: SelectChangeEvent) => {
        event.stopPropagation();
        const {name, value} = event.target;
        setTeamGoal((prevData) => ({
            ...prevData,
            [name]: value, // Dynamically update the field
        }));
      };

    function handleOnKpiChange(updatedKpi: TeamGoalKpiInfo) {
        const updatedKpis = (goal.goal_kpis || []).map((kpi) => {
            if (kpi.kpi_id === updatedKpi.kpi_id) {
                return {...updatedKpi};
            }
            return kpi;
        });
        setTeamGoal((prevData) => ({
            ...prevData,
            goal_kpis: [...updatedKpis],
        }));
    }

    const handleDeleteKpi = (deletedKpi: TeamGoalKpiInfo) => {
        const updatedItem = {...goal};
        updatedItem.goal_kpis = (goal.goal_kpis || []).filter((kpi) => {
            return !kpisEqual(deletedKpi, kpi);
        });
        setTeamGoal(updatedItem);
    }

    const getNewKpiId = (kpis?: TeamGoalKpiInfo[]) => -( (kpis?.length ?? 0) + 1 );

    const handleAddKpi = () => {
        const newKpi: TeamGoalKpiInfo = {
            goal_id: goal.goal_id,
            kpi_name: "",
            kpi_measure_definition: "",
            kpi_target_value: null,
            kpi_achieved_date: null,
            kpi_achieved_user_id: null,
            kpi_status: "ACTIVE",
            kpi_has_custom_users: false,
            kpi_target_date: null,
            kpi_weight: null,
            kpi_created_date: dayjs().format("YYYY-MM-DD"),
            kpi_created_user_id: userId,
            // Set the kpi_id to a negative value to indicate the new kpi
            kpi_id: getNewKpiId(goal.goal_kpis),
        };

        setTeamGoal((prevData) => ({
            ...prevData,
            goal_kpis: [...(prevData.goal_kpis || []), newKpi],
        }));
    }

    async function onOK() {
        try {
            if (goal.goal_description.length == 0){
                setGoalDescriptionError(true)
            } else {
                setGoalDescriptionError(false)
                onSave(goal);
            }
        } catch (error) {
            console.error(error);
        }
    }

    const goalEditTitle = (
        <div className="text-left" style={{width: '100%'}}>
            {/* Edit action item goal */}
            <Box sx={{p: 1}}>
                <FormControl fullWidth>
                    <TextField
                        size={"small"}
                        name="goal_description"
                        required
                        label="Goal (or Objective) description"
                        defaultValue={goal.goal_description}
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                        onChange={handleChangeGoalDescription}
                        error={goalDescriptionError}
                        helperText={goalDescriptionError ? "Please enter Goal (or Objective) description" : ""}
                    />
                </FormControl>
            </Box>
        </div>
    )

    const toolBarContent = (
        <>
            <Tooltip title="Add KPI (or Key Results) that will help your team achieve the goal. Your team members will get feedback on their impact." arrow>
                <Button variant="outlined" startIcon={<AddIcon/>} color={"secondary"} onClick={handleAddKpi}>
                    Add KR
                </Button>
            </Tooltip>
            <Tooltip title="Cancel changes" arrow>
                <Button variant="outlined" startIcon={<CancelIcon/>} color={"error"} onClick={onCancel}>
                    Cancel
                </Button>
            </Tooltip>
            <Tooltip title="Save changes" arrow>
                <Button variant="outlined" startIcon={<CheckIcon/>} onClick={onOK}>
                    OK
                </Button>
            </Tooltip>
        </>
    )

    const goalEditFields = (
        <Stack direction={"row"} sx={{width: '100%', flex: 1}}>
            <Paper sx={{padding: 2, mb: 2, width: '100%'}}>
                <Grid container spacing={2}>
                    <Grid size={{ xs: 12, sm: 4 }}>
                        <FormControl>
                            <InputLabel id="goal_status_select_label">Status</InputLabel>
                            <Tooltip id={"goal_status_select_tip_" + goal.goal_id} title="Default: ACTIVE. Set to PENDING if it's a future goal: the team is not working on it yet." arrow  placement="top">
                                <Select
                                    size={"small"}
                                    labelId="goal_status_select_label"
                                    id="goal_status_select"
                                    name="goal_status"
                                    value={goal.goal_status}
                                    label="Status"
                                    defaultValue={goal.goal_status}
                                    onChange={handleChangeStatus}
                                    //onChange={e => setSelectedStatus(e.target.value as T_GoalStatus)}
                                >
                                    {statuses.map(status => (
                                        <MenuItem value={status}>
                                            {GOAL_STATUS_UX[status]}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Tooltip>
                        </FormControl>
                    </Grid>
                    <Grid size={{ xs: 12, sm: 4 }}>
                        <TextField
                            size={"small"}
                            fullWidth
                            id="goal_target_date"
                            label="Target Date"
                            type="date"
                            defaultValue={goal.goal_target_date}
                            name={"goal_target_date"}
                            slotProps={{inputLabel: {shrink: true}}}
                            onChange={handleOnChange}
                        />
                    </Grid>
                    <Grid size={{ xs: 12, sm: 4 }}>
                        {goal.goal_achieved_date && 
                            <TextField
                                size={"small"}
                                name="goal_achieved_date"
                                label="Date Achieved"
                                type="date"
                                defaultValue={goal.goal_achieved_date}
                                onChange={handleOnChange}
                                slotProps={{inputLabel: {shrink: true}}}
                            />
                        }
                    </Grid>
                </Grid>
            </Paper>
        </Stack>
    )

    const stepsFields = (
        <Stack spacing={2}>
            {
                (goal.goal_kpis || []).map((kpi) => {
                    return <KpiEdit kpiInfo={kpi}
                                     key={kpi.kpi_id}
                                     onChange={handleOnKpiChange}
                                     onDeleteKpi={handleDeleteKpi}/>
                })
            }
        </Stack>
    );

    return <Accordion defaultExpanded>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon/>}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            className="flex justify-between"
        >
            {goalEditTitle}
        </AccordionSummary>
        <AccordionDetails>
            {goalEditFields}
            {stepsFields}
        </AccordionDetails>
        <AccordionActions>
            {toolBarContent}
        </AccordionActions>
    </Accordion>
}
