import * as React from 'react';
import { useEffect, useContext } from "react";
import { styled } from '@mui/material/styles';

import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import { Button, IconButton, Popover, Slider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import Grid from '@mui/material/Grid2';

import Diversity3OutlinedIcon from "@mui/icons-material/Diversity3Outlined";
import EmergencyOutlinedIcon from '@mui/icons-material/EmergencyOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutral';
import ThumbDownOutlinedIcon from "@mui/icons-material/ThumbDownOutlined";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";

import { UserContext, CompetenciesContext } from "../../utils/context.js";
import { UserCareerStepCompetency } from '../../services/api/schemas/UserCareerStepCompetency';

import { getCompetencyRating } from '../../services/api/CompetencyRating';
import { CompetencyRating } from '../../services/api/schemas/CompetencyRating';
import { getUserRatings } from '../../services/api/Dashboard';

const RANGE_WIDTH = 10;

export default function ExpectationsCompetencies() {
  const [user] = useContext(UserContext);
  const [competencies] = useContext(CompetenciesContext);
  
  const [strengthsopps, setUserRatings] = React.useState<StrengthOpportunityInfo[]>([]);
  const [selected_competency_name, setSelectedCompetencyName] = React.useState<string>("");
  const [selected_competency_description, setSelectedCompetencyDescription] = React.useState<string>("");
  const [selected_competency_ratings, setSelectedCompetencyRatings] = React.useState<CompetencyRating[]>([]);

  const setDisplayNameCompetency = (competency_id: number) => {
    const foundCompetency:UserCareerStepCompetency = competencies.find(
      (competency:UserCareerStepCompetency) => competency.competency_id === competency_id
    );
    if (foundCompetency) {
      setSelectedCompetencyName(foundCompetency.competency.competency_name);
      setSelectedCompetencyDescription(foundCompetency.competency.competency_description);
    } else {
      setSelectedCompetencyName("");
      setSelectedCompetencyDescription("");
    }
  };

  const getUserCompetencyRating = (career_step_competency_id: number) => {
    const foundCompetency = strengthsopps.find(
      (strengthsopps_details:StrengthOpportunityInfo) => strengthsopps_details.career_step_competency_id === career_step_competency_id
    );
    return foundCompetency ? foundCompetency.average_rating : -1; 
  };
  const getUserCompetencyRatingDisplay = (prefix: string, career_step_competency_id: number, expected_rating: number) => {
    const rating = getUserCompetencyRating(career_step_competency_id);
    var ratingDisplay = "";
    if (rating > 0){
      if (rating < (expected_rating - (RANGE_WIDTH * 2))) {
        ratingDisplay += "below expectations";
      }
      else if (rating < (expected_rating - RANGE_WIDTH)) {
        ratingDisplay += "approaching expectations";
      }
      else if (rating > (expected_rating + RANGE_WIDTH)) {
        ratingDisplay += "exceeding expectations";
      }
      else {
        ratingDisplay += "meeting expectations";
      }
    }
    if (ratingDisplay) 
      ratingDisplay = prefix + ratingDisplay;

    return ratingDisplay; 
  };

  const getUserCompetencyFeedbackCount = (career_step_competency_id: number) => {
    const foundCompetency = strengthsopps.find(
      (strengthsopps_details:StrengthOpportunityInfo) => strengthsopps_details.career_step_competency_id === career_step_competency_id
    );
    return foundCompetency ? foundCompetency.feedback_count : 0; 
  };
  const getUserCompetencyFeedbackCountDisplay = (career_step_competency_id: number) => {
    const count = getUserCompetencyFeedbackCount(career_step_competency_id);
    var feedbackDisplayCount = "";
    switch(count) {
      case 0: {
        feedbackDisplayCount = "no feedback yet";
        break;
      }
      case 1: {
        feedbackDisplayCount = count + " feedback";
        break;

      }
      default: {
        feedbackDisplayCount = count + " feedbacks";
      }
    }
    return feedbackDisplayCount; 
  };


  const fetchUserRatingsAndFilterCompetencies = async (user_id: number) => {
    await getUserRatings(user_id).then((results) => {
      const all_results = results as StrengthOpportunityInfo[] || [];
      let comp_result = all_results.filter(function (el) {
        return el.career_step_competency_id;
        }
      );
      setUserRatings(comp_result);
    }).catch((err) => {
      console.error("Failed to fetch user ratings: ", err);
    });
  };
  

  const fetchCompetencyRating = async (competency_id: number) => {
    setDisplayNameCompetency(competency_id);
    await getCompetencyRating(competency_id).then((results) => {
      results.sort((one, two) => (one.competency_rating_level > two.competency_rating_level ? -1 : 1));
      setSelectedCompetencyRatings(results);
    }).catch((err) => {
      console.error("Failed to fetch competency ratings: ", err);
    });
  };

  useEffect(() => {
      try {
        if (user && competencies){
          fetchUserRatingsAndFilterCompetencies(user.user_id);
        }
      } catch (err) {
        console.error("Failed to retrieve competencies expectations. ", err);
      }
    }, [user, competencies]);

    // POPUP ratings
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const handleClickPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
      try {
        const {value} = event.currentTarget;
        var selected_competency_id: number = +value;
        fetchCompetencyRating(selected_competency_id);
        } catch (err) {
        console.error("Failed to retrieve competency ID from 'i' button (for rating). ", err);
      }
      setAnchorEl(event.currentTarget);
    };
    const handleClosePopover = () => {
      setAnchorEl(null);
    };
    const open = Boolean(anchorEl);

    
const StyledSlider = styled(Slider)(({ theme }) => ({
  '& .MuiSlider-rail': {
    height: '10px',
    borderRadius: '4px',
  },
  '& .MuiSlider-track': {
    backgroundColor: theme.palette.success.main, // Expected range color
    borderColor: theme.palette.success.main, // Expected range color
    height: '10px',
    borderRadius: '4px',
    opacity: 0.6,
  },
  '& .MuiSlider-thumb': {
    width: '0px',
    height: '0px',
    backgroundColor: theme.palette.success.main, // Highlighted user rating
    opacity: 0.6,
  },
  '& .MuiSlider-mark': {
    backgroundColor: theme.palette.primary.main, // Actual rating marker
    width: '10px',
    height: '20px',
    borderRadius: '30%',
    boxShadow: `0 0 1px ${theme.palette.primary.main}`,
    '&:hover': {
      transform: 'scale(1.2)',
    },
  },
  '& .MuiSlider-markActive': {
    backgroundColor: theme.palette.primary.main,
    boxShadow: `0 0 5px ${theme.palette.primary.main}`,
  },
}));


  return (
    <Card
      variant="outlined"
      sx={{ display: 'flex', flexDirection: 'column', gap: '8px', flexGrow: 1 }}
    >
      {competencies && competencies.length > 0 && (
        <CardContent>
          <Typography component="h2" variant="h6" sx={{ mb: 1 }}>
            <Diversity3OutlinedIcon /> Competencies expected
          </Typography>
          <Typography sx={{ color: 'text.secondary', mb: '8px'  }}>
            Competencies are soft skills specific to your company. <br />
            Competency expectations are based on your leadership level.
          </Typography>
          {competencies.map((comp:UserCareerStepCompetency) => (
            <Grid container   
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Grid size={1}>
              {comp.must_have && 
                <Tooltip title="Must have" arrow>
                  <EmergencyOutlinedIcon />
                </Tooltip>
              }
            </Grid>
            <Grid size={6}>
            {comp.competency.competency_name}
                    <Tooltip title="See details" arrow>
                      <Button aria-describedby={comp.competency.competency_name} value={comp.competency_id} size="small" onClick={handleClickPopover}>
                        <InfoOutlinedIcon />
                      </Button>
                    </Tooltip>
                    <Popover
                      id={'popover-' + comp.competency_id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClosePopover}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                      sx={{ width: '95%' }}
                    >
                      <Stack
                        direction="row"
                        padding={2}
                        sx={{
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          gap: 2,
                        }}
                      >
                        <Stack
                          direction="column"
                          spacing={2}
                          sx={{
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                            gap: 1,
                          }}
                        >
                          <b>
                            {selected_competency_name}
                          </b>
                          {selected_competency_description}
                        </Stack>
                        <IconButton aria-label="Close" size="small" onClick={handleClosePopover}>
                          <CloseOutlinedIcon />
                        </IconButton>
                      </Stack>
                      {selected_competency_ratings && selected_competency_ratings.length > 0 && (
                        <TableContainer>
                        <Table size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell align="center" size="small"><b>Rating</b></TableCell>
                              <TableCell align="left"><b>Definition</b></TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {selected_competency_ratings.map((competency_rating) => (
                              <TableRow key={competency_rating.competency_rating_level}>
                                <TableCell align="center">
                                  {competency_rating.competency_rating_level > 100 && (
                                    <Tooltip title="exceptional" arrow><StarBorderOutlinedIcon /></Tooltip>
                                  )}
                                  {(competency_rating.competency_rating_level <= 100 && competency_rating.competency_rating_level > 80) && (
                                    <Tooltip title="good" arrow><ThumbUpOutlinedIcon /></Tooltip>
                                  )}
                                  {(competency_rating.competency_rating_level <= 80 && competency_rating.competency_rating_level > 60) && (
                                    <Tooltip title="ok" arrow><SentimentNeutralIcon /></Tooltip>
                                  )}
                                  {(competency_rating.competency_rating_level <= 60 && competency_rating.competency_rating_level > 40) && (
                                    <Tooltip title="to be improved" arrow><ThumbDownOutlinedIcon /></Tooltip>
                                  )}
                                  {competency_rating.competency_rating_level <= 40 && (
                                    <Tooltip title="concerning" arrow><WarningAmberOutlinedIcon /></Tooltip>
                                  )}
                                </TableCell>
                                <TableCell align="left">
                                  {competency_rating.competency_rating_definition}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )}
                  </Popover>
            </Grid>
            <Grid size={5} sx={{textAlign: 'right'}}>
                <Typography variant="body2">
                  {getUserCompetencyFeedbackCountDisplay(comp.career_step_competency_id)}
                  {getUserCompetencyRatingDisplay(": ", comp.career_step_competency_id, comp.expected_rating)}
                </Typography>
            </Grid>
            <Grid size={1}>
            </Grid>
            <Grid size={11}>
                <StyledSlider
                  getAriaLabel={() => 'Expected rating at level'}
                  disabled
                  value={[comp.expected_rating - RANGE_WIDTH, comp.expected_rating + RANGE_WIDTH]}
                  marks={[
                          {
                            value: getUserCompetencyRating(comp.career_step_competency_id)
                          },
                        ]}
                  valueLabelDisplay="auto"
                  min={0}
                  max={125}
                  //getAriaValueText={valuetext}
                />
            </Grid>
            <Grid size={12} padding={1}>
            </Grid>
          </Grid>
          ))}
        </CardContent>
      )}
    </Card>
  );
}