import * as React from "react";
import Stack from "@mui/material/Stack";
import NotificationsRoundedIcon from "@mui/icons-material/NotificationsRounded";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import MenuButton from "./MenuButton";
import OptionsMenu from "./OptionsMenu";

import { stringAvatar } from "../../utils/stringAvatar";

import ColorModeIconDropdown from "../shared-theme/ColorModeIconDropdown";
import { getCurrentUser } from "../../services/api/Users";
import { User } from "../../services/api/schemas/User";
import Notifications from "./Notifications";
import ImpersonateReports from "./ImpersonateReports";


export default function Header() {
  const [user, setUser] = React.useState<User>();

  React.useEffect(() => {
    const fetchUser = async () => {
      try {
        const currentUser = await getCurrentUser();
        setUser(currentUser);
      } catch (err) {
        console.error("Failed to retrieve user. ", err);
      }
    };
    fetchUser();
  }, []);

  return (
    <Stack
      direction="row"
      sx={{
        display: { xs: "none", md: "flex" },
        width: "100%",
        alignItems: { xs: "flex-start", md: "center" },
        justifyContent: "flex-end",
        maxWidth: { sm: "100%", md: "1700px" },
        pt: 1.5,
      }}
      spacing={2}
    >
      <Stack direction="row" sx={{ alignItems: 'center', gap: 1 }}>
        {/* <CustomDatePicker /> 
        <MenuButton showBadge aria-label="Open notifications">
          <NotificationsRoundedIcon />
        </MenuButton>
        <Notifications />
        */}
        <ImpersonateReports />
        <Avatar {...stringAvatar(user ? user.user_name ? user.user_name : user.user_email : "?")} />
        {/* <Avatar
          sizes="small"
          alt={
            user ? `${user.user_name ? user.user_name : user.user_email}` : "?"
          }
          //src="/static/images/avatar/7.jpg"
          sx={{ width: 36, height: 36 }}
        />*/}
        <Box sx={{ mr: "auto" }}>
          <Typography
            variant="body2"
            sx={{ fontWeight: 500, lineHeight: "16px" }}
          >
            {user
              ? `${user.user_name ? user.user_name : user.user_email}`
              : "Not logged in"}
          </Typography>
          <Typography variant="caption" sx={{ color: "text.secondary" }}>
            {user ? `${user.user_email}` : "Not logged in"}
          </Typography>
        </Box>
        <ColorModeIconDropdown />
        <OptionsMenu />
      </Stack>
    </Stack>
  );
}
