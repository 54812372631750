import React from "react";

import {Checkbox, Chip, Stack, TableCell, TableRow, Tooltip,} from "@mui/material";

import {convertDateToShort} from "../../utils/monthNames";
import { colorFromDate } from "../../utils/colorsFromDate";

interface StepProps {
    stepInfo: ActionItemStepInfo;
    onStepCompleted: (step: ActionItemStepInfo, isCompleted: boolean) => void;
    viewLevelDetails: boolean;
}

export const StepCard = ({stepInfo, onStepCompleted, viewLevelDetails}: StepProps) => {
    async function handleStepCompleted(event: React.ChangeEvent<HTMLInputElement>) {
        const isChecked = event.target.checked; // Get the current value of the checkbox
        onStepCompleted(stepInfo, isChecked);
    }

    const backgroundColor = colorFromDate(
        stepInfo.user_action_plan_step_target_date, 
        stepInfo.user_action_plan_step_date_completed
    );

    return (
        <TableRow>
            <TableCell size="small" width="5%">
                {stepInfo.user_action_plan_step_date_completed ? 
                (
                    <Tooltip title="Reopen completed step" arrow>
                        <Checkbox id={`step-check- ${stepInfo.user_action_plan_step_id}`}
                                checked={!!stepInfo.user_action_plan_step_date_completed} 
                                onChange={handleStepCompleted}/>
                    </Tooltip>
                ):(
                    <Tooltip title="Mark step as complete" arrow>
                        <Checkbox id={`step-check- ${stepInfo.user_action_plan_step_id}`}
                                checked={!!stepInfo.user_action_plan_step_date_completed} 
                                onChange={handleStepCompleted}/>
                    </Tooltip>
                )}
            </TableCell>
            <TableCell align="left" sx={{padding: 1}}>
                <Stack direction={"column"} justifyContent="flex-end" spacing={0}>
                    <Stack direction={"row"}>
                        {stepInfo.user_action_plan_step_description}
                        <Chip color={backgroundColor} sx={{marginLeft: 1}}
                            label={stepInfo.user_action_plan_step_date_completed? `completed ${convertDateToShort(stepInfo.user_action_plan_step_date_completed)}` : `by ${convertDateToShort(stepInfo.user_action_plan_step_target_date)}`}
                            size="small"
                        />
                    </Stack>
                    {viewLevelDetails && 
                    <i>
                        {stepInfo.user_action_plan_step_measurement && 'Measurement (success criteria): ' + stepInfo.user_action_plan_step_measurement} 
                    </i>
                    }
                </Stack>
            </TableCell>
        </TableRow>
    );
};