import {
    Accordion,
    AccordionActions,
    AccordionSummary,
    Box,
    Button,
    FormControl,
    Grid2,
    Paper,
    TextField,
    Tooltip
} from "@mui/material";
import React, {useState} from "react";
import AccordionDetails from "@mui/material/AccordionDetails";
import Stack from "@mui/material/Stack";
import Grid from '@mui/material/Grid2';

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";

import {StepEdit} from "./StepEdit";
import {stepsEqual} from "../../utils/ActionPlanUtils";

interface ActionItemCardEditProps {
    plan: ActionItemInfo;
    onCancel: () => void;
    onSave: (item: ActionItemInfo) => void;
}

export const ActionPlanItemCardEdit = ({
                                           plan,
                                           onCancel,
                                           onSave,
                                       }: ActionItemCardEditProps) => {
    const [actionItem, setActionItem] = useState<ActionItemInfo>(plan);
    const [actionItemNameError, setActionItemNameError] = useState(false);
    const [priorityError, setPriorityError] = useState(false);

    function handleOnChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        event.stopPropagation();
        const {name, value} = event.target;
        setActionItem((prevData) => ({
            ...prevData,
            [name]: value, // Dynamically update the field
        }));
    }

    function handleOnActionItemNameChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        event.stopPropagation();
        const {name, value, required} = event.target;
        if (required && value.length == 0){
            setActionItemNameError(true)
        } else {
            setActionItemNameError(false)
        }
        setActionItem((prevData) => ({
            ...prevData,
            [name]: value, // Dynamically update the field
        }));
    }
    function handleOnPriorityChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        event.stopPropagation();
        const {name, value, required} = event.target;
        if (required && value.length == 0){
            setPriorityError(true)
        } else {
            setPriorityError(false)
        }
        setActionItem((prevData) => ({
            ...prevData,
            [name]: value, // Dynamically update the field
        }));
    }

    function handleOnStepChange(updatedStep: ActionItemStepInfo) {
        const updatedSteps = (actionItem.user_action_plan_steps || []).map((step) => {
            if (step.user_action_plan_step_id === updatedStep.user_action_plan_step_id) {
                return {...updatedStep};
            }
            return step;
        });
        setActionItem((prevData) => ({
            ...prevData,
            user_action_plan_steps: [...updatedSteps],
        }));
    }

    const handleDeleteStep = (deletedStep: ActionItemStepInfo) => {
        const updatedItem = {...actionItem};
        updatedItem.user_action_plan_steps = (actionItem.user_action_plan_steps || []).filter((step) => {
            return !stepsEqual(deletedStep, step);
        });
        setActionItem(updatedItem);
    }

    const getNewStepId = (steps?: ActionItemStepInfo[]) => -( (steps?.length ?? 0) + 1 );

    const handleAddStep = () => {
        const newStep: ActionItemStepInfo = {
            user_action_plan_id: actionItem.user_action_plan_id,
            // Set the user_action_plan_step_id to a negative value to indicate the new step
            user_action_plan_step_id: getNewStepId(actionItem.user_action_plan_steps),
            user_action_plan_step_description: "",
            user_action_plan_step_measurement: "",
            user_action_plan_step_target_date: "",
            user_action_plan_step_date_completed: null,
        };

        setActionItem((prevData) => ({
            ...prevData,
            user_action_plan_steps: [...(prevData.user_action_plan_steps || []), newStep],
        }));
    }

    async function onOK() {
        try {
            if (actionItem.user_action_plan_goal.length == 0){
                setActionItemNameError(true)
            } else {
                setActionItemNameError(false)
                if (actionItem.user_action_plan_priority.length == 0){
                    setPriorityError(true)
                } else {
                    setPriorityError(false)
                    onSave(actionItem);
                }
            }
        } catch (error) {
            // TODO: handle failure
            console.error(error);
        }
    }

    const actionItemEditTitle = (
        <div className="text-left" style={{width: '100%'}}>
            {/* Edit action item goal */}
            <Box sx={{p: 1}}>
                <FormControl fullWidth>
                    <TextField
                        size={"small"}
                        name="user_action_plan_goal"
                        label="Action item description"
                        required
                        defaultValue={actionItem.user_action_plan_goal}
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                        onChange={handleOnActionItemNameChange}
                        error={actionItemNameError}
                        helperText={actionItemNameError ? "Please enter you action item description" : ""}
                    />
                </FormControl>
            </Box>
        </div>
    )

    const toolBarContent = (
        <>
            <Tooltip title="Add steps to complete the action item and get feedback on the progress" arrow>
                <Button variant="outlined" startIcon={<AddIcon/>} color={"secondary"} onClick={handleAddStep}>
                    Add Step
                </Button>
            </Tooltip>
            <Tooltip title="Cancel changes" arrow>
                <Button variant="outlined" startIcon={<CancelIcon/>} color={"error"} onClick={onCancel}>
                    Cancel
                </Button>
            </Tooltip>
            <Tooltip title="Save changes" arrow>
                <Button variant="outlined" startIcon={<CheckIcon/>} onClick={onOK}>
                    OK
                </Button>
            </Tooltip>
        </>
    )

    const actionItemEditFields = (
        <Stack direction={"row"} sx={{width: '100%', flex: 1}}>
            <Paper sx={{padding: 2, mb: 2, width: '100%'}}>
                {/*Edit action item priority, date completed, and target date*/}
                <Grid container spacing={2}>
                    <Grid size={{ xs: 12, sm: 4 }}>
                        <Tooltip title="The priority of this action item: its order of importance to support your personal growth." arrow>
                            <TextField
                                size={"small"}
                                name="user_action_plan_priority"
                                label="Action item priority"
                                type="number"
                                required
                                defaultValue={actionItem.user_action_plan_priority}
                                onChange={handleOnPriorityChange}
                                error={priorityError}
                                helperText={priorityError ? "Please enter action item priority" : ""}
                            />
                        </Tooltip>
                    </Grid>
                    <Grid size={{ xs: 12, sm: 8 }}>
                        <FormControl fullWidth sx={{gap: 2}}>
                            <TextField
                                size={"small"}
                                name="user_action_plan_business_value"
                                label="Business Value"
                                defaultValue={actionItem.user_action_plan_business_value}
                                onChange={handleOnChange}
                                multiline
                            />
                        </FormControl>
                    </Grid>
                    <Grid size={{ xs: 12, sm: 4 }}>
                        {actionItem.user_action_plan_date_completed && 
                            <TextField
                                size={"small"}
                                name="user_action_plan_date_completed"
                                label="Date Completed"
                                type="date"
                                defaultValue={actionItem.user_action_plan_date_completed}
                                onChange={handleOnChange}
                                slotProps={{inputLabel: {shrink: true}}}
                            />
                        }
                    </Grid>
                </Grid>
            </Paper>
        </Stack>
    )

    const stepsFields = (
        <Stack spacing={2}>
            {
                (actionItem.user_action_plan_steps || []).map((step) => {
                    return <StepEdit stepInfo={step}
                                     key={step.user_action_plan_step_id}
                                     onChange={handleOnStepChange}
                                     onDeleteStep={handleDeleteStep}/>
                })
            }
        </Stack>
    );

    return <Accordion defaultExpanded>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon/>}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            className="flex justify-between"
        >
            {actionItemEditTitle}
        </AccordionSummary>
        <AccordionDetails>
            {actionItemEditFields}
            {stepsFields}
        </AccordionDetails>
        <AccordionActions>
            {toolBarContent}
        </AccordionActions>
    </Accordion>
}
