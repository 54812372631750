import * as React from "react";
import { useEffect } from "react";
//import { useContext } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

import { alpha } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import AppTheme from "../shared-theme/AppTheme";

import {
  UserContext,
  SkillsContext,
  CompetenciesContext,
} from "../../utils/context.js";
import { User } from "../../services/api/schemas/User";
import { UserCareerStepSkill } from "../../services/api/schemas/UserCareerStepSkill";
import { UserCareerStepCompetency } from "../../services/api/schemas/UserCareerStepCompetency";

import FirebaseAuth from "../../services/authentication/firebase/Authentication";

import { getCurrentUser } from "../../services/api/Users";
import { getUserCareerStepSkills } from "../../services/api/UserCareerStepSkills";
import { getUserCareerStepCompetencies } from "../../services/api/UserCareerStepCompetencies";

import { PathConstants } from "../../utils/nav";

import AppNavbar from "./AppNavBar";
import Header from "./Header";
import SideMenu from "./SideMenu";


export default function Layout(props: { disableCustomTheme?: boolean }) {
  const [userContext, setUserContext] = React.useState<User>();
  const [skillsContext, setSkillsContext] =
    React.useState<UserCareerStepSkill[]>();
  const [competenciesContext, setCompetenciesContext] =
    React.useState<UserCareerStepCompetency[]>();

  const fa = new FirebaseAuth();
  const userInfo = fa.getUserInfo();
  const navigate = useNavigate();


  const loadFullContext = async () => {

    try{
      const currentUser = await getCurrentUser();
      setUserContext(currentUser);
  
      if (currentUser && currentUser.career_step_id){
        await getUserCareerStepSkills(currentUser.career_step_id!).then((value_skills) => {
          setSkillsContext(value_skills);  
            });
      }
      if (currentUser && currentUser.level_id){
        await getUserCareerStepCompetencies(currentUser.level_id!).then((value_comp) => {
          setCompetenciesContext(value_comp);  
            });
      }
    } catch (err) {
      console.warn("Failed to load context. ", err);
      try{
        await fa.signOut();
        navigate(PathConstants.SIGN_UP);
      } catch {}
    }
  };

  useEffect(() => {
    try {
      if (userInfo === null) {
        navigate(PathConstants.SIGN_IN);
      } else {
        loadFullContext();
      }
    } catch (err) {
      console.error("Failed to retrieve user. ", err);
      navigate(PathConstants.SIGN_UP);
    }
  }, [userInfo]);

  return (
    <AppTheme {...props}>
      <CssBaseline enableColorScheme />
      {userInfo && userContext && (
        <UserContext.Provider value={[userContext, setUserContext]}>
          <SkillsContext.Provider value={[skillsContext, setSkillsContext]}>
            <CompetenciesContext.Provider
              value={[competenciesContext, setCompetenciesContext]}
            >
              <Box sx={{ display: "flex" }}>
                <SideMenu />
                <AppNavbar />
                {/* Main content */}
                <Box
                  component="main"
                  sx={(theme) => ({
                    flexGrow: 1,
                    backgroundColor: alpha(theme.palette.background.default, 1),
                    overflow: "auto",
                  })}
                >
                  <Stack
                    spacing={2}
                    sx={{
                      alignItems: "center",
                      mx: 3,
                      pb: 5,
                      mt: { xs: 8, md: 0 },
                    }}
                  >
                    <Header />
                    <Outlet />
                  </Stack>
                </Box>
              </Box>
            </CompetenciesContext.Provider>
          </SkillsContext.Provider>
        </UserContext.Provider>
      )}
    </AppTheme>
  );
}
