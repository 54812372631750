import React, {useContext, useEffect, useState} from "react";
import dayjs from "dayjs";

import {Box, Button, FormControlLabel, Grid2, styled,} from "@mui/material";
import Switch from "@mui/material/Switch";
import ZoomOutOutlinedIcon from "@mui/icons-material/ZoomOutOutlined";
import ZoomInOutlinedIcon from "@mui/icons-material/ZoomInOutlined";

import {UserContext} from "../../utils/context";

import {
    getActionPlan,
    createActionPlan,
    deleteActionPlan,
    updateActionPlan,
    updateActionPlanAggregate,
    updateActionPlanStep
} from "../../services/api/ActionPlan";
import {
    getActionPlanSuggestions,
    acceptActionPlanSuggestion,
    rejectActionPlanSuggestion,
    acceptActionPlanStepSuggestion,
    rejectActionPlanStepSuggestion
} from "../../services/api/ActionPlanSuggestion";
import {sortActionPlans, sortActionPlanSteps,} from "../../utils/ActionPlanUtils";

import {ActionPlanItemCard} from "./ActionPlanItemCard";
import {ActionPlanSuggestionCard} from "./ActionPlanSuggestionCard";
import {ActionPlanItemCardEdit} from "./ActionPlanItemCardEdit";
import {PageHeader} from "../_PageHeader";

import { Player } from "@lottiefiles/react-lottie-player";

const StyledEmptyState = styled('div')(({ theme }) => ({
    alignSelf: 'center',
    width: '100%',
    height: 500,
    marginTop: theme.spacing(1),
    borderRadius: (theme).shape.borderRadius,
    //outline: '6px solid',
    outlineColor: 'hsla(220, 25%, 80%, 0.2)',
    border: '1px solid',
    borderColor: (theme).palette.grey[200],
    boxShadow: '0 0 12px 8px hsla(220, 25%, 80%, 0.2)',
    backgroundImage: `url(${process.env.REACT_APP_TEMPLATE_IMAGE_URL || 'https://branco.ai'}/static/empty-state/action-plan_pointer_trans.png)`,
    backgroundSize: 'cover',
    [theme.breakpoints.up('xs')]: {
        marginTop: theme.spacing(1),
        height: 300,
      },
      [theme.breakpoints.up('sm')]: {
        marginTop: theme.spacing(1),
        height: 400,
      },
      [theme.breakpoints.up('md')]: {
        marginTop: theme.spacing(1),
        height: 550,
      },
        ...theme.applyStyles('dark', {
      boxShadow: '0 0 24px 12px hsla(210, 100%, 25%, 0.2)',
      backgroundImage: `url(${process.env.REACT_APP_TEMPLATE_IMAGE_URL || 'https://branco.ai'}/static/empty-state/action-plan_pointer_trans.png)`,
      outlineColor: 'hsla(220, 20%, 42%, 0.1)',
      borderColor: (theme).palette.grey[700],
    }),
  }));

  const animationArrow = "/static/lotties/arrow_right.json";
  
  
type ActionPlanMapping = {
    actionPlan: ActionItemInfo;
    stepSuggestions: ActionItemStepSuggestionInfo[];
};

interface ListItemContainerProps {
    itemInfo: ActionItemInfo,
    stepSuggestions: Array<ActionItemStepSuggestionInfo>,
    onSave: (updatedItem: ActionItemInfo) => void,
    onDelete: (item: ActionItemInfo) => void,
    onAcceptSuggestionStep: (item: ActionItemInfo, step: ActionItemStepSuggestionInfo, isAccepted: boolean) => void,
    onActionItemCompleted: (item: ActionItemInfo, isCompleted: boolean) => void,
    onStepCompleted: (step: ActionItemStepInfo, isCompleted: boolean) => void,
    showActiveOnly: boolean,
    viewLevelDetails: boolean
}

const ListItemContainer = ({
                               itemInfo,
                               stepSuggestions,
                               onSave,
                               onDelete,
                               onAcceptSuggestionStep,
                               onActionItemCompleted,
                               onStepCompleted: onStepCompleted,
                               showActiveOnly,
                               viewLevelDetails
                           }: ListItemContainerProps) => {
    const [isEditing, setIsEditing] = useState(false);
    const handleEdit = () => setIsEditing(true);
    const handleCancelEdit = () => setIsEditing(false);

    function handleSave(updatedItem: ActionItemInfo) {
        setIsEditing(false);
        onSave(updatedItem);
    }

    return isEditing ? (
        <ActionPlanItemCardEdit plan={itemInfo} onCancel={handleCancelEdit}
                                onSave={handleSave}/>
    ) : (
        <ActionPlanItemCard
            plan={itemInfo}
            onDelete={onDelete}
            onEdit={handleEdit}
            onActionItemCompleted={onActionItemCompleted}
            onStepCompleted={onStepCompleted}
            onAcceptSuggestionStep={onAcceptSuggestionStep}
            stepSuggestions={stepSuggestions}
            showActiveOnly={showActiveOnly}
            viewLevelDetails={viewLevelDetails}
        />
    );
};

export const ActionPlanPage = () => {
    const [user] = useContext(UserContext);
    const [actionPlanMapping, setActionPlanMapping] = useState<
        ActionPlanMapping[]
    >([]);
    const [actionPlanSuggestions, setActionPlanSuggestions] = useState<ActionItemSuggestionInfo[]>([]);
    const [createActionItem, setCreateActionItem] = useState(false);
    const [showActiveOnly, setShowActiveOnly] = useState(true);
    const [viewLevelDetails, setViewLevelDetails] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        try {
            if (user) {
                try {
                    fetchDataAndCreateMapping(user.user_id).catch((e) => {
                        console.error("Failed to fetch action plan data: " + e);
                        // TODO: UI error
                    });
                } catch (e) {
                    console.error("Failed to fetch action plan data: " + e);
                }
            }
        } catch (err) {
            console.error("Failed to retrieve goals. ", err);
        }
    }, [user]);

    const fetchActionPlan = async (id: number) => {
        return await getActionPlan(id).then((value) => {
            const actionPlans = value as ActionItemInfo[];
            actionPlans.forEach((plan) => {
                if (plan.user_action_plan_steps) {
                    plan.user_action_plan_steps.sort(sortActionPlanSteps);
                }
            });
            return actionPlans.sort(sortActionPlans);
        });
    };

    const fetchActionPlanSuggestions = async (id: number) => {
        return await getActionPlanSuggestions(id).then((value) => {
            return value as ActionItemSuggestionInfo[];
        });
    };

    const fetchDataAndCreateMapping = async (userId: number) => {
        const actionPlans = await fetchActionPlan(userId);
        const suggestions = await fetchActionPlanSuggestions(userId);

        const actionPlanMapping: ActionPlanMapping[] = [];

        // Populate existing actionPlan with suggested new steps
        for (let i = 0; i < actionPlans.length; i++) {
            // Find suggestions associated with this action plan
            const actionPlanStepSuggestions =
                suggestions.find(
                    (suggestion) =>
                        suggestion.user_action_plan_id == actionPlans[i].user_action_plan_id
                )?.user_action_plan_step_suggestions || [];

            // Filter only new suggestions
            const newStepSuggestions = actionPlanStepSuggestions.filter(
                (suggestion) => suggestion.user_action_plan_step_id == null
            );

            actionPlanMapping.push({
                actionPlan: {
                    ...actionPlans[i], // Shallow copy of the outer object
                    user_action_plan_steps: [
                        ...(actionPlans[i].user_action_plan_steps || []),
                    ], // Copy of the nested array
                },
                stepSuggestions: newStepSuggestions,
            });
        }
        setActionPlanMapping(actionPlanMapping);

        // Populate suggested new actionPlan with suggested new steps
        const actionPlanSuggestions = suggestions.filter(
            (suggestion) => suggestion.user_action_plan_id == null
        );
        setActionPlanSuggestions(actionPlanSuggestions);
        
        setIsLoading(false);
    };

    function handleStepCompleted(step: ActionItemStepInfo, isCompleted: boolean) {
        step.user_action_plan_step_date_completed = isCompleted ? dayjs().format("YYYY-MM-DD") : null;
        updateActionPlanStep(step).then(() => {
            // Update mapping
            const updatedMapping = actionPlanMapping.map((value) => {
                if (value.actionPlan.user_action_plan_id === step.user_action_plan_id) {
                    // Update the step
                    const updatedSteps = value.actionPlan.user_action_plan_steps!.map((stepValue) => {
                        if (stepValue.user_action_plan_step_id === step.user_action_plan_step_id) {
                            return {
                                ...step
                            };
                        }
                        return stepValue;
                    });
                    return {
                        actionPlan: {
                            ...value.actionPlan,
                            user_action_plan_steps: updatedSteps,
                        },
                        stepSuggestions: value.stepSuggestions,
                    };
                }
                return value;
            });
            setActionPlanMapping(updatedMapping);
        }).catch((e) => {
            console.error("Failed to update step: " + e);
        });
    }

    function handleActionItemCompleted(item: ActionItemInfo, isCompleted: boolean) {
        item.user_action_plan_date_completed = isCompleted ? dayjs().format("YYYY-MM-DD") : null;
        updateActionPlan({...item}).then(() => {
            // Update mapping
            const updatedMapping = actionPlanMapping.map((value) => {
                if (
                    value.actionPlan.user_action_plan_id === item.user_action_plan_id
                ) {
                    return {
                        actionPlan: {
                            ...item
                        },
                        stepSuggestions: [...value.stepSuggestions],
                    };
                }
                return {...value};
            });
            setActionPlanMapping(updatedMapping);
        }).catch((e) => {
            console.error("Failed to update action item: " + e);
            // TODO: show a UI error
        });
    }

    function handleDeleteActionItem(item: ActionItemInfo) {
        // TODO: add prompt "Are you sure you want to delete?"
        deleteActionPlan(item.user_action_plan_id).then(() => {
            // Remove deleted item from the mapping
            const updatedMapping = actionPlanMapping.filter(
                (value) => value.actionPlan.user_action_plan_id !== item.user_action_plan_id
            );
            setActionPlanMapping([...updatedMapping]);
        }).catch((e) => {
            console.error("Failed to delete action item: " + e, " actionItem: " + item);
            // TODO: handle failure
        });
    }

    function handleSave(updatedItem: ActionItemInfo) {
        updateActionPlanAggregate(updatedItem)
            .then(() => {
                // TODO: handle success
                // Update mapping
                const updatedMapping = actionPlanMapping.map((value) => {
                    if (
                        value.actionPlan.user_action_plan_id ===
                        updatedItem.user_action_plan_id
                    ) {
                        return {
                            actionPlan: {
                                ...updatedItem,
                                user_action_plan_steps: [
                                    ...(updatedItem.user_action_plan_steps || []),
                                ],
                            },
                            stepSuggestions: [...value.stepSuggestions],
                        };
                    }
                    return {...value};
                });
                setActionPlanMapping(updatedMapping);
            })
            .catch((e) => {
                // TODO: handle failure
            });
    }

    function handleAcceptActionItemSuggestion(suggestionItem: ActionItemSuggestionInfo, isAccepted: boolean) {
        if (isAccepted) {
            acceptActionPlanSuggestion(suggestionItem.user_action_plan_suggestion_id).then((response) => {
                const newActionItem = response as ActionItemInfo;
                console.log("Accepted suggestion: " + JSON.stringify(newActionItem));
                // Add newly created ActionPlanInfo item to the mapping
                actionPlanMapping.push({actionPlan: newActionItem, stepSuggestions: []});

                // Remove accepted suggestion from the list of suggestions
                const updatedSuggestions = actionPlanSuggestions.filter((suggestion) =>
                    suggestion.user_action_plan_suggestion_id != suggestionItem.user_action_plan_suggestion_id);
                setActionPlanSuggestions([...updatedSuggestions]);
            }).catch((e) => {
                // TODO: handle error
                console.error("Failed to accept suggestion: " + e);
            });
        } else {
            rejectActionPlanSuggestion(suggestionItem.user_action_plan_suggestion_id).then(() => {
                // Remove rejected suggestion from the list of suggestions
                const updatedSuggestions = actionPlanSuggestions.filter((suggestion) =>
                    suggestion.user_action_plan_suggestion_id != suggestionItem.user_action_plan_suggestion_id);
                setActionPlanSuggestions([...updatedSuggestions]);
            }).catch((e) => {
                // TODO: handle error
                console.error("Failed to reject suggestion: " + e);
            });
        }
    }

    const renderActionItems = () =>
        actionPlanMapping.map((value) => {
            const visible = !(showActiveOnly && value.actionPlan.user_action_plan_date_completed);
            return visible && (
                <ListItemContainer
                    itemInfo={value.actionPlan}
                    stepSuggestions={value.stepSuggestions}
                    // TODO: replace Date.now with a unique id in the mapping array
                    key={`goal-${value.actionPlan.user_action_plan_id}-${Date.now()}`}
                    onSave={handleSave}
                    onDelete={handleDeleteActionItem}
                    onAcceptSuggestionStep={handleAcceptSuggestionStep}
                    onActionItemCompleted={handleActionItemCompleted}
                    onStepCompleted={handleStepCompleted}
                    showActiveOnly={showActiveOnly}
                    viewLevelDetails={viewLevelDetails}
                />
            );
        });

    const renderActionItemSuggestions = () =>
        actionPlanSuggestions.map((value) => {
            return (
                <ActionPlanSuggestionCard
                    suggestionInfo={value}
                    onAcceptActionItemSuggestion={handleAcceptActionItemSuggestion}
                    key={`goal-${value.user_action_plan_suggestion_id}`}
                />
            );
        });

    function handleAcceptSuggestionStep(
        item: ActionItemInfo,
        step: ActionItemStepSuggestionInfo,
        isAccepted: boolean
    ) {
        if (isAccepted) {
            // Accept suggestion
            acceptActionPlanStepSuggestion(step.user_action_plan_suggestion_id, step.user_action_plan_step_suggestion_id).then((response) => {
                const newStep = response as ActionItemStepInfo;
                console.log("Accepted suggestion: " + JSON.stringify(newStep));
                // Find the action plan that the accepted step belongs to
                const updatedMapping = actionPlanMapping.map((value) => {
                    if (value.actionPlan.user_action_plan_id === item.user_action_plan_id) {

                        // Ensure user_action_plan_steps is initialized
                        value.actionPlan.user_action_plan_steps = value.actionPlan.user_action_plan_steps || [];
                        // Add newly created ActionPlanStepInfo item to the mapping
                        value.actionPlan.user_action_plan_steps.push(newStep);
                        // Remove accepted suggestion from the list of suggestions
                        value.stepSuggestions = value.stepSuggestions.filter((suggestion) =>
                            suggestion.user_action_plan_step_suggestion_id != step.user_action_plan_step_suggestion_id);
                    }
                    return {...value};
                });
                setActionPlanMapping(updatedMapping);
            }).catch((e) => {
                console.error("Failed to accept suggestion: " + e);
                // TODO: handle error
            });
        } else {
            // Reject suggestion
            rejectActionPlanStepSuggestion(step.user_action_plan_suggestion_id, step.user_action_plan_step_suggestion_id).then(() => {
                console.log("Rejected suggestion: " + JSON.stringify(step));
                // Find the action plan that the rejected step belongs to
                const updatedMapping = actionPlanMapping.map((value) => {
                    if (value.actionPlan.user_action_plan_id === item.user_action_plan_id) {
                        // Remove accepted suggestion from the list of suggestions
                        value.stepSuggestions = value.stepSuggestions.filter((suggestion) =>
                            suggestion.user_action_plan_step_suggestion_id != step.user_action_plan_step_suggestion_id) || [];
                    }
                    return {...value};
                });
                setActionPlanMapping(updatedMapping);
            }).catch((e) => {
                console.error("Failed to reject suggestion: " + e);
            });
        }
    }

    function handleChangeDetailsView() {
        setViewLevelDetails(!viewLevelDetails);
    }

    function handleNewActionItem() {
        setCreateActionItem(true);
    }

    const handleActiveOnlyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setShowActiveOnly(event.target.checked);
    };

    const renderTopToolBar = () => (
        <Grid2 container sx={{my: 2}} justifyContent="flex-end" alignItems="center">
            {((!actionPlanMapping || actionPlanMapping.length == 0) && !isLoading && !createActionItem) &&
            (
                <Grid2 justifyItems={"flex-end"}>
                    <Player
                        src={animationArrow}
                        className="player"
                        loop
                        autoplay
                        style={{ height: '60px', width: '60px' }}                            
                    />
                </Grid2>
            )}
            {actionPlanMapping.length > 0 && (
                <>
                    <FormControlLabel
                        label="active only"
                        control={<Switch id="actionplan-viewStatusChange-switch" defaultChecked onChange={handleActiveOnlyChange}/>}
                    />
                    {viewLevelDetails ? (
                        <Button color="info" onClick={handleChangeDetailsView}>
                            <ZoomOutOutlinedIcon/>
                        </Button>
                    ) : (
                        <Button color="info" onClick={handleChangeDetailsView}>
                            <ZoomInOutlinedIcon/>
                        </Button>
                    )}
                </>
            )}
            <Button variant="contained" onClick={handleNewActionItem}>+ New Action</Button>
        </Grid2>
    );

    const emptyItem = {
        user_action_plan_id: 0,
        user_id: user?.user_id || 0,
        user_action_plan_goal: "",
        user_action_plan_steps: [],
        user_action_plan_priority: "1",
        user_action_plan_business_value: "",
        user_action_plan_date_created: null,
        user_action_plan_date_completed: null,
    };

    function handleCreateActionItem(newActionItem: ActionItemInfo) {
        createActionPlan(user!.user_id, newActionItem).then((response) => {
            const newActionItem = response as ActionItemInfo;
            console.log("Created new action item: " + JSON.stringify(newActionItem));
            // Add newly created ActionPlanInfo item to the mapping
            actionPlanMapping.push({actionPlan: newActionItem, stepSuggestions: []});
            setCreateActionItem(false);
        }).catch((e) => {
            console.error("Failed to create new action item: " + e);
            // TODO: handle error
        });
    }

    return (
        <Box sx={{width: "100%", maxWidth: {sm: "100%", md: "1700px"}}}>
            <Grid2>
                <PageHeader
                    title="My action plan"
                    subtitle="An action plan focuses on professional long term growth."
                    subtitle2="Define 1-2 action items and the steps to achieve each one of them: we'll suggest improvements based on the feedback you receive."
                />
            </Grid2>
            <Grid2>
                {
                    (createActionItem) ? (
                        <>
                            <ActionPlanItemCardEdit plan={emptyItem} onCancel={() => setCreateActionItem(false)}
                                                    onSave={handleCreateActionItem}/>
                        </>
                    ) : (
                        <>
                            {renderTopToolBar()}
                            {renderActionItems()}
                            {renderActionItemSuggestions()}
                        </>)
                }
            </Grid2>
            {(!actionPlanMapping || actionPlanMapping.length == 0) && !isLoading && !createActionItem && 
                <StyledEmptyState />
            }
        </Box>
    );
};