import { get, post, put, delete_api } from "./helpers";

export enum GOAL_STATUS_UX {
  PENDING = "PENDING",
  ACTIVE = "ACTIVE"
};

export enum GOAL_STATUS {
  PENDING = "PENDING",
  ACTIVE = "ACTIVE",
  ACHIEVED = "ACHIEVED",
  DELETED = "DELETED"
};

type UpdateTeamGoalKpi = {
  kpi_name: string;
  kpi_measure_definition: string | null;
  kpi_target_date: string | null;
  kpi_target_value: number | null;
  kpi_weight: number | null;
  kpi_created_date: string;
  kpi_created_user_id: number;
  kpi_achieved_date: string | null;
  kpi_achieved_user_id: number | null;
  kpi_status: string;
  kpi_has_custom_users: boolean;
  kpi_id: number | null;
};

type UpdateTeamGoal = {
  strategy_id: number;
  goal_description: string;
  goal_target_date: string | null;
  goal_created_date: string;
  goal_created_user_id: number;
  goal_achieved_date: string | null;
  goal_achieved_user_id: number | null;
  goal_status: string;
  goal_kpis?: Array<UpdateTeamGoalKpi>;
};

type CreateTeamGoalKpi = {
  kpi_name: string;
  kpi_measure_definition: string | null;
  kpi_target_date: string | null;
  kpi_target_value: number | null;
  kpi_weight: number | null;
  kpi_created_date: string;
  kpi_created_user_id: number;
  kpi_achieved_date: string | null;
  kpi_achieved_user_id: number | null;
  kpi_status: string;
  kpi_has_custom_users: boolean;
};

type CreateTeamGoal = {
  strategy_id: number;
  goal_description: string;
  goal_target_date: string | null;
  goal_created_date: string;
  goal_created_user_id: number;
  goal_achieved_date: string | null;
  goal_achieved_user_id: number | null;
  goal_status: string;
  goal_kpis?: Array<CreateTeamGoalKpi>;
};


export const getTeamGoals = async (
  OrgId: number,
  GoalStatus?: string,
): Promise<TeamGoalInfo[]> => {
  try {
    var params = null;
    if (GoalStatus) {
       params = {org_id: OrgId.toString(), goal_status: GoalStatus};
    }
    else {
      params = {org_id: OrgId.toString()};
    }

    return await get<TeamGoalInfo[]>("/goals?" + new URLSearchParams(params).toString());
  } catch (err) {
    console.error(err);
    return [];
  }  
};

export const getTeamGoalsCount = async (
  OrgId: number,
): Promise<number> => {
  try {
    const params = {org_id: OrgId.toString()};
    return await get<number>("/goals/count?" + new URLSearchParams(params).toString());
  } catch (err) {
    console.error(err);
    return 0;
  }  
};


export const createTeamGoals = async (
  strategyId: number,
  teamGoal: TeamGoalInfo
): Promise<TeamGoalInfo | null> => {
  try {
    const teamGoalBody: CreateTeamGoal = {
      strategy_id: strategyId,
      goal_description: teamGoal.goal_description,
      goal_target_date: teamGoal.goal_target_date,
      goal_created_date: teamGoal.goal_created_date,
      goal_created_user_id: teamGoal.goal_created_user_id,
      goal_achieved_date: teamGoal.goal_achieved_date,
      goal_achieved_user_id: teamGoal.goal_achieved_user_id,
      goal_status: teamGoal.goal_status,
      goal_kpis: (teamGoal.goal_kpis || []).map((kpi) => {
        return {
            kpi_name: kpi.kpi_name,
            kpi_measure_definition: kpi.kpi_measure_definition,
            kpi_target_date: kpi.kpi_target_date,
            kpi_target_value: kpi.kpi_target_value,
            kpi_weight: kpi.kpi_weight,
            kpi_created_date: kpi.kpi_created_date,
            kpi_created_user_id: kpi.kpi_created_user_id,
            kpi_achieved_date: kpi.kpi_achieved_date,
            kpi_achieved_user_id: kpi.kpi_achieved_user_id,
            kpi_status: kpi.kpi_status,
            kpi_has_custom_users: kpi.kpi_has_custom_users,
        };
      }),
    };

    return await post<TeamGoalInfo>("/goals", 
      teamGoalBody,
    );
  } catch (err) {
      console.error(err);
      return null;
    } 
};

export const deleteTeamGoal = async (
  goalId: number
): Promise<TeamGoalInfo | null> => {
  try {
    const params = {goal_id: goalId.toString()};
    return await delete_api<TeamGoalInfo>("/goals/goal_id?" + new URLSearchParams(params).toString());
  } catch (err) {
    console.error(err);
    return null;
  }  
};

export const updateTeamGoal = async (
  teamGoal: TeamGoalInfo
): Promise<TeamGoalInfo | null> => {
  try {
    const params = {goal_id: teamGoal.goal_id.toString()};
    const teamGoalBody: CreateTeamGoal = {
      strategy_id: teamGoal.strategy_id,
      goal_description: teamGoal.goal_description,
      goal_target_date: teamGoal.goal_target_date,
      goal_created_date: teamGoal.goal_created_date,
      goal_created_user_id: teamGoal.goal_created_user_id,
      goal_achieved_date: teamGoal.goal_achieved_date,
      goal_achieved_user_id: teamGoal.goal_achieved_user_id,
      goal_status: teamGoal.goal_status,
    };

    return await put<TeamGoalInfo>("/goals/goal_id?" + new URLSearchParams(params).toString(), 
      teamGoalBody,
    );
  } catch (err) {
      console.error(err);
      return null;
    } 
};

export const updateTeamGoalAggregate = async (
  teamGoal: TeamGoalInfo
): Promise<TeamGoalInfo | null> => {
  try {
    const teamGoalBody: UpdateTeamGoal = {
      strategy_id: teamGoal.strategy_id,
      goal_description: teamGoal.goal_description,
      goal_target_date: teamGoal.goal_target_date,
      goal_created_date: teamGoal.goal_created_date,
      goal_created_user_id: teamGoal.goal_created_user_id,
      goal_achieved_date: teamGoal.goal_achieved_date || null,
      goal_achieved_user_id: teamGoal.goal_achieved_user_id || null,
      goal_status: teamGoal.goal_status,
      goal_kpis: (teamGoal.goal_kpis || []).map((kpi) => {
        return {
            kpi_name: kpi.kpi_name,
            kpi_measure_definition: kpi.kpi_measure_definition,
            kpi_target_date: kpi.kpi_target_date,
            kpi_target_value: kpi.kpi_target_value,
            kpi_weight: kpi.kpi_weight,
            kpi_created_date: kpi.kpi_created_date,
            kpi_created_user_id: kpi.kpi_created_user_id,
            kpi_achieved_date: kpi.kpi_achieved_date || null,
            kpi_achieved_user_id: kpi.kpi_achieved_user_id || null,
            kpi_status: kpi.kpi_status,
            kpi_has_custom_users: kpi.kpi_has_custom_users,
            // Drop the negative step IDs for new kpi (the backend will assign new IDs)
            kpi_id: (kpi.kpi_id == null || kpi.kpi_id < 0) ? null : kpi.kpi_id,
        };
      }),
    };

    return await put<TeamGoalInfo>("/goals/" + teamGoal.goal_id.toString(), 
      teamGoalBody,
    );
  } catch (err) {
      console.error(err);
      return null;
    } 
};



export const updateTeamGoalsKpi = async (
  kpi: TeamGoalKpiInfo
): Promise<TeamGoalKpiInfo | null> => {
  try {
    const params = {goal_id: kpi.goal_id.toString(),
      kpi_id: kpi.kpi_id!.toString()};
    const kpiBody = {
        kpi_name : kpi.kpi_name,
        kpi_measure_definition : kpi.kpi_measure_definition,
        kpi_target_date : kpi.kpi_target_date,
        kpi_target_value : kpi.kpi_target_value,
        kpi_weight : kpi.kpi_weight,
        kpi_created_date : kpi.kpi_created_date,
        kpi_created_user_id : kpi.kpi_created_user_id,
        kpi_achieved_date : kpi.kpi_achieved_date,
        kpi_achieved_user_id : kpi.kpi_achieved_user_id,
        kpi_status : kpi.kpi_status,
        kpi_has_custom_users: kpi.kpi_has_custom_users,
      };
  
    return await put<TeamGoalKpiInfo>("/goals/goal_id/kpis/kpi_id?" + new URLSearchParams(params).toString(), 
    kpiBody,
    );
  } catch (err) {
      console.error(err);
      return null;
    } 
};


export const getKpiUserByKpi = async (
  kpiId: number
): Promise<TeamGoalKpiUserInfo[]> => {
  try {
    const params = {kpi_id: kpiId.toString()};
    return await get<TeamGoalKpiUserInfo[]>("/kpi_users/kpi_id?" + new URLSearchParams(params).toString());
  } catch (err) {
    console.error(err);
    return [];
  }  
};

export const createKpiUser = async (
  kpiUser: TeamGoalKpiUserInfo
): Promise<TeamGoalKpiUserInfo | null> => {
  try {
    return await post<TeamGoalKpiUserInfo>("/kpi_users", 
      kpiUser,
    );
  } catch (err) {
      console.error(err);
      return null;
    } 
};


export const deleteKpiUser = async (
  kpiId: number,
  userId: number
): Promise<TeamGoalKpiUserInfo | null> => {
  try {
    const params = {kpi_id: kpiId.toString(), user_id: userId.toString()};
    return await delete_api<TeamGoalKpiUserInfo>("/kpi_users?" + new URLSearchParams(params).toString());
  } catch (err) {
    console.error(err);
    return null;
  }  
};

/*
export const updateTeamGoalKpiUsersAggregate = async (
  kpi_id: number,
  teamGoalKpiUserInfo: TeamGoalKpiUserInfo[]
): Promise<TeamGoalKpiUserInfo | null> => {
  try {
    return await put<TeamGoalKpiUserInfo>("/kpi_users/" + kpi_id.toString(), 
    teamGoalKpiUserInfo,
    );
  } catch (err) {
      console.error(err);
      return null;
    } 
};
*/