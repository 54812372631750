import { get, post, put } from "./helpers";
import { User } from "./schemas/User";

export const getCurrentUser = async (): Promise<User> => {
  return await get<User>("/users/me");
};

export const getUser = async (userId: number): Promise<User> => {
  return await get<User>(`/users/${userId}`);
};

export const createUser = async (
  userName: string,
  userEmail: string
): Promise<User> => {
  return await post<User>("/users", {
    user_name: userName,
    user_email: userEmail,
  });
};


export const isUserOrgLeader = async (orgId: number): Promise<boolean> => {
  const params = {org_id: orgId.toString()};
  return await get<boolean>("/users/me/is_org_leader?" + new URLSearchParams(params).toString());
};

export const isUserPeopleManager = async (): Promise<boolean> => {
  return await get<boolean>("/users/me/is_people_manager");
};


export const myTeam = async (orgId: number): Promise<User[]> => {
  const params = {org_id: orgId.toString()};
  return await get<User[]>("/users/me/my_org_teammates?" + new URLSearchParams(params).toString());
};

export const myDirectReports = async (): Promise<User[]> => {
  return await get<User[]>("/users/me/my_direct");
};


export const getPendingDialogs = async (
): Promise<DialogInfo[]> => {
  try {
    return await get<DialogInfo[]>("/users/me/dialogs");
  } catch (err) {
    console.error(err);
    return [];
  }  
};

export const myDialogsCount = async (): Promise<number> => {
  return await get<number>("/users/me/dialogs/count");
};


export const updateUser = async (
  user: User
): Promise<User | null> => {
  try {
    const userBody = {
      user_name: user.user_name,
      org_id: user.org_id,
      org_id_date_since: user.org_id_date_since,
      role_id: user.role_id,
      role_id_date_since: user.role_id_date_since,
      career_step_id: user.career_step_id,
      career_step_id_date_since: user.career_step_id_date_since,
      level_id: user.level_id,
      level_id_date_since: user.level_id_date_since,
      product_intro_date_view: user.product_intro_date_view,
      direct_manager_user_id: user.direct_manager_user_id,
    };
    return await put<User>("/users/" + user.user_id.toString(), 
    userBody,
    );
  } catch (err) {
      console.error(err);
      return null;
  } 
};
