import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import ForkRightOutlinedIcon from "@mui/icons-material/ForkRightOutlined";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import ModeStandbyOutlinedIcon from "@mui/icons-material/ModeStandbyOutlined";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";

export const PathConstants = {
  LANDING: "/",
  SIGN_UP: "/sign-up",
  SIGN_IN: "/sign-in",
  HOME: "/dashboard",
  CAREER: "/my-career",
  ACTION_PLAN: "/action-plan",
  TEAM_GOALS: "/team-goals",
  FEEDBACK: "/feedback",
  SETTINGS: "/settings",
  PROFILE: "/profile",
  ACCOUNT: "/account",
  SLACK_REDIRECT: "/slack-redirect",
};

export const navMainListItems = [
  { text: "Home", icon: <HomeRoundedIcon />, route: PathConstants.HOME },
  {
    text: "Team objectives",
    icon: <ModeStandbyOutlinedIcon />,
    route: PathConstants.TEAM_GOALS,
  },
  {
    text: "My action plan",
    icon: <AssignmentTurnedInOutlinedIcon />,
    route: PathConstants.ACTION_PLAN,
  },
  {
    text: "Feedback",
    icon: <ChatOutlinedIcon />,
    route: PathConstants.FEEDBACK,
  },
  {
    text: "My career",
    icon: <ForkRightOutlinedIcon />,
    route: PathConstants.CAREER,
  },
];
export const navSecondaryListItems = [
  {
    text: "Settings",
    icon: <SettingsRoundedIcon />,
    route: PathConstants.SETTINGS,
  },
  //{ text: 'About', icon: <InfoRoundedIcon />, route: 'about'}},
];
