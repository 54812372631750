import { get, post } from "./helpers";

export const getFeedbacksReceived = async (
  UserId: number,
): Promise<FeedbackInfo[]> => {
  try {
    const params = {user_id: UserId.toString()};
    return await get<FeedbackInfo[]>("/feedbacks/received?" + new URLSearchParams(params).toString());
  } catch (err) {
    console.error(err);
    return [];
  }  
};

export const getFeedbacksProvided = async (
  UserId: number,
): Promise<FeedbackInfo[]> => {
  try {
    const params = {user_id: UserId.toString()};
    return await get<FeedbackInfo[]>("/feedbacks/provided?" + new URLSearchParams(params).toString());
  } catch (err) {
    console.error(err);
    return [];
  }  
};

export const getFeedbacksSelf = async (
  UserId: number,
): Promise<FeedbackInfo[]> => {
  try {
    const params = {user_id: UserId.toString()};
    return await get<FeedbackInfo[]>("/feedbacks/self?" + new URLSearchParams(params).toString());
  } catch (err) {
    console.error(err);
    return [];
  }  
};

export const getFeedbacksCount = async (
  UserId: number,
): Promise<number> => {
  try {
    const params = {user_id: UserId.toString()};
    return await get<number>("/feedbacks/count?" + new URLSearchParams(params).toString());
  } catch (err) {
    console.error(err);
    return 0;
  }  
};

export const getFeedbacksReceivedCount = async (
  UserId: number,
): Promise<number> => {
  try {
    const params = {user_id: UserId.toString()};
    return await get<number>("/feedbacks/received/count?" + new URLSearchParams(params).toString());
  } catch (err) {
    console.error(err);
    return 0;
  }  
};

const SLACK_COMPANY_TOOL_ID = 1;
const DIALOG_DEFAULT_PROMPT_VERSION = 1;

export const triggerSlackFeedback = async (
  reviewerUserId: number,
  receiverUserId: number,
): Promise<DialogInfo | null> => {
  try {
    const dialog: DialogCreate = {
      user_id: reviewerUserId,
      target_user_id: receiverUserId,
      tool_id: SLACK_COMPANY_TOOL_ID,
      prompt_version: DIALOG_DEFAULT_PROMPT_VERSION,
      debug: false,
    };
    return await post<DialogInfo>("/dialog/feedback", 
      dialog,
    );
  } catch (err) {
    console.error(err);
    return null;
  }  
};

