import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import MuiChip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import QueryStatsRoundedIcon from '@mui/icons-material/QueryStatsRounded';
import GroupWorkOutlinedIcon from '@mui/icons-material/GroupWorkOutlined';
import FitnessCenterOutlinedIcon from '@mui/icons-material/FitnessCenterOutlined';

//import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';

const bulletPointIcon = () => {
  //return (<CheckOutlinedIcon />);
  return("- ");
}

const itemDescrGrowth = () => {
  return (<>
      {bulletPointIcon()} Clarity and transparency: cascade company objectives all the way to individuals<br />
      {bulletPointIcon()} Skills and Competencies tracking: team impact and performance at a glance<br />
      {bulletPointIcon()} Actionable growth plans: gain better insights for structured development<br />
    </>
  );
}
const itemDescrAgents = () => {
  return (<>
      {bulletPointIcon()} Custom-trained AI agents on your company’s skills, values and competencies<br />
      {bulletPointIcon()} Automatically manage feedback, reviews, and action plans behind the scenes<br />
      {bulletPointIcon()} Reclaim 160 hours per manager and 30 hours per employee each year<br />
    </>
  );
}
const itemDescrExec = () => {
  return (<>
      {bulletPointIcon()} Personalized growth plans: meaningful, measurable, aligned with team objectives<br />
      {bulletPointIcon()} AI-driven coaching: structured support across the organization<br />
      {bulletPointIcon()} Continuous improvement: as people grow, the company thrives<br />
    </>
  );
}

const items = [
  {
    icon: <FitnessCenterOutlinedIcon />,
    title: 'Your Growth Hub, simple and effective',
    description: itemDescrGrowth(),
      //'Set clear expectations and foster transparency from the start. With a comprehensive overview of skill development, competency improvements, and goal alignment, your team gets actionable growth plans that keep them focused and motivated. Gain better insights, make smarter decisions, and watch results soar.',
    imageLight: `url("${process.env.REACT_APP_TEMPLATE_IMAGE_URL || 'https://branco.ai'}/static/images/branco_screenshot_career_light.png")`,
    imageDark: `url("${process.env.REACT_APP_TEMPLATE_IMAGE_URL || 'https://branco.ai'}/static/images/branco_screenshot_career_dark.png")`,
  },
  {
    icon: <GroupWorkOutlinedIcon />,
    title: 'AI-Powered Productivity: less admin, more leadership',
    description: itemDescrAgents(),
      //'Our AI agents operate behind the scenes, following your guidelines and standards. One agent manages feedback through Slack, another reviews and anonymizes responses, while a third specializes in building action plans. Together, they free you to focus on what really matters: leading and innovating.',
    imageLight: `url("${process.env.REACT_APP_TEMPLATE_IMAGE_URL || 'https://branco.ai'}/static/images/branco_screenshot_feedback_and_slack_light.png")`,
    imageDark: `url("${process.env.REACT_APP_TEMPLATE_IMAGE_URL || 'https://branco.ai'}/static/images/branco_screenshot_feedback_and_slack_dark.png")`,
  },
  {
    icon: <QueryStatsRoundedIcon />,
    title: 'Executive-level Coaching for Everyone',
    description: itemDescrExec(),
      //'Think of it as having a leadership coach for your entire organization. We ensure every individual’s growth is meaningful, measurable, and aligned with team goals. As your people improve, your company thrives and that’s when real transformation happens.',
    imageLight: `url("${process.env.REACT_APP_TEMPLATE_IMAGE_URL || 'https://branco.ai'}/static/images/branco_screenshot_action_plan_light.png")`,
    imageDark: `url("${process.env.REACT_APP_TEMPLATE_IMAGE_URL || 'https://branco.ai'}/static/images/branco_screenshot_action_plan_dark.png")`,
  },
];

interface ChipProps {
  selected?: boolean;
}

const Chip = styled(MuiChip)<ChipProps>(({ theme }) => ({
  variants: [
    {
      props: ({ selected }) => selected,
      style: {
        background:
          'linear-gradient(to bottom right, hsl(210, 98%, 48%), hsl(210, 98%, 35%))',
        color: 'hsl(0, 0%, 100%)',
        borderColor: (theme).palette.primary.light,
        '& .MuiChip-label': {
          color: 'hsl(0, 0%, 100%)',
        },
        ...theme.applyStyles('dark', {
          borderColor: (theme).palette.primary.dark,
        }),
      },
    },
  ],
}));

interface MobileLayoutProps {
  selectedItemIndex: number;
  handleItemClick: (index: number) => void;
  selectedFeature: (typeof items)[0];
}

export function MobileLayout({
  selectedItemIndex,
  handleItemClick,
  selectedFeature,
}: MobileLayoutProps) {
  if (!items[selectedItemIndex]) {
    return null;
  }

  return (
    <Box
      sx={{
        display: { xs: 'flex', sm: 'none' },
        flexDirection: 'column',
        gap: 2,
      }}
    >
      <Box sx={{ display: 'flex', gap: 2, overflow: 'auto' }}>
        {items.map(({ title }, index) => (
          <Chip
            size="medium"
            key={index}
            label={title}
            onClick={() => handleItemClick(index)}
            selected={selectedItemIndex === index}
          />
        ))}
      </Box>
      <Card variant="outlined">
        <Box
          sx={(theme) => ({
            mb: 2,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            minHeight: 280,
            backgroundImage: 'var(--items-imageLight)',
            ...theme.applyStyles('dark', {
              backgroundImage: 'var(--items-imageDark)',
            }),
          })}
          style={
            items[selectedItemIndex]
              ? ({
                  '--items-imageLight': items[selectedItemIndex].imageLight,
                  '--items-imageDark': items[selectedItemIndex].imageDark,
                } as any)
              : {}
          }
        />
        <Box sx={{ px: 2, pb: 2 }}>
          <Typography
            gutterBottom
            sx={{ color: 'text.primary', fontWeight: 'medium' }}
          >
            <b>{selectedFeature.title}</b>
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary', mb: 1.5 }}>
            {selectedFeature.description}
          </Typography>
        </Box>
      </Card>
    </Box>
  );
}

export default function Features() {
  const [selectedItemIndex, setSelectedItemIndex] = React.useState(0);

  const handleItemClick = (index: number) => {
    setSelectedItemIndex(index);
  };

  const selectedFeature = items[selectedItemIndex];

  return (
    <Container id="product" sx={{ py: { xs: 12, lg: 12 } }}>
      <Box sx={{ width: { sm: '100%', md: '100%' } }}>
        <Typography
          component="h2"
          variant="h4"
          gutterBottom
          sx={{ color: 'text.primary' }}
        >
          Unleash Your Team’s Potential
        </Typography>
        <Typography
          variant="body1"
          sx={{ color: 'text.secondary', mb: { xs: 2, sm: 4 } }}
        >
          According to SHRM, managers spend 200+ hours annually on performance reviews, employees spend 40: Branco.ai gives 80% of that time back.<br />
          Our AI-powered agents provide real-time support, driving team growth, boosting engagement, and accelerating business outcomes.<br />
          Say goodbye to vague feedback and ineffective reviews.
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row-reverse' },
          gap: 2,
        }}
      >
        <div>
          <Box
            sx={{
              display: { xs: 'none', sm: 'flex' },
              flexDirection: 'column',
              gap: 2,
              height: '100%',
            }}
          >
            {items.map(({ icon, title, description }, index) => (
              <Box
                key={index}
                component={Button}
                onClick={() => handleItemClick(index)}
                sx={[
                  (theme) => ({
                    p: 2,
                    height: '100%',
                    width: '100%',
                    '&:hover': {
                      backgroundColor: (theme).palette.action.hover,
                    },
                  }),
                  selectedItemIndex === index && {
                    backgroundColor: 'action.selected',
                  },
                ]}
              >
                <Box
                  sx={[
                    {
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'left',
                      gap: 1,
                      textAlign: 'left',
                      textTransform: 'none',
                      color: 'text.secondary',
                    },
                    selectedItemIndex === index && {
                      color: 'text.primary',
                    },
                  ]}
                >
                  <Typography variant="h6">
                   {icon}&nbsp;&nbsp;{title}
                  </Typography>
                  <Typography variant="body2">{description}</Typography>
                </Box>
              </Box>
            ))}
          </Box>
          <MobileLayout
            selectedItemIndex={selectedItemIndex}
            handleItemClick={handleItemClick}
            selectedFeature={selectedFeature}
          />
        </div>
        <Box
          sx={{
            display: { xs: 'none', sm: 'flex' },
            width: { xs: '100%', md: '45%' },
            height: 'var(--items-image-height)',
          }}
        >
          <Card
            variant="outlined"
            sx={{
              height: '100%',
              width: '100%',
              display: { xs: 'none', sm: 'flex' },
              pointerEvents: 'none',
            }}
          >
            <Box
              sx={(theme) => ({
                m: 'auto',
                width: 420,
                height: 500,
                backgroundSize: 'contain',
                backgroundImage: 'var(--items-imageLight)',
                ...theme.applyStyles('dark', {
                  backgroundImage: 'var(--items-imageDark)',
                }),
              })}
              style={
                items[selectedItemIndex]
                  ? ({
                      '--items-imageLight': items[selectedItemIndex].imageLight,
                      '--items-imageDark': items[selectedItemIndex].imageDark,
                    } as any)
                  : {}
              }
            />
          </Card>
        </Box>
      </Box>
    </Container>
  );
}
