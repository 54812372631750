import React, { useContext, useState, useEffect } from "react";

import {Autocomplete, Avatar, AvatarGroup, Button, Checkbox, Chip, Grid2, Stack, TableCell, TableRow, TextField, Tooltip,} from "@mui/material";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";

import {
    getKpiUserByKpi, 
    createKpiUser,
    deleteKpiUser
} from "../../services/api/TeamGoals";

import {convertDateToShort} from "../../utils/monthNames";
import { colorFromDate } from "../../utils/colorsFromDate";
import { stringAvatar } from "../../utils/stringAvatar";

import { User } from "../../services/api/schemas/User";


interface KpiProps {
    kpiInfo: TeamGoalKpiInfo;
    onKpiCompleted: (step: TeamGoalKpiInfo, isCompleted: boolean) => void;
    viewLevelDetails: boolean;
    userOrgLeader: boolean;
    teamMates: User[];
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


export const KpiCard = ({kpiInfo, onKpiCompleted, viewLevelDetails, userOrgLeader, teamMates}: KpiProps) => {
    const [kpiUsers, setKpiUsers] = useState<User[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isEditing, setIsEditing] = useState(false);
    const [selectTeamMates, setSelectTeamMates] = useState<User[]>([]);


    const fetchCustomKpiUsers = async (kpiId: number) => {
        return await getKpiUserByKpi(kpiId).then((value) => {
            const kpi_user = value as TeamGoalKpiUserInfo[] || [];
            const teamMateMapper: User[] = [];
            for (let i = 0; i < kpi_user.length; i++) {
                const foundTeamMate = teamMates.find(
                    (teamMate:User) => teamMate.user_id === kpi_user[i].user_id);
                if (foundTeamMate)
                    teamMateMapper.push(foundTeamMate);
            }
            setKpiUsers(teamMateMapper);
            setSelectTeamMates(teamMateMapper);
            setIsLoading(false);
        });
    };
    
    useEffect(() => {
        try {
            if (kpiInfo && kpiInfo.kpi_id && teamMates){
                if (kpiInfo.kpi_has_custom_users)
                    fetchCustomKpiUsers(kpiInfo.kpi_id);
                else{
                    setKpiUsers(teamMates);
                    setSelectTeamMates(teamMates);
                    setIsLoading(false);        
                }
            }
        } catch (err) {
        console.error("Failed to retrieve KpiUsers. ", err);
        }
    }, [kpiInfo, teamMates]);


    const updateKpiUsers = () => {
        try {
            // if custom, i'll cehck the diffs
            // if not custom, i only need to add
            if (kpiInfo.kpi_has_custom_users){
                // New users to add
                for (let i = 0; i < selectTeamMates.length; i++) {
                    const foundTeamMate = kpiUsers.find(
                        (kpiUser:User) => kpiUser.user_id === selectTeamMates[i].user_id);
                    if (!foundTeamMate) {
                        const kpiUser: TeamGoalKpiUserInfo = {
                            kpi_id: kpiInfo.kpi_id!,
                            user_id: selectTeamMates[i].user_id,
                        };
                        createKpiUser(kpiUser);
                    }
                }
                // Old users to remove
                for (let i = 0; i < kpiUsers.length; i++) {
                    const foundTeamMate = selectTeamMates.find(
                        (selectTeamMate:User) => selectTeamMate.user_id === kpiUsers[i].user_id);
                    if (!foundTeamMate) {
                        deleteKpiUser(kpiInfo.kpi_id!, kpiUsers[i].user_id);
                    }
                }
            } else {
                // Only add who's selected...
                for (let i = 0; i < selectTeamMates.length; i++) {
                    const kpiUser: TeamGoalKpiUserInfo = {
                        kpi_id: kpiInfo.kpi_id!,
                        user_id: selectTeamMates[i].user_id,
                    };
                    createKpiUser(kpiUser);
                }
            }

            //updateTeamGoalKpiUsersAggregate(kpiInfo.kpi_id!, updatedKpiUsers)
            setKpiUsers(selectTeamMates);
            setIsEditing(false);

        } catch (err) {
            console.error("Failed to update Kpi custom users.", err);
        }    
    };

    
    async function handleKpiCompleted(event: React.ChangeEvent<HTMLInputElement>) {
        const isChecked = event.target.checked; // Get the current value of the checkbox
        onKpiCompleted(kpiInfo, isChecked);
    }

    const backgroundColor = colorFromDate(
        kpiInfo.kpi_target_date? kpiInfo.kpi_target_date: "", 
        kpiInfo.kpi_achieved_date
    );

    /*
    function stringToColor(string: string) {
        let hash = 0;
        let i;
      
        for (i = 0; i < string.length; i += 1) {
          hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }
      
        let color = '#';
      
        for (i = 0; i < 3; i += 1) {
          const value = (hash >> (i * 8)) & 0xff;
          color += `00${value.toString(16)}`.slice(-2);
        }
        return color;
      }
      
      function stringAvatar(name?: string) {
        if (!name) 
            name = "? ?";
        if (name.split(' ').length < 2) 
            name = name + ' ?';
        return {
          sx: {
            bgcolor: stringToColor(name),
          },
          children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
      }
*/

    return (

        <TableRow>
            <TableCell size="small" width="5%">
                {userOrgLeader && 
                    kpiInfo.kpi_achieved_date ? (
                        <Tooltip title="Reopen achieved KR/KPI" arrow>
                            <Checkbox id={`step-check- ${kpiInfo.kpi_id}`}
                                    checked={!!kpiInfo.kpi_achieved_date} 
                                    onChange={handleKpiCompleted}/>
                        </Tooltip>
                    ):(
                        <Tooltip title="Mark as achieved" arrow>
                            <Checkbox id={`step-check- ${kpiInfo.kpi_id}`}
                                    checked={!!kpiInfo.kpi_achieved_date} 
                                    onChange={handleKpiCompleted}/>
                        </Tooltip>
                    )
                }
            </TableCell>
            <TableCell align="left" sx={{padding: 1}}>
                <Stack direction={"column"} justifyContent="flex-end" spacing={0}>
                    <Grid2 container justifyContent="flex-start" alignItems={"center"} spacing={1} >
                        <Grid2 size={{ xs: 12, sm: 7 }}>
                            {kpiInfo.kpi_name}
                            <Chip color={backgroundColor} sx={{marginLeft: 1, marginRight: 1}}
                                label={kpiInfo.kpi_achieved_date? `completed ${convertDateToShort(kpiInfo.kpi_achieved_date)}` : `by ${convertDateToShort(kpiInfo.kpi_target_date)}`}
                                size="small"
                            />
                        </Grid2>
                        {kpiUsers && kpiUsers.length > 0 && 
                            isEditing && userOrgLeader ? (
                                <>
                                    <Grid2 size={{ xs: 12, sm: 4 }}>
                                        <Autocomplete
                                            multiple
                                            id="autocomp-team-mates"
                                            options={teamMates}
                                            disableCloseOnSelect
                                            value={selectTeamMates}
                                            onChange={(event, value) => {setSelectTeamMates(value);}}
                                            getOptionLabel={(option) => option.user_name!}
                                            renderOption={(props, option, { selected }) => {
                                            const { key, ...optionProps } = props;
                                            return (
                                                <li key={key} {...optionProps}>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{ marginRight: 8 }}
                                                    checked={selected}
                                                />
                                                {option.user_name}
                                                </li>
                                            );
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} label="Team members" placeholder="type or select" />
                                            )}
                                        />   
                                    </Grid2>
                                    <Grid2 size={{ xs: 12, sm: 1 }}>
                                        <Tooltip title="Cancel changes" arrow>
                                            <Button variant="outlined" startIcon={<CancelIcon/>} color={"error"} onClick={() => {setIsEditing(false); setSelectTeamMates(kpiUsers);}}>
                                                Cancel
                                            </Button>
                                        </Tooltip>
                                        <Tooltip title="Save changes" arrow>
                                            <Button variant="outlined" startIcon={<CheckIcon/>} onClick={() => updateKpiUsers()}>
                                                OK
                                            </Button>
                                        </Tooltip>
                                    </Grid2>
                                </>
                            ) : (
                                userOrgLeader ? (
                                    <Grid2 size={{ xs: 12, sm: 5 }}>
                                        <Button key={"lead_btn_key_users_" + kpiInfo.kpi_id} id={"lead_btn_key_users_" + kpiInfo.kpi_id} onClick={() => setIsEditing(true)}>
                                            <AvatarGroup key={"lead_avg_key_users_" + kpiInfo.kpi_id} id={"lead_avg_key_users_" + kpiInfo.kpi_id} max={5} >
                                                {kpiUsers.map((kpiUser) => (
                                                    <Tooltip title={kpiUser.user_name}>
                                                        <Avatar key={"lead_avatar_key_users_" + kpiInfo.kpi_id} id={"lead_avatar_key_users_" + kpiInfo.kpi_id} {...stringAvatar(kpiUser.user_name)} />
                                                    </Tooltip>
                                                ))}
                                            </AvatarGroup>                         
                                        </Button>
                                    </Grid2>
                                ) : (
                                    <Grid2 size={{ xs: 12, sm: 5 }}>
                                        <AvatarGroup key={"avg_key_users_" + kpiInfo.kpi_id} id={"avg_key_users_" + kpiInfo.kpi_id} max={5} >
                                            {kpiUsers.map((kpiUser) => (
                                                <Tooltip title={kpiUser.user_name}>
                                                    <Avatar key={"avatar_key_users_" + kpiInfo.kpi_id} id={"avatar_key_users_" + kpiInfo.kpi_id} {...stringAvatar(kpiUser.user_name)} />
                                                </Tooltip>
                                            ))}
                                        </AvatarGroup>                         
                                    </Grid2>
                                )
                            )
                        }
                        {!isLoading && kpiUsers && kpiUsers.length === 0 && 
                            <>
                                <Grid2 size={{ xs: 12, sm: 4 }}>
                                    <Autocomplete
                                        multiple
                                        id="autocomp-team-mates-empty"
                                        options={teamMates}
                                        disableCloseOnSelect
                                        value={selectTeamMates}
                                        onChange={(event, value) => {setSelectTeamMates(value);}}
                                        getOptionLabel={(option) => option.user_name!}
                                        renderOption={(props, option, { selected }) => {
                                        const { key, ...optionProps } = props;
                                        return (
                                            <li key={key} {...optionProps}>
                                            <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                            />
                                            {option.user_name}
                                            </li>
                                        );
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params} label="Team members" placeholder="type or select" />
                                        )}
                                    />   
                                </Grid2>
                                <Grid2 size={{ xs: 12, sm: 1 }}>
                                    <Tooltip title="Cancel changes" arrow>
                                        <Button variant="outlined" startIcon={<CancelIcon/>} color={"error"} onClick={() => {setIsEditing(false); setSelectTeamMates(kpiUsers);}}>
                                            Cancel
                                        </Button>
                                    </Tooltip>
                                    <Tooltip title="Save changes" arrow>
                                        <Button variant="outlined" startIcon={<CheckIcon/>} onClick={() => updateKpiUsers()}>
                                            OK
                                        </Button>
                                    </Tooltip>
                                </Grid2>
                            </>
                        }                        
                    </Grid2>
                    {viewLevelDetails && 
                    <i>
                        {kpiInfo.kpi_measure_definition && 'Measurement (success criteria): ' + kpiInfo.kpi_measure_definition} 
                        <br />
                        <Chip color="default" sx={{marginLeft: 1}}
                            label={`created on ${convertDateToShort(kpiInfo.kpi_created_date)}`}
                            size="small"
                        />
                        {kpiInfo.kpi_achieved_date && 
                            <Chip color={backgroundColor} sx={{marginLeft: 1}}
                                label={`target ${convertDateToShort(kpiInfo.kpi_target_date)}`}
                                size="small"
                            />
                        }
                        {kpiInfo.kpi_target_value && ' target value: ' + kpiInfo.kpi_target_value} 
                        {kpiInfo.kpi_weight && ' (weight: ' + kpiInfo.kpi_weight + ')'}
                    </i>
                    }
                </Stack>
            </TableCell>
        </TableRow>
    );
};