import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme, Tooltip, Grid2, Typography, Button, Popover, IconButton, Stack } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import { Player } from '@lottiefiles/react-lottie-player';

import { PathConstants } from '../../utils/nav';

import { PageHeader } from '../_PageHeader';
import InviteUser from './InviteUser';

interface OnboardingOrgLeaderProps {
  slackComplete: boolean;
  goalsComplete: boolean;
  oneFeedbackComplete: boolean;
  teamJoinedComplete: boolean;
}


const OnboardingOrgLeader = ({
  slackComplete,
  goalsComplete,
  oneFeedbackComplete,
  teamJoinedComplete,
}: OnboardingOrgLeaderProps) => {
  
  const theme = useTheme();
  const navigate = useNavigate();

  // Lottie file paths (replace with actual files)
  const slackLottie = '/static/lotties/setting-shield.json';
  const goalsLottie = '/static/lotties/team-goals.json';
  const feedbackLottie = '/static/lotties/feedback-rating.json';
  const teamJoinLottie = '/static/lotties/all-hands.json';

  const handleClick = (path: string) => {
    navigate(path);
  };

  const getLottieProps = (src: string, complete: boolean, loop: boolean = true) => ({
    src,
    loop: loop,
    autoplay: true,
    style: {
      width: 100,
      height: 100,
      cursor: 'pointer',
      filter: !complete
        ? theme.palette.mode === 'dark'
          ? 'invert(1)'
          : 'none'
        : theme.palette.mode === 'dark'
          ? 'grayscale(1) invert(1) opacity(0.5)'
          : 'grayscale(1) opacity(0.5)',
    },
  });

  // POPUP team invites
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const handleInviteTeamPopover = (event: React.MouseEvent<HTMLElement>) => {
    try {
      //const {value} = event.currentTarget;
      //var selected_competency_id: number = +value;
      //fetchCompetencyRating(selected_competency_id);
      } catch (err) {
      console.error("Failed to open 'invite team members' from org leader onboarding. ", err);
    }
    setAnchorEl(event.currentTarget);
  };
  const handleInviteTeamPopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

    
  return (
    <Grid2
      width="100%"
      container
      spacing={1}
//      padding={1}
      columns={12}
      sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}
    >
      <Grid2 size={12}>
        <PageHeader title="Welcome, unlock your team potential!" 
          subtitle="Let's get started in less than 5 minutes:"
          subtitle2=""
        />
      </Grid2>
      <Grid2 size={{ xs: 12, sm: 3}}>
        <Tooltip title="Connect Branco to Slack">
          <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}} onClick={() => handleClick(PathConstants.SETTINGS)}>
            <Player {...getLottieProps(slackLottie, slackComplete, !slackComplete && !goalsComplete && !oneFeedbackComplete && !teamJoinedComplete)} />
            <Button
              variant={slackComplete? "outlined" : "contained"} 
              size="small"
              color="primary"
              disabled={slackComplete}
            >
              1. Connect Slack
            </Button>
          </div>
        </Tooltip>
      </Grid2>
      <Grid2 size={{ xs: 12, sm: 3}}>
        <Tooltip title="Set your first team Objective and KR (or KPI)">
          <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}} onClick={() => handleClick(PathConstants.TEAM_GOALS)}>
            <Player {...getLottieProps(goalsLottie, goalsComplete, slackComplete && !goalsComplete && !oneFeedbackComplete && !teamJoinedComplete)} />
            <Button
              variant={goalsComplete? "outlined" : "contained"} 
              size="small"
              color="primary"
              disabled={goalsComplete}
            >
              2. Team Objectives
            </Button>
          </div>
        </Tooltip>
      </Grid2>
      <Grid2 size={{ xs: 12, sm: 3}}>
        <Tooltip title="Run a feedback loop on yourself">
          <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}} onClick={() => handleClick(PathConstants.FEEDBACK)}>
            <Player {...getLottieProps(feedbackLottie, oneFeedbackComplete, slackComplete && goalsComplete && !oneFeedbackComplete && !teamJoinedComplete)} />
            <Button
              variant={oneFeedbackComplete? "outlined" : "contained"} 
              size="small"
              color="primary"
              disabled={oneFeedbackComplete}
            >
              3. Test Feedback
            </Button>
          </div>
        </Tooltip>
      </Grid2>
      <Grid2 size={{ xs: 12, sm: 3}}>
        <Tooltip title="Invite your team members to branco.ai">
          <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}} onClick={handleInviteTeamPopover}>
            <Player {...getLottieProps(teamJoinLottie, teamJoinedComplete, slackComplete && goalsComplete && oneFeedbackComplete && !teamJoinedComplete)} />
            <Button
              variant={teamJoinedComplete? "outlined" : "contained"} 
              size="small"
              color="primary"
              disabled={teamJoinedComplete}
            >
              4. Invite the team
            </Button>
          </div>
        </Tooltip>
      </Grid2>

       <Popover
            id={'popover-onboard-invite-team-member'}
            open={open}
            anchorEl={anchorEl}
            onClose={handleInviteTeamPopoverClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            sx={{ width: '95%' }}
          >
            <Stack
              direction="row"
              padding={2}
              sx={{
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: 2,
              }}
            >
              <Stack
                direction="column"
                spacing={2}
                sx={{
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  gap: 1,
                }}
              >
                <b>
                  Invite your team members
                </b>
              </Stack>
              <IconButton aria-label="Close" size="small" onClick={handleInviteTeamPopoverClose}>
                <CloseOutlinedIcon />
              </IconButton>
            </Stack>
            <InviteUser 
              inviteLink={window.location.origin + PathConstants.SIGN_UP}
            />
        </Popover>


    </Grid2>
  );
};

export default OnboardingOrgLeader;
