import {Theme, Components} from "@mui/material/styles";

export const autocompleteCustomizations: Components<Theme> = {
    MuiAutocomplete: {
        styleOverrides: {
            root: ({ theme }) => ({
                color: theme.palette.grey[500],
                ...theme.applyStyles("dark", {
                    color: theme.palette.grey[400],
                }),
            }),
            endAdornment: {
                "& .MuiButtonBase-root": {
                    background: "inherit", // Removes any background overrides
                    color: "inherit", // Ensures the button uses default MUI color
                    border: "none", // Prevents custom borders
                    boxShadow: "none", // Removes unwanted shadows
                    cursor: "pointer", // Fixes cursor on hover
                    "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.04)", // MUI default hover effect
                    },
                    "&:focus": {
                        outline: "none", // Keeps focus styles clean
                    },
                },
            },
        },
    }
}